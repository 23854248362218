import React from 'react'
import './Placement.css'
import { Link } from 'react-router-dom'
function Placement() {
  return (
    <div className='dpsru-every-component-css'>

      <h2 className="dpsru-primary-heading-for-every-page">
        Placement Cell
      </h2>

      <div className="placement-head-container">
        Ms.  Rakhi Ahuja <br />
        Training and Placement Head, DPSRU <br />
        {/* <a href="mailto:rakhiahujadpsru@gmail.com" style={{textDecoration:'none'}}>rakhiahujadpsru@gmail.com</a>  */}
        E-mail: rakhiahujadpsru [at] gmail [dot] com
      </div>

      <div className="placement-details-text">
        The placement cell, DPSRU continue to stands committed towards getting the best possible opportunities for its students by providing them with a thriving platform and the best exposure. The aim of the placement cell is to provide employment opportunities, including self- employment and admission for higher studies in reputed institutes, for the students, commensurate
        with their specialization & calibre and to place maximum students in reputed companies. To
        achieve this aim, the university conducts skill enhancement programmes and continuously
        interact with the industries to update the current requirements of industry for the students. <br /><br />

        DPSRU is concluding its final placement for the year in leading National & Multinational companies at all levels including undergraduate, postgraduate, Ph.D. & Diploma students. Like previous years, the DPSRU continued to enjoy immense confidence from the corporate world as substantiated by the remarkable placement season for the session 2016-17 & 2017- 18. The university’s unique student driven culture, robust corporate engagement model and planned academic and research rigour have consistently resulted in a significant increase in the competency levels of the students. This value proposition has  strengthened the confidence of the recruiters in DPSRU. The university has left no stone unturned to bring the biggest names in the industry on campus. Be it the R&D giant, Novartis or Quintiles IMS, each and every company that has visited the college so far has been amazed by the talent the students have to offer. During 2018-19, the University witnessed participation of various esteemed organizations offering roles across various domains including formulation development, clinical research, business analysis,  production, quality assurance, <br /><br />

        research & development, hospital & community pharmacy and sales & marketing. Various companies including WNS (Value-edge), IQVIA, Dr. Reddys, Zyla, Abbott, Cipla, Novartis, ZS Associates,
        DRG, Eli-Lilly, BresMed, Walter Bushnell, BresMed, Phronesis Jubilient Generics, Turacoz, Mankind,
        Troikaa Pharmaceuticals, Cognitrex, Fortis, Sanyog Entreprise, Fortis Healthworld, Tata Consultancy
        etc. participated in the recruitment process of our students. The companies offered package of 8.5
        to 5.0 lacs for M.Pharm. and 3.5 to 6.2 lacs for B.Pharm. students. The remarkable thing in this process was the  proactive participation of student coordinators (Anmol Wadhwa, Dev Jain, Himanshu, Vandna and team) in the months leading to the recruitments. On the whole, DPSRU has yet again bettered its previous
        records imparting the best of opportunities to its students and had another successful year with the
        placement process for M. Pharm. students. Most of the B. Pharm. students showed their interest for
        higher studies and cleared the GPAT examination. In addition to recruitment, the placement cell of
        University coordinates the activities like arranging Job training, industrial visits and career guidance
        lectures to students. University has arranged summer training of 120 students of D. Pharm. in
        various Government and Private hospitals. The summer training of M. Pharm. students is arranged
        in WNS, Cognitrex, Hospitals, Quintiles IMS etc. B.Pharm. students are sent to various industries
        for summer training. The placement cell has organized workshops including Workshop on Career
        Awareness entitled ‘I am a Pharmacist-Do I know my industry! in collaboration with SIHMRICI and
        APTI Delhi State Branch. In addition to it, various expert talks on Resume writing, Aptitude test,
        Group Discussions were arranged. The placement cell started the soft skill training programme in the university for M. Pharm. and Ph.D. students. The university is always a trend setter. Continuing with it, the placement cell of University also guides the students in professionalism, presentation techniques, interview skills and new technology trends & developments in the industry and motivates them for self-employment, start-ups and entrepreneurship. One of our Ph.D. student has started his own start-up for Pharmaceutical and allied Health Sciences. <br />
      </div>


<div className="placement-record-btn-container">
  <h2 className="dpsru-secondary-heading-for-every-page">Placement Record</h2>

  <div className="nirf-data-container">
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/placement-record-2022-2023"
            className="nirf-botton library-navlink library-navlink active"
          >
            
            2022-2023
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/placement-record-2021-2022"
            className="nirf-botton library-navlink library-navlink active"
          >
            
            2021-2022
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/placement-record-2020-2021"
            className="nirf-botton library-navlink library-navlink active"
          >
            
            2020-2021
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">

          <Link
          to='/placement-record-2019-2020'
           className="nirf-botton library-navlink library-navlink active">
          2019-2020
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/placement-record-2018-2019"
            className="nirf-botton library-navlink library-navlink active"
          >
            
            2018-2019
          </Link>
        </section>
        <section className="nirf-data-section-eact library-section-each">
          <Link
            to="/placement-record-2017-2018"
            className="nirf-botton library-navlink library-navlink active"
          >
            
            2017-2018
          </Link>
        </section>
      </div>
</div>

      {/* table start here */}

      <table >
        <tbody>
          <tr>
            <th>S.No.</th>
            <th>Name of students</th>
            <th>Specialization</th>
            <th>Job profile</th>
          </tr>
          <tr>
            <th>Novartis, Hyderabad</th>
          </tr>
          <tr>
            <td>1</td>
            <td>Urgen Chodon</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Anmol Wadhwa</td>
            <td>M.Pharm.(DRA)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Debiparna Biswas</td>
            <td>M.Pharm.(Pharm. Analysis)</td>
            <td>Associate Scientist-1</td>
          </tr>
          <tr>
            <td>4</td>
            <td>Mona Vishwakarma</td>
            <td>M.Pharm. (Pharmaceutics)</td>
            <td>Associate Scientist-1</td>
          </tr>
          <tr>
            <th>IQVIA,Gurugram</th>
          </tr>
          <tr>
            <td>5</td>
            <td>RaviKumar Yadav</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>6</td>
            <td>Mrigna Malhotra</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>7</td>
            <td>Shalu Pal</td>
            <td>M.Pharm. (Pharmacognosy)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>8</td>
            <td>Chaitnya Goswami</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>TraineeData Analyst</td>
          </tr>
          <tr>
            <td>9</td>
            <td>Sahil Kalyan</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>TraineeData Analyst</td>
          </tr>
          <tr>
            <td>11</td>
            <td>Shweta Rawat</td>
            <td>M.Pharm.(Drug Regulatory
              Affairs)</td>
            <td>TraineeData Analyst</td>
          </tr>
          <tr>
            <td>12</td>
            <td>Sanchita Dhiman</td>
            <td>Pharm.MBA</td>
            <td>TraineeData Analyst</td>
          </tr>
          <tr>
            <th>WNS Global Services, Gurugram</th>
          </tr>
          <tr>
            <td>13</td>
            <td>Aayush KumarJha</td>
            <td>M.Pharm(Pharmaceutics)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>14</td>
            <td>Dev Jain</td>
            <td>M.Pharm(Pharmaceutics)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>15</td>
            <td>Deepak Insa</td>
            <td>M.Pharm(Chemistry)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>16</td>
            <td>Ritika Pal</td>
            <td>M.Pharm(Drug Regulatory
              Affairs)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>17</td>
            <td>Hridyanshu Verma</td>
            <td>M.Pharm(Drug Regulatory
              Affairs)</td>
            <td>Trainee Analyst</td>
          </tr>
          <tr>
            <th>ZS Associates, Gurugram</th>
          </tr>
          <tr>
            <td>18</td>
            <td>Anjali Kapoor</td>
            <td>M.Pharm(Drug Regulatory
              Affairs)</td>
            <td>Knowledge Management
              Associate</td>
          </tr>
          <tr>
            <td>19</td>
            <td>Rahul Saini</td>
            <td>M.Pharm(Pharmacology)</td>
            <td>Knowledge Management
              Associate</td>
          </tr>
          <tr>
            <td>20</td>
            <td>Sheetal Goel</td>
            <td>M.Pharm(Quality Assurance)</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td>21</td>
            <td>Harshita Sharma</td>
            <td>M.Pharm(Pharmaceutics)</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <th>Dr. Reddy’s, Hyderabad</th>
          </tr>
          <tr>
            <td>22</td>
            <td>Neekita Sharma</td>
            <td>M.Pharm(Pharmaceutics)</td>
            <td>Research &amp; Development</td>
          </tr>
          <tr>
            <th>Walter Bushnell, Gurugram</th>
          </tr>
          <tr>
            <td>23</td>
            <td>Shivangi Chauhan</td>
            <td>M.Pharm(Pharmacology)</td>
            <td>Medical Information Service</td>
          </tr>
          <tr>
            <td>24</td>
            <td>Varun Kumar</td>
            <td>M.Pharm(Clinical Research)</td>
            <td>Medical Information Service</td>
          </tr>
          <tr>
            <td>25</td>
            <td>Priyanka Lekhwar</td>
            <td>M.Pharm(Pharmaceutics)</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <th>DRG, Gurugram</th>
          </tr>
          <tr>
            <td>26</td>
            <td>Varun Kumar</td>
            <td>M.Pharm(Clinical Research)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>27</td>
            <td>Priya Soni</td>
            <td>M.Pharm.(Pharmaceutics)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>28</td>
            <td>Aysha Arshad</td>
            <td>M.Pharm.(Pharmaceutics)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>29</td>
            <td>Ankita Kumar</td>
            <td>M.Pharm.(Hospital
              Pharmacy)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>30</td>
            <td>Priyanka Lekhwar</td>
            <td>M.Pharm.(Pharmaceutics)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>31</td>
            <td>Hardeep S Bambra</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>32</td>
            <td>Ritesh Pathania</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <td>33</td>
            <td>Komal Tomar</td>
            <td>Pharm.MBA</td>
            <td>Health Economic Executive</td>
          </tr>
          <tr>
            <th>Zyla, Gurugram</th>
          </tr>
          <tr>
            <td>34</td>
            <td>Richa Singh</td>
            <td>M.Pharm.(Pharmacology)</td>
            <td>Clinical Research Analyst</td>
          </tr>
          <tr>
            <th>Eli Lilly</th>
          </tr>
          <tr>
            <td>35</td>
            <td>Sonali Sharma</td>
            <td>Pharm.MBA</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>36</td>
            <td>Aprajita Rastogi</td>
            <td>Pharm.MBA</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>37</td>
            <td>Prabhsimran Singh</td>
            <td>Pharm.MBA</td>
            <td>Marketing</td>
          </tr>
          <tr>
            <td>38</td>
            <td>Aayushi Jain</td>
            <td>B.Pharm</td>
            <td>Sales Representative</td>
          </tr>
          <tr>
            <td>39</td>
            <td>Ashi Siddiqui</td>
            <td>B.Pharm</td>
            <td>Sales Representative</td>
          </tr>
          <tr>
            <td>40</td>
            <td>Ritika Beechwani</td>
            <td>B.Pharm</td>
            <td>Sales Representative</td>
          </tr>
          <tr>
            <td>41</td>
            <td>Aaron</td>
            <td>B.Pharm</td>
            <td>Sales Representative</td>
          </tr>
          <tr>
            <th>Abbott, Himachal Pradesh</th>
          </tr>
          <tr>
            <td>42</td>
            <td>Kajal Jain</td>
            <td>M.Pharm. (Pharmacognosy)</td>
            <td>Production</td>
          </tr>
          <tr>
            <td>43</td>
            <td>Ubaid Tariq</td>
            <td>M.Pharm.(Biotechnology)</td>
            <td>Production</td>
          </tr>
          <tr>
            <td>44</td>
            <td>Priyanka Lekhwar</td>
            <td>M. Pharm. (Pharmaceutics)</td>
            <td>Production</td>
          </tr>
          <tr>
            <td>45</td>
            <td>Anjali Kapoor</td>
            <td>M.Pharm.(DRA)</td>
            <td>Quality Assurance</td>
          </tr>
          <tr>
            <td>46</td>
            <td>Geeta Negi</td>
            <td>M.Pharm(Pharm. Analysis)</td>
            <td>Quality Control</td>
          </tr>
          <tr>
            <td>47</td>
            <td>Shivani Chawda</td>
            <td>M.Pharm(Pharm. Analysis)</td>
            <td>Quality Assurance</td>
          </tr>
          <tr>
            <th>Kalam Institute of Health Technology, Vishakhapatnam</th>
          </tr>
          <tr>
            <td>48</td>
            <td>Rashi Tomer</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td>Senior Research Fellow</td>
          </tr>
          <tr>
            <td>49</td>
            <td>Megha Dabas</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td>Senior Research Fellow</td>
          </tr>
          <tr>
            <th>Cipla, Mumbai</th>
          </tr>
          <tr>
            <td>50</td>
            <td>Smridhi Khurana</td>
            <td>B.Pharm</td>
            <td>Therapy Manager</td>
          </tr>
          <tr>
            <td>51</td>
            <td>Akash Bansal</td>
            <td>B.Pharm</td>
            <td>Therapy Manager</td>
          </tr>
          <tr>
            <th>Cognitrex, Gurugram</th>
          </tr>
          <tr>
            <td>52</td>
            <td>Anmol Wadhwa</td>
            <td>M.Pharm.(DRA)</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>53</td>
            <td>Sonali Sharma</td>
            <td>Pharm. MBA</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>54</td>
            <td>Sanchita Dhiman</td>
            <td>Pharm.MBA</td>
            <td>Data Analyst</td>
          </tr>
          <tr>
            <td>55</td>
            <td>Mugdha Kesh</td>
            <td>Pharm.MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td>56</td>
            <td>Vaishali Khanna</td>
            <td>Pharm.MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td>57</td>
            <td>Poorvi Sharma</td>
            <td>Pharm MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td>58</td>
            <td>Nazla Khalid</td>
            <td>Pharm. MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td>59</td>
            <td>Richa Swantantrata</td>
            <td>Pharm. MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <th>BresMed</th>
          </tr>
          <tr>
            <td>60</td>
            <td>Rahul</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td>Research Associate</td>
          </tr>
          <tr>
            <td>61</td>
            <td>Himanshu</td>
            <td>M.Pharm. (Pharmaceutics)</td>
            <td>Research Associate</td>
          </tr>
          <tr>
            <th>Phronesis</th>
          </tr>
          <tr>
            <td>62</td>
            <td>Kokila</td>
            <td>B.Pharm.</td>
            <td>Business Development
              Executive</td>
          </tr>
          <tr>
            <td>63</td>
            <td>Shivani Giri</td>
            <td>B.Pharm.</td>
            <td>Business Development
              Executive</td>
          </tr>
          <tr>
            <th>NPPA, New Delhi</th>
          </tr>
          <tr>
            <td>64</td>
            <td>Garima Singh</td>
            <td>M.Pharm.</td>
            <td>Officer</td>
          </tr>
          <tr>
            <th>Fortis, Gurugram</th>
          </tr>
          <tr>
            <td>65</td>
            <td>Mohammad Sharib</td>
            <td>MHM</td>
            <td>Trainee</td>
          </tr>
          <tr>
            <th>Park Hospital, Gurugram</th>
          </tr>
          <tr>
            <td>66</td>
            <td>Anshika Gupta</td>
            <td>MHM</td>
            <td>Trainee</td>
          </tr>
          <tr>
            <th>Turacoz</th>
          </tr>
          <tr>
            <td>67</td>
            <td>Kritika Tiwary</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>Medical writer</td>
          </tr>
          <tr>
            <td>68</td>
            <td>Pankaj Chakravorty</td>
            <td>M.Pharm.(Quality Assurance)</td>
            <td>Medical writer</td>
          </tr>
          <tr>
            <td>69</td>
            <td>Shubham Abrol</td>
            <td>M.Pharm. (Chemistry)</td>
            <td>Business Devlp. Executive</td>
          </tr>
          <tr>
            <td>70</td>
            <td>Indu Yadav (waiting)</td>
            <td>M.Pharm. (Chemistry)</td>
            <td>Medical writer</td>
          </tr>
          <tr>
            <th>Fortis Healthworld</th>
          </tr>
          <tr>
            <td>71</td>
            <td>Khushagar Kanna</td>
            <td>Ph.D.</td>
            <td>Start-up funded by DIIF</td>
          </tr>
        </tbody>
      </table>

      {/* table end here */}

      <h3 className="placement-sub-heading">
        Placements of DPSRU in 2018-19: Overview
      </h3>

      <table>
        <tbody>
          <tr>
            <td>Number of companies called for recruitment</td>
            <td>22</td>
          </tr>

          <tr>
            <td>New Recruiters</td>
            <td>08</td>
          </tr>

          <tr>
            <td>Maximum package offer</td>
            <td>8.5 lacs</td>
          </tr>

          <tr>
            <td>Average package for PG</td>
            <td>6.0 lacs</td>
          </tr>

          <tr>
            <td>Average package for UG</td>
            <td>5.0 lacs</td>
          </tr>

          <tr>
            <td>Number of offers by companies</td>
            <td>70</td>
          </tr>

          <tr>
            <td>Number of offers for Hospital/Industry training for UG & Diploma</td>
            <td>More than 150

            </td>
          </tr>

        </tbody>
      </table>


      <h3 className="placement-sub-heading">
        Training and placement Cell of DPSRU is involved in
      </h3>

      <div className="training-and-placement-cell-involved">
        <ol>
          <li> Placements of D. Pharm., B.Pharm., M.Pharm., Master in Public Health, Master in Hospital Administration, MBA in Pharmaceutical Management, Physiotherapy & Ph.D students.</li>
          <li> Training of students in Hospitals.</li>
          <li>Training of students in Industry.</li>
          <li>Training of students in Research Organization.</li>
          <li>Soft Skill Training</li>
          <li> Domain Skill Training</li>
          <li>Workshops on Resume Writing, Aptitude Test and Interveiw Skills etc.</li>
          <li>Workshops on career Awareness programmes</li>
          <li> Workshops on Entrepreneurship</li>
        </ol>
      </div>

      <h3 className="placement-sub-heading">
        Facilities at Training Placement Cell
      </h3>

      <div className="training-and-placement-cell-involved facility-at-training-placement-cell">
        <ul>
          <li> The Training and Placement Cell located at second floor which is well equipped and is designed to smoothly handle and support the placement procedure at every stage. Arrangements for interviews, group discussions and pre-placement talks are handled by the staff at the Placement Cell. We are equipped with the following facilities to aid the process:  Well furnished Two rooms for interviews and group discussions. </li>

          <li>  Fully computerized Two information technology Centers with 10 Mbps internet connectivity & UPS backup .</li>

          <li>The IT Centers can accommodate 50 students.  A Seminar Hall to conduct pre-placement talks and tests with a seating capacity of 50 to 60 students. </li>

          <li>It is equipped with projection and internet facility through WiFi mode.  Student placement volunteers to assist on the day of visit and through the selection process.  </li>

          <li>The company provides the Job Description which the serves as an introduction of the job profile for the benefit of the candidates and also informs them of the company’s requirements. </li>

          <li> The relevant content of the Job Description electronically broadcasted to all the students along with the other additional information furnished by the company </li>

          <li>The company can ask for the resumes of interested students and has the liberty to shortlist them before the beginning of the placement process.</li>

        </ul>
      </div>

      <h3 className="placement-sub-heading">
        Placements Report 2017-18
      </h3>

      <div className="placement-details-text">
        DPSRU has active Training and placement cell for the recruitment activities in the campus in association with Student Placement Representatives. The placement cell, DPSRU stands committed towards getting the best possible opportunities for its students by providing them with a thriving platform and the best exposure. The aim of the placement cell is to provide employment opportunities, including self employment and admission for higher studies in reputed institutes, for the students, commensurate with their specialization & calibre and to place maximum students in reputed companies. To achieve this aim, the university conducts skill enhancement programmes and continuously interact with the industries to update the current requirements of industry for the students.
        DPSRU has concluded its final placement for the year 2017 and is closer to 100% placement of eligible and interested students in leading National & Multinational companies at all levels including undergraduate, postgraduate, Ph.D. & Diploma students. Like last year, the DPSRU continued to enjoy immense confidence from the corporate world as substantiated by the remarkable placement season for the session 2016-17. The university’s unique student driven culture, robust corporate engagement model and planned academic and research rigour have consistently resulted in a significant increase in the competency levels of the students. This value proposition has strengthened the confidence of the recruiters in DPSRU. <br /><br />

        The university has left no stone unturned to bring the biggest names in the industry on campus. Be it the R&D giant, Pfizer or Quintiles IMS, each and every company that has visited the college so far has been amazed by the talent the students have to offer.
        During 2017-18, the University witnessed participation of various esteemed organizations offering roles across various domains including clinical research, business analysis, production, quality assurance, research & development, hospital & community pharmacy and sales & marketing. Various companies including WNS (Value-edge), Quintiles IMS, Cipla, Pfizer, Complete Heors solutions, Astrazeneca, ZS Associates, DRG, Eli-Lilly, Novartis, Parexal International, Abbott Healthcare, BresMed, Walter Bushnell, Jubilient Generics, Turacoz, Mankind, Bioinnovat, Troikaa Pharmaceuticals, Cognitrex, Integral Biosciences Pvt. Ltd. Fortis, Meril Life Sciences, Mumbai, Sanyog Entreprise, WrigNon etc. participated in the recruitment process of our students. The companies offered package of 8.5 to 7.5 lacs for Ph.D., 7.0 to 5.5 lacs for M.Pharm. and 3.5 to 4.5 lacs for B.Pharm. students. <br /><br />

        In addition to recruitment, the placement cell of University coordinates the activities like arranging Job training, industrial visits and career guidance lectures to students. University has arranged summer training of 120 students of D.  Pharm. in Hospitals including G.B Pant Hospital, Pt. Madan Mohan Malaviya Hospital & Lok Nayak Hospital. The summer training of M. Pharm. students is arranged in National Dope Testing Lab, Jubiliant Generics, Smartanalyst, Quintiles IMS etc. More than 60 students of B.Pharm. are sent to various industries for summer training. The placement cell has organized workshops including Workshop on Career enhancement for employability and self-employment and Workshop on Chromatography and Mass spectroscopy techniques in Pharmaceutical Applications. <br /><br />
        The university is always a trend setter. Continuing with it, the placement cell of University also guides the students in professionalism, presentation techniques, group discussion techniques, interview skills and new technology trends & developments in the industry and motivates them for self employment, start-ups and entrepreneurship. Two of our M.Pharm. students have started their own start-ups for Pharmaceutical and allied Health Sciences. One of our Ph.D. student is selected for grant for starting his own venture.
      </div>

      <h3 className="placement-sub-heading">
        SOME OF THE PLACEMENTS FOR 2017-2018
      </h3>

      {/* second table start here */}
      <table border="1" style={{marginBottom:'2%'}}>
        <tbody>
          <tr>
            <td >S. No.</td>
            <td>Name of students</td>
            <td>Specialization</td>
            <td>Job profile</td>
          </tr>
          <tr>
            <td >WNS (Value-edge), Gurgaon </td>
          </tr>
          <tr>
            <td >1</td>
            <td>Nikhil Varshney</td>
            <td>Pharm. MBA</td>
            <td >Trainee Analyst Marketing Analytics</td>
          </tr>
          <tr>
            <td >2</td>
            <td>Vipin&nbsp; Choudhary</td>
            <td>M.Pharm.(Clinical Research)</td>
          </tr>
          <tr>
            <td >3</td>
            <td>Dr. Deepti Sharma</td>
            <td>MHM</td>
          </tr>
          <tr>
            <td >Quintiles IMS, Gurgaon</td>
          </tr>
          <tr>
            <td >4</td>
            <td>Lalit Kumar</td>
            <td>M.Pharm.(Pharm. Chemistry)</td>
            <td >Analyst</td>
          </tr>
          <tr>
            <td >5</td>
            <td>Akhilesh Kumar</td>
            <td>M.Pharm. (Pharmaceutics)</td>
          </tr>
          <tr>
            <td >6</td>
            <td>Yogeshwar Tyagi</td>
            <td>M.Pharm. (Pharmaceutics)</td>
          </tr>
          <tr>
            <td >7</td>
            <td>Aarushi</td>
            <td>M.Pharm.(Clinical Research)</td>
          </tr>
          <tr>
            <td >8</td>
            <td>Sumayya K.</td>
            <td>M.Pharm. (Pharmacognosy)</td>
          </tr>
          <tr>
            <td >9</td>
            <td>Suman Bhardwaj</td>
            <td>M.Pharm.(QA)</td>
          </tr>
          <tr>
            <td >10</td>
            <td>Jyoti Gaur</td>
            <td>M.Pharm.(Clinical Research)</td>
            <td >Trainee Analyst</td>
          </tr>
          <tr>
            <td >11</td>
            <td>Deepshi Ranjan</td>
            <td>M.Pharm.(QA)</td>
          </tr>
          <tr>
            <td >12</td>
            <td>Pooja Bisht</td>
            <td>M. Pharm. (DRA)</td>
          </tr>
          <tr>
            <td >13</td>
            <td>Garima Singh</td>
            <td>M. Pharm. (Chemistry)</td>
          </tr>
          <tr>
            <td >14</td>
            <td>Charul Upadhyay</td>
            <td>M. Pharm. (DRA)</td>
          </tr>
          <tr>
            <td >15</td>
            <td>Ritik Aggarwal</td>
            <td>M. Pharm. (Pharmaceutics)</td>
          </tr>
          <tr>
            <td >16</td>
            <td>Puja Paul</td>
            <td>Pharm. MBA</td>
          </tr>
          <tr>
            <td  >Cipla, Mumbai</td>
          </tr>
          <tr>
            <td >16</td>
            <td  >Aishwarya Mathur</td>
            <td>B. Pharm.</td>
            <td >Territory Manager</td>
          </tr>
          <tr>
            <td >17</td>
            <td  >Gagan Chitkara</td>
            <td>B. Pharm.</td>
          </tr>
          <tr>
            <td  >Complete Heors solutions, Gurgaon</td>
          </tr>
          <tr>
            <td >18</td>
            <td  >Tarun Gaur</td>
            <td>M. Pharm. (Clinical Research)</td>
            <td >Analyst</td>
          </tr>
          <tr>
            <td >19</td>
            <td  >Neeti Channa</td>
            <td>M. Pharm. (DRA)</td>
          </tr>
          <tr>
            <td  >Astrazeneca, Bangalore</td>
          </tr>
          <tr>
            <td >20</td>
            <td  >Akshita Nagpal</td>
            <td>B. Pharm.</td>
            <td >Pharma Trainee</td>
          </tr>
          <tr>
            <td >21</td>
            <td  >Anushka Gupta</td>
            <td>B. Pharm.</td>
          </tr>
          <tr>
            <td >22</td>
            <td  >Anvita Nagar</td>
            <td>B. Pharm.</td>
          </tr>
          <tr>
            <td >23</td>
            <td  >Neelam- MBA</td>
            <td>Pharm. MBA</td>
          </tr>
          <tr>
            <td  >ZS Associates, Gurgaon</td>
          </tr>
          <tr>
            <td >24</td>
            <td  >Nikhil Varshney</td>
            <td>Pharm. MBA</td>
            <td>Trainee Analyst</td>
          </tr>
          <tr>
            <td  >DRG</td>
          </tr>
          <tr>
            <td >25</td>
            <td  >Priya Aggarwal</td>
            <td>M. Pharm. (Pharmaceutics)</td>
            <td >Summer Trainee</td>
          </tr>
          <tr>
            <td >26</td>
            <td  >Preeti Bhati</td>
            <td>M. Pharm. (Pharmacognosy)</td>
          </tr>
          <tr>
            <td  >Eli-Lilly</td>
          </tr>
          <tr>
            <td >27</td>
            <td  >Vivek</td>
            <td>B. Pharm.</td>
            <td>Territory Manager</td>
          </tr>
          <tr>
            <td  ><a href="http://www.parexel.com/" target="_blank" rel="noopener">Parexal International</a></td>
          </tr>
          <tr>
            <td >28</td>
            <td  >Priyanka Singh</td>
            <td>B. Pharm.</td>
            <td >Analyst</td>
          </tr>
          <tr>
            <td >29</td>
            <td  >Sakhi Sharma</td>
            <td>B. Pharm.</td>
          </tr>
          <tr>
            <td  >Abbott Healthcare</td>
          </tr>
          <tr>
            <td >30</td>
            <td  >Aayushi Gupta</td>
            <td>M. Pharm. (DRA)</td>
            <td>QA/QC Chemist</td>
          </tr>
          <tr>
            <td  >BresMed</td>
          </tr>
          <tr>
            <td >31</td>
            <td  >Divya Sharma</td>
            <td>M. Pharm. (Pharmacology)</td>
            <td>Graduate Research Associate</td>
          </tr>
          <tr>
            <td  >Walter Bushnell</td>
          </tr>
          <tr>
            <td >32</td>
            <td  >Priyanka Mittal</td>
            <td>M. Pharm. (QA)</td>
            <td >Trainee-Medical information</td>
          </tr>
          <tr>
            <td >33</td>
            <td  >Divya Sharma</td>
            <td>M. Pharm. (Pharmacology)</td>
          </tr>
          <tr>
            <td  >Jubilient Generics</td>
          </tr>
          <tr>
            <td >34</td>
            <td  >Lalit Kumar</td>
            <td>M. Pharm. (Pharm. Chemistry)</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td  >Cognitrex</td>
          </tr>
          <tr>
            <td >35</td>
            <td  >Sonali</td>
            <td>Pharm. MBA</td>
            <td >Summer Trainee</td>
          </tr>
          <tr>
            <td >36</td>
            <td  >Sanchita</td>
            <td>Pharm. MBA</td>
          </tr>
          <tr>
            <td  >Smartanalyst, Gurgaon</td>
          </tr>
          <tr>
            <td >37</td>
            <td  >Nitu Sah</td>
            <td>Pharm. MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td  >Troikaa Pharmaceuticals</td>
          </tr>
          <tr>
            <td >38</td>
            <td  >Mrinal</td>
            <td>Pharm. MBA</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td  >Integral Biosciences Pvt. Ltd.</td>
          </tr>
          <tr>
            <td >39</td>
            <td  >Kamal Sahni</td>
            <td>M. Pharm. (QA)</td>
            <td>Summer Trainee</td>
          </tr>
          <tr>
            <td  >Entrepreneurship</td>
          </tr>
          <tr>
            <td >40</td>
            <td  >Prabhsimran</td>
            <td>Pharm. MBA</td>
            <td>e-Bottle</td>
          </tr>
          <tr>
            <td >41</td>
            <td  >Anmol Wadhwa</td>
            <td>M.Pharm. (DRA)</td>
            <td>Medical insurance</td>
          </tr>
          <tr>
            <td >42</td>
            <td  >Pankaj Sharma</td>
            <td>Ph.D. (Pharmaceutics)</td>
            <td>Low cost disinfectant for rural India</td>
          </tr>
          <tr>
            <td  >Government Job</td>
          </tr>
          <tr>
            <td >43</td>
            <td  >Mamta Yadav</td>
            <td>M. Pharm. (Pharmaceutics)</td>
            <td>Defence</td>
          </tr>
          <tr>
            <td >44</td>
            <td  >Ritik Aggarwal</td>
            <td>M. Pharm. (Pharmaceutics)</td>
            <td > Hospital Pharmacist</td>
          </tr>
        </tbody>
      </table>
      {/* second table end here */}

      <h3 className="placement-sub-heading">
      Placements of DPSRU in 2017-18: Overview
      </h3>

      {/* Third table start here */}
      <table style={{marginBottom:'3%'}}>
<tbody>
<tr>
<td >Number of companies called for recruitment</td>
<td >25</td>
</tr>
<tr>
<td >New Recruiters</td>
<td >10</td>
</tr>
<tr>
<td >Maximum package offer</td>
<td >8.5 lacs</td>
</tr>
<tr>
<td >Average package for PG</td>
<td >6.0 lacs</td>
</tr>
<tr>
<td >Average package for UG</td>
<td >4.0 lacs</td>
</tr>
<tr>
<td >Number of offers by companies</td>
<td >42</td>
</tr>
<tr>
<td >Number of offer for Hospital/Industry training for UG &amp; Diploma</td>
<td >More than 150</td>
</tr>
<tr>
<td >Number of start-ups</td>
<td >3</td>
</tr>
<tr>
<td >Number of Govt. jobs offered</td>
<td >2</td>
</tr>
</tbody>
</table>
      {/* Third table end here */}
    </div>
  )
}

export default Placement