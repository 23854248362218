import React from "react";
import academicsCalendar202324 from '../../Academics/academics-calendar.pdf'
import './Archive.css'

const Archive = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">Archive</h2>

      <div className="archive-data-container">
<ul className="archive-ul-container">
    <li className="archive-li-box"><a href={academicsCalendar202324}>Academics-calendar 2023-24</a></li>
</ul>

      </div>
    </div>
  );
};

export default Archive;
