import React from 'react'
import './NewFooter.css'
import './ResponsiveFooter.css'
import dpsruLogo from './dpsruLogo.jpg'
// import Map from './map-design.png'
import Map from './map.png'
// import Map from './locate us ss.jpg'
import {  NavLink } from 'react-router-dom'
import GoToTopButton from '../Go to Top/GoToTop'
import { FaFacebookF, FaInstagram, FaTwitter, FaYoutube,FaLinkedinIn } from "react-icons/fa";

import avcom from '../AvcomNetworkTechnologies/Avcom-Profile.pdf'

function Footer() {
    return (
        <div className="footer-comp">
            <div className='footer'>
                <div className="footer-container">
                    <div className="footer-sec footer-sec-1">

                        <div className='bottom-footer-third-section'>
                            <p><NavLink to='/contactUs' className="locate-us-heading footer-contact-us-dpsru">Contact Us </NavLink></p>
                        </div>
                        <h4 className="footer-short-heading">
                            Campus Address :
                        </h4>
                        <p className="footer-text-sec-p">
                            Mehrauli - Badarpur Road,<br /> Pushp vihar Sector 03, New Delhi <br /> 110017, India
                        </p>
                        <h4 className="footer-short-heading">
                            Contact
                        </h4>
                        <p className="footer-text-sec-p">
                            29552039, 29552040
                        </p>
                    </div>
                    <div className="footer-sec footer-sec-middle">
                        <img src={dpsruLogo} alt="dpsru" />

                    </div>
                    <div className="footer-sec map-footer-sec-new">

                    <div className='bottom-footer-third-section' style={{justifyContent:'center'}}>

                            <p><a href='https://www.google.com/maps/place/Delhi+Institute+of+Pharmaceutical+Sciences+and+Research/@28.5163177,77.22234,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce18c32871099:0x1ed9984d99b24c5a!8m2!3d28.5163177!4d77.224534!16s%2Fm%2F0g54514' className="locate-us-heading footer-contact-us-dpsru" >Locate Us</a></p>
                        </div>

                   

                        <a href="https://www.google.com/maps/place/Delhi+Institute+of+Pharmaceutical+Sciences+and+Research/@28.5163177,77.22234,17z/data=!3m1!4b1!4m6!3m5!1s0x390ce18c32871099:0x1ed9984d99b24c5a!8m2!3d28.5163177!4d77.224534!16s%2Fm%2F0g54514" target='_blank'  rel="noreferrer">

                            <img src={Map} alt="Map" />
                        </a>

                    </div>
                </div>

                <div className=" dpsru-text-in-footer"><h1>Delhi Pharmaceutical Sciences And Research University</h1></div>

                {/* Bottom Footer */}
                <div className="bottom-footer">
                    <div className="footer-content-dpsru" >
                        <p className="copyright-dpsru">Copyright © 2023 - 2024 . All rights reserved.</p>

                        <div className='dpsru-social-media-icon'>
                            <a href="https://www.facebook.com/profile.php?id=61553962983251" className='footer-facebook-icon'><FaFacebookF /></a>

                            <a href="https://www.instagram.com/dpsruofficial/" className='footer-instagram-icon'><FaInstagram /></a>

                            <a href="https://twitter.com/dpsru_official" className='footer-twitter-icon'> <FaTwitter /> </a>


                            <a href="https://www.linkedin.com/in/dpsru-university/" className='footer-linkedin-icon'> <FaLinkedinIn /> </a>

                            {/* <a href="https://www.kooapp.com/koo/DelhiGovDigital/" className='koo-a-link-footer' style={{ display: 'inline-block' }}> <img src={koo} alt="" /> </a> */}

                            {/* <NavLink to='/youtubeLinks' className='footer-youtube-icon'><FaYoutube /> </NavLink> */}
                            <a href='https://www.youtube.com/channel/UC1HJbrrrelw3nZy0ZHO0mgw' className='footer-youtube-icon'><FaYoutube /> </a>
                        </div>

                        <div className='bottom-footer-third-section'>
                            <p><NavLink to='/grievanceForm' >Grievance Form</NavLink></p>
                            {/* <p><NavLink to='/admin/admin-login' >Admin</NavLink></p> */}
                            <p><NavLink to='/naac'>Naac</NavLink></p>
                            <p><NavLink to='/examination/examination-result'>Result</NavLink></p>
                            <p className='go-to-top-button-footer'> <GoToTopButton /></p>
                        </div>

                    </div>

                </div>
            </div>

            <div className="avcom-details-in-footer"> || <a href={avcom}> Developed and Maintained by :-  Avcom Network Technologies </a>  || </div>
        </div>

    )
}

export default Footer