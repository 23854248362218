import React from "react";
import "./Scholorships.css";

import newgif from '../../NEW/new.gif'

// file import
import ScholorshipForMinorityDateExtended from './ScholorShip data/ScholorshipForMinorityDateExtended.pdf'
import LastDateofApplicationonNSPExtended  from './ScholorShip data/Last Date of Application on NSP Extended.pdf'
import PostMatricandMeritcummeansScholarship  from './ScholorShip data/Post-Matric-and-Merit-cum-means-Scholarship.jpeg'
import ScholorshipNotice  from './ScholorShip data/ScholorshipNotice.pdf'
import UnnatiScholarship  from './ScholorShip data/UnnatiScholarship.pdf'
import CSSscholarshiponNSP  from './ScholorShip data/CSSscholarshiponNSP.pdf'
import AictescholarshipsonNSP  from './ScholorShip data/AictescholarshipsonNSP.pdf'
import extensionOfStateFundedScholorship  from './ScholorShip data/extensionOfStateFundedScholorship.pdf'
import MeritCumMeansLinkedFinancialA  from './ScholorShip data/MeritCumMeansLinkedFinancialA.pdf'
import NoticeRegardingExtension  from './ScholorShip data/NoticeRegardingExtension.jpeg'
import PragatiSakshamSwanath  from './ScholorShip data/PragatiSakshamSwanath.pdf'
import RegardingLaunchingOfSC_ST_OBCScholarship  from './ScholorShip data/RegardingLaunchingOfSC_ST_OBCScholarship.pdf'
import NoticeRegardingUpdatingAadharDetails  from './ScholorShip data/NoticeRegardingUpdatingAadharDetails.pdf'
import NoticeRegardingExtensionOfDateOnNationalScholarshipPortal  from './ScholorShip data/NoticeRegardingExtensionOfDateOnNationalScholarshipPortal.pdf'
import AICTEUnderNSP  from './ScholorShip data/AICTEUnderNSP.pdf'
import RegardingScholarshipAtNSP  from './ScholorShip data/RegardingScholarshipAtNSP.pdf'
import LastDateOfSubmissionOfScholarshipDocuments  from './ScholorShip data/LastDateOfSubmissionOfScholarshipDocuments.pdf'
import NoticeOnScholarship  from './ScholorShip data/NoticeOnScholarship.pdf'
import OrderOfExtension  from './ScholorShip data/ORDER-of-Extension-of-the-Merit-Cum-Means-Scheme-for-the-students-upto-31.07.2021.pdf'
import AboutScholarshipsAtDPSRU  from './ScholorShip data/AboutScholarshipsAtDPSRU.pdf'
import DELHI_MINORTIES  from './ScholorShip data/DELHI_MINORTIES.pdf'
import MERIT_CUM_MEANS_FOR_ALL_INCOME_BASED_DELHI  from './ScholorShip data/MERIT_CUM_MEANS_FOR_ALL_INCOME_BASED_DELHI.pdf'
import PostMatricScholarships  from './ScholorShip data/PostMatricScholarships.pdf'
import MeritCumMeansLinkedFinancialAssistanceScheme  from './ScholorShip data/MeritCumMeansLinkedFinancialAssistanceScheme.pdf'
import RulesForAwardForNECMeritScholarship  from './ScholorShip data/RulesForAwardForNECMeritScholarship.pdf'
import CentralGovtScholarship  from './ScholorShip data/CentralGovtScholarship.pdf'
import pmsForScs  from './ScholorShip data/PMS_for_SCs_Scheme_Guidelines_1.pdf'
import PMSOBCRevisedGuidelinesE2018  from './ScholorShip data/PMS_OBC_RevisedGuidelines_E_2018.pdf'
import DehliHigherandtecheducationsuppscheme from './ScholorShip data/Delhi Higher & Technical Education Support Scheme.pdf'
import Guidlinesfordelhihigherandtecheducationsupscheme from './ScholorShip data/Guidelines of Delhi Higher & Technical Education Support Scheme.pdf'

function Scholorships() {
  return (
    <div className="scholorship-component">
      <h2 className="scholorship-main-heading">SCHOLARSHIP</h2>

      <div className="results-data-link-container">
        <ul className="datesheet-ul-container">
          <li>
          <a href={Guidlinesfordelhihigherandtecheducationsupscheme}>
          Guidelines of Delhi Higher & Technical Education Support Scheme
          </a> <img src={newgif} alt="new"  />
        </li>
      <li>
          <a href={DehliHigherandtecheducationsuppscheme}>
          Delhi Higher & Technical Education Support Scheme
          </a> <img src={newgif} alt="new"  />
        </li>
      <li>
          <a href={ScholorshipForMinorityDateExtended}>
          Scholarship for Minority ( Date Extension)  
          </a> <img src={newgif} alt="new"  />
        </li>
        {/* <li>Notice for Scholarship Schemes for Minority Students </li>  */}
        <li>
          <a href={LastDateofApplicationonNSPExtended}>
            Notice – Last Date of Application on NSP Extended
          </a> <img src={newgif} alt="new"  />
        </li>
        <li>
          <a href={PostMatricandMeritcummeansScholarship}>
            Post Matric and Merit cum Means Scholarship
          </a>
        </li>
        <li>
          <a href={ScholorshipNotice}>
            Scholarship Notice
          </a>
        </li>
        <li>
          <a href={UnnatiScholarship}>
            Unnati Scholarship
          </a>
        </li>
        <li>
          <a href={CSSscholarshiponNSP}>
            CSS Scholarships on NSP
          </a>
        </li>
        <li>
          <a href={AictescholarshipsonNSP}>
            AICTE Scholarships on NSP
          </a>
        </li>
        <li>
          <a href={extensionOfStateFundedScholorship}>
            Extension of State Funded Scholarship Schemes for Minorities
          </a>
        </li>
        <li>
          <a href={MeritCumMeansLinkedFinancialA}>
            Merit-cum-means linked Financial Assistance Scheme
          </a>
        </li>
        <li>
          <a href={NoticeRegardingExtension}>
            Notice regarding Extension of Last Date for Scholarship Schemes on
            edistrict portal
          </a>
        </li>
        <li>
          <a href={PragatiSakshamSwanath}>
            Notice regarding Pragati_Saksham_Swanath Scholarship
          </a>
        </li>
        <li>
          <a href={RegardingLaunchingOfSC_ST_OBCScholarship}>
            Notice: Regarding launching of SC/ST/OBC scholarship schemes for
            students for the year 2021-22
          </a>
        </li>
        <li>
          <a href={NoticeRegardingUpdatingAadharDetails}>
            Notice regarding updating Aadhar details on National Scholarship
            Portal{" "}
          </a>
        </li>
        <li>
          <a href={NoticeRegardingExtensionOfDateOnNationalScholarshipPortal}>
            Notice regarding Extension of Date on National Scholarship Portal
          </a>
        </li>
        <li>
          <a href={AICTEUnderNSP}>
            Notice regarding extension of date for Scholarship Schemes by AICTE
            under NSP
          </a>
        </li>
        <li>
          <a href={RegardingScholarshipAtNSP}>
            Notice: Last Date Extension Regarding Scholarship at NSP
          </a>
        </li>
        <li>
          <a href={LastDateOfSubmissionOfScholarshipDocuments}>
            Important Notice: Last Date of Submission of Scholarship Documents
          </a>
        </li>
        <li>
          <a href={NoticeOnScholarship}>
            Notice on Scholarship{" "}
          </a>
        </li>
        <li>
          <a href={OrderOfExtension}>
            ORDER of Extension of the Merit Cum Means Scheme for the students
            upto 31.07.2021
          </a>
        </li>
</ul>
        <h1 className="scholorship-details-heading">SCHOLARSHIP DETAILS</h1>

        <ul>
          <li>
            <a href={AboutScholarshipsAtDPSRU}>
              About Scholarships at DPSRU
            </a>
          </li>
          <li>
            <a href={DELHI_MINORTIES}>
              State Funded Scholarship Schemes (for Delhi Minorities)
            </a>
          </li>
          <li>
            <a href={MERIT_CUM_MEANS_FOR_ALL_INCOME_BASED_DELHI}>
              Scheme of ‘MERIT CUM MEANS BASED SCHOLARSHIP’ for Students
              belonging to the Minority Communities
            </a>
          </li>
          <li>
            <a href={PostMatricScholarships}>
              Post Matric Scholarships (for ST Students)
            </a>
          </li>
          <li>
            <a href={MeritCumMeansLinkedFinancialAssistanceScheme}>
              Merit-cum-Means Linked Financial Assistance Scheme
            </a>
          </li>
          <li>
            <a href={RulesForAwardForNECMeritScholarship}>
              Rules for Award for NEC Merit Scholarship
            </a>
          </li>
          <li>
            <a href={CentralGovtScholarship}>
              Central Govt. Scholarship through National Scholarship Portal
            </a>
          </li>
          <li>
            <a href={pmsForScs}>
              Post Matric Scholarships (for SC Students)
            </a>
          </li>
          <li>
            <a href={PMSOBCRevisedGuidelinesE2018}>
              Post Matric Scholarships (for OBC Students)
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Scholorships;
