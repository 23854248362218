import React from "react";
import "./Events.css";
import G20CycleRally from "./Events Data/G20-CYCLE-RALLY-EVENT.pdf";
import panelDiscussionToInvestInPeople from "./Events Data/Panel-Discussion-new-.pdf";
import G20IndoBrazilFusionFest from "./Events Data/G20 Indo Brazil Fusion Fest.pdf";
import REPORTonPUSHPPRADARSHNAM2023 from "./Events Data/REPORT on PUSHP PRADARSHNAM 2023.pdf";
import G20MahafoodfestivalofChinaFranceGermanyatDPSRU from "./Events Data/G20 Maha food festival of China, France & Germany at DPSRU.pdf";
import G20QuizCompetitionatDPSRU from "./Events Data/G20 Quiz Competition at DPSRU.pdf";
import Conference2020 from "./Events Data/Conference 2020.pdf";
import Conference2022 from "./Events Data/Conference 2022.pdf";
import WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023 from "./Events Data/Workshop on Adjunct Mechanism in On Field Injury Rehabilitation Kinesiotaping On 13th Feb 2023.pdf";
import NationalScienceDay28thFeb2023 from "./Events Data/National Science Day 28th Feb 2023.pdf";
import UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage from "./Events Data/Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy children of Anganwadi School, Deoli village.pdf";

const Events = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">EVENTS</h2>

      <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">G20 Events</h2>
     
          <a href={G20CycleRally} className="event-files-link">
            G20 CYCLE RALLY at “Qutub Minar” on 25th May 2023
          </a>
          <a href={panelDiscussionToInvestInPeople} className="event-files-link">
          Panel Discussion @DPSRU on “TO INVEST IN PEOPLE, PRIORITIZE EDUCATION” on 24.01.2023
          </a>
          <a href={G20IndoBrazilFusionFest} className="event-files-link">
          G20 Indo Brazil Fusion Fest
          </a>
       
          <a href={REPORTonPUSHPPRADARSHNAM2023} className="event-files-link">
          REPORT on PUSHP PRADARSHNAM 2023
          </a>
       
          <a href={G20MahafoodfestivalofChinaFranceGermanyatDPSRU} className="event-files-link">
          G20 Maha food festival of China, France & Germany at DPSRU
          </a>
       
          <a href={G20QuizCompetitionatDPSRU} className="event-files-link">
          G20 Quiz Competition at DPSRU
          </a>
       
      </div>

      <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Conferences</h2>

        <a href={Conference2020} className="event-files-link">
        Conference 2020
          </a>
        <a href={Conference2022} className="event-files-link">
        Conference 2022
          </a>
    </div>

    <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Workshop</h2>
        <a href={WorkshoponAdjunctMechanisminOnFieldInjuryRehabilitationKinesiotapingOn13thFeb2023} className="event-files-link">
        Workshop on Adjunct Mechanism in On Field Injury Rehabilitation: Kinesiotaping On 13th Feb 2023
          </a>
    </div>

    <div className="event-data-container">
        <h2 className="dpsru-secondary-heading-for-every-page">Other Event</h2>
        <a href={NationalScienceDay28thFeb2023} className="event-files-link">
        National Science Day 28th Feb 2023
          </a>
        <a href={UnnatBharatAbhiyancellDPSRUDonatedChairstotheneedychildrenofAnganwadiSchoolDeolivillage} className="event-files-link">
        Unnat Bharat Abhiyan cell, DPSRU Donated Chairs to the needy children of Anganwadi School, Deoli village
          </a>
    </div>
    </div>
  );
};

export default Events;
