import React, { useState } from "react";
import "./Admissions.css";

import newGif from "../NEW/new.gif";

import contactus from "./Contact-us-for-Admission-Related-Query_page-0001-e1689748824681.f0df102630279354d7ad.jpg";

import admissionData01 from "./newData Of admissions/Notice-for-MBA-Non-Delhi-Document-Verification.pdf";
import admissionData02 from "./newData Of admissions/Notice-for-B.Pharm-L.E-Document-Verification.pdf";
import admissionData03 from "./newData Of admissions/Notice-for-M.Sc-AI-MPH-M.Sc-Sport-Science-Counseling-Document-Verification.pdf";
import admissionData04 from "./newData Of admissions/B.-Pharm.-L.E.-THIRD-ROUND-ONLINE-COUNSELLING-SCHEDULE-2023.pdf";
import admissionData05 from "./newData Of admissions/Second-Round-Online-Counseling-Schedule-for-PG-M.-Pharm.-Course-2023-24.pdf";
import admissionData06 from "./newData Of admissions/CamScanner-09-29-2023-18.15.21_1.pdf";
import admissionData07 from "./newData Of admissions/Revised-Provisional-Merit-List-of-candidates-Delhi-NON-DPSRU-MPH-1.pdf";
import admissionData08 from "./newData Of admissions/Revised-Provisional-Merit-List-of-candidates-NON-Delhi-1.pdf";
import admissionData09 from "./newData Of admissions/Revised-Provisional-Merit-List-of-candidates-Delhi-DPSRU-MPH.pdf";
import admissionData10 from "./newData Of admissions/Revised-Provisional-Merit-lists-Non-Delhi-for-MSc.-AIM-Sports-Sci.-and-MPH-Courses-Courses-2023.pdf";
import admissionData11 from "./newData Of admissions/Revised-Provisional-Merit-lists-Delhi-Non-DPSRU-for-MSc.-AIM-Sports-Sci.-and-MPH-Courses-Courses-2023.pdf";
import admissionData12 from "./newData Of admissions/Revised-Provisional-Merit-lists-Delhi-DPSRU-for-MSc.-AIM-Sports-Sci.-and-MPH-Courses-2023.pdf";
import admissionData13 from "./newData Of admissions/verification-of-Document-notice.pdf";
import admissionData14 from "./newData Of admissions/B.Pharm-LE-Second-Round-COUNSELLING-SCHEDULE-2023.pdf";
import admissionData15 from "./newData Of admissions/Provisional-GD-and-PI-Verified-List-DELHI-NON-DPSRU-2023-24-1.pdf";
import admissionData16 from "./newData Of admissions/Provisional-GD-and-PI-Verified-List-DELHI-DPSRU-2023-24-1.pdf";
import admissionData17 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-UG-SPECIAL-SPOT-ROUND-Non-Delhi.pdf";
import admissionData18 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-UG-SPECIAL-SPOT-ROUND-Delhi.pdf";
import admissionData19 from "./newData Of admissions/Date-extended-Notice-for-Final-Admission-Pre-PhD-2023-24.pdf";
import admissionData20 from "./newData Of admissions/Revised-M.Pharm-Provisional-Merit-list-of-Delhi-DPSRU.pdf";
import admissionData21 from "./newData Of admissions/Revised-M.Pharm-Provisional-Merit-list-of-Delhi-Non-DPSRU.pdf";
import admissionData22 from "./newData Of admissions/Revised-M.Pharm-Provisional-Merit-list-of-Non-Delhi.pdf";
import admissionData23 from "./newData Of admissions/Notice-for-Document-Verification.pdf";
import admissionData24 from "./newData Of admissions/ADMISSION-SCHEDULE-FOR-PG-M.-Pharm.-COURSE.pdf";
import admissionData25 from "./newData Of admissions/Notice-for-final-admission-Pre-Ph.D-2023-24.pdf";
import admissionData26 from "./newData Of admissions/List-of-final-admission-Pre-PhD-2023-24.pdf";
import admissionData27 from "./newData Of admissions/B.Pharm-LE-COUNSELLING-SCHEDULE-2023.pdf";
import admissionData28 from "./newData Of admissions/LE-Seat-Matrix-for-B.-Pharm-2023-24.pdf";
import admissionData29 from "./newData Of admissions/Result-of-Ph.D.-Entrance-Examination-2023-24.pdf";
import admissionData30 from "./newData Of admissions/Notice-and-List-for-candidates-of-Pre-PhD-Interview-September-2023.pdf";
import admissionData31 from "./newData Of admissions/List-for-approved-Supervisors-September-2023.pdf";
import admissionData32 from "./newData Of admissions/List-for-approved-Supervisors-September-2023.pdf";
import admissionData33 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-M.Sc-MPH-Delhi-NON-DPSRU.pdf";
import admissionData34 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-M.Sc-MPH-Delhi-DPSRU-2.pdf";
import admissionData35 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-M.Pharm-NON-Delhi.pdf";
import admissionData36 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-M.Pharm-Delhi-NON-DPSRU.pdf";
import admissionData37 from "./newData Of admissions/Provisional-Merit-List-of-candidates-applied-for-M.Pharm-Delhi-DPSRU.pdf";
import admissionData38 from "./newData Of admissions/Provisional-Merit-List-of-candidates-B.Pharm-LE-NON-DPSRU.pdf";
import admissionData39 from "./newData Of admissions/Provisional-Merit-List-of-candidates-B.Pharm-LE-DPSRU.pdf";
import admissionData40 from "./newData Of admissions/Spot-Round-Schedule-for-Admissions-to-UG-courses-2023-24.pdf";
import admissionData41 from "./newData Of admissions/Revised-Vacant-Seats-for-Special-Spot-Round.pdf";
import admissionData42 from "./newData Of admissions/Provisional-Merit-List-of-Outside-DelhiSpot-Round.pdf";
import admissionData43 from "./newData Of admissions/Provisional-Merit-List-of-DelhiSpot-Round.pdf";
import admissionData44 from "./newData Of admissions/List-of-B.Pharm-LE-Candidates-who-have-not-filled-course-preferences.pdf";
import admissionData45 from "./newData Of admissions/List-of-PG-Candidates-who-have-not-filled-course-preferences.pdf";
import admissionData46 from "./newData Of admissions/Alphabetical-List-of-ALL-PG-CANDIDATES.pdf";
import admissionData47 from "./newData Of admissions/Alphabetical-List-of-ALL-B.Pharm-LE-CANDIDATES (1).pdf";

import admissionData48 from "./newData Of admissions/Notice-for-Document-Verification-1st-to-6th-Round.pdf";
import admissionData49 from "./newData Of admissions/Notice-for-Document-Verification (1).pdf";
import admissionData50 from "./newData Of admissions/6th-round-online-counseling.pdf";
import admissionData51 from "./newData Of admissions/Fifth-Schedule.rtf";
import admissionData52 from "./newData Of admissions/Fifth-Schedule.rtf";
import admissionData53 from "./newData Of admissions/4th-Round-Counselling-Schedule.pdf";
import admissionData54 from "./newData Of admissions/THIRD-ROUND-Counselling-Schedule.pdf";
import admissionData55 from "./newData Of admissions/First-round-online-counseling-2023.pdf";
import admissionData56 from "./newData Of admissions/First-round-online-counseling-2023.pdf";
import admissionData57 from "./newData Of admissions/Revised-Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";
import admissionData58 from "./newData Of admissions/Revised-Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import admissionData59 from "./newData Of admissions/Alphabetical-List-of-candidates-who-have-not-filled-course-Preferences.pdf";
import admissionData60 from "./newData Of admissions/Notice-regarding-UG-courses-preference-filling-2023-24.pdf";
import admissionData61 from "./newData Of admissions/Advertisement-2023-24-1.pdf";
import admissionData62 from "./newData Of admissions/Phd-Admission-2023-24.pdf";

// Admission link

import admissionLink01 from "./newData Of admissions/Admission link/MPT-Entrance-Result.pdf";
import admissionLink02 from "./newData Of admissions/Admission link/Final-Write-up-for-advertisement.pdf";
import admissionLink03 from "./newData Of admissions/Admission link/B.-Pharm-Ayurveda.pdf";
import admissionLink04 from "./newData Of admissions/Admission link/BBA-INFORMATION-BROCHURE-.pdf";
import admissionLink06 from "./newData Of admissions/Admission link/SAHS-Brochure (1).pdf";
import admissionLink07 from "./newData Of admissions/Admission link/Vacant-Seats-for-Online-Spot-Round-1.pdf";
import admissionLink08 from "./newData Of admissions/Admission link/Spot-Round-for-online-counseling-schedule.pdf";
import admissionLink09 from "./newData Of admissions/Admission link/Notice-1.pdf";
import admissionLink10 from "./newData Of admissions/Admission link/document-verification-notice.pdf";
import admissionLink11 from "./newData Of admissions/Admission link/WhatsApp-Image-2023-07-22-at-10.55.13-AM.jpeg";
import admissionLink14 from "./newData Of admissions/Admission link/Prospectus-2023-for-web.pdf";
import admissionLink16 from "./newData Of admissions/Admission link/Phd-Admission-2023-24.pdf";

import thirdRoundOnlineCouneling from "./newData Of admissions/Third-Round-Online-Counseling-Schedule-for-PG-M.-Pharm.-Course-2023-24.pdf";

import mPharmaDovVeri2324 from "./newData Of admissions/Notice for M.Pharm Document Verification 2023-24.pdf";
import noticeForFinalSportRound2324 from "./newData Of admissions/Notice for FINAL SPOT ROUND 2023-24.pdf";
import ProvisionalMaritListNonDMBA from "./newData Of admissions/PROVISIONAL MERIT LIST OF MBA NON DELHI.pdf";
import noticeForMbaNonDelhiCandDocVerification from "./newData Of admissions/Notice for MBA (Non-Delhi) Candidate Document Verification.pdf";
import fourthRoundOnlineCounselingSehedule from "./newData Of admissions/Fouth-Round-Online-Counseling-Schedule-for-PG-M.-Pharm.-Course-2023-24.222b79df6116f2c66740.pdf";
import fifthRoundCounselingScheduleforpgMpharm from "./newData Of admissions/FIFTH ROUND COUNSELING SCHEDULE FOR PG (M. Pharm.) COURSE.pdf";
import SixthRoundOnlineCounselingScheduleforPG from "./newData Of admissions/Sixth Round Online Counseling Schedule for PG (M. Pharm.) Course (2023-24).pdf";
import NoticeForAdmissionsNoticeForMPHDocumentVerification from "./newData Of admissions/Notice for MPH Document Verification 2023-24.pdf";

import noticeForMptCounselingAndDocVerification from "./newData Of admissions/Notice for MPT counseling and document verification 2023-24.pdf";

import noticeForMbaCounselingAndDocVerification from "./newData Of admissions/Notice for MBA counseling and document verification 2023-24.pdf";

import noticeFormpharmnondelhigpat from "./newData Of admissions/Notice for M.Pharm, Nond-Delhi GPAT counseling and document verification 2023-24.pdf";
import noticeformscdocverifiation from "./newData Of admissions/Notice for M.Sc. Document Verification.pdf";
import noticeforenrolmentformsubmission from "./newData Of admissions/Notice for Enrolment Form Submission (Session 2023-24).pdf";
import noticefornotpgpatcandiacondocv from "./newData Of admissions/Notice for Non-GPAT Candidates Counseling and Document Verification.pdf";
import finalspotroundformbaadmission from "./newData Of admissions/Final spot round for MBA admission.pdf";
import noticefordocverificationofofnondelhicand from "./newData Of admissions/Notice for document verification  of Non-Delhi Candidates applied for M.Pharm courses (2023-24).pdf";
import CuetPg2024ParticipationUniversities from "./newData Of admissions/CUET PG 2024 - Participating Universities.pdf";
import prospectus20212022 from './newData Of admissions/Prospectus-2021-22_Web.pdf'
import prospectus20222023 from './newData Of admissions/Prospectus-2022-23_Website.pdf'
// import admissioninformationforPGandUGcorses from './newData Of admissions/ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES.pdf'
import admissioninformationforPGandUGcorses from './newData Of admissions/ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES_new.pdf'
// import prospectus202425 from './newData Of admissions/Admission link/Prospectus 2024-2025.pdf'
//  import prospectus202425 from './newData Of admissions/Prospectus 2024-25.pdf'
 import prospectus202425 from './newData Of admissions/Admission link/Prospectus 2024-25-updated.pdf'
import faqofadminssioa from './newData Of admissions/Admission related FAQs.pdf'
import regardingObcNoncreamyadmission2425 from './newData Of admissions/REGARDING OBC NON CREAMY ADMISSIONS 2024-24.pdf'
// import revisedAdmissionScheduleofUgCourses2425 from './newData Of admissions/REVISED ADMISSION SCHEDULE OF UG COURSES 2024-25.pdf'
// import revisedAdmissionScheduleofUgCourses2425 from './newData Of admissions/admission scheudule for all UG courses 2024-25.pdf'
import ExtensionofRegistrationdateforugcourses from './newData Of admissions/Extension of registration date for UG Courses.pdf'
import newRevisedAdmissionSchedule from './newData Of admissions/New Revised Admission Scedule.pdf'
import vacantpositionofseatsforvariousundergradutecoursesforadmission2425 from './newData Of admissions/Vacant position of seats for various undergraduate courses for admission 2024-25.pdf'
import cumulativereportofAdmissionforvariousundergradutecoursetill11jul from './newData Of admissions/Cumulative report of admissions for various undergraduate courses till 11-07-2024 up to the merit number 600 (Delhi General).pdf'
import cumulativereportofadmissiotill12juluptono900 from './newData Of admissions/Cumulative report of admissions till  12-07-2024 up to the merit number 900.pdf'
import cumulativereportofasdmission13724 from './newData Of admissions/cumulative report of admission 13.07.2024.pdf'
import AdmissionSchedyleforpwdCandidates from './newData Of admissions/Admission schedule for PWD candidates.pdf'
import cumutivereportofadvacsheet18jul24 from './newData Of admissions/Cumulative report of admissions vacant seats in UG Courses.pdf'
import reportofsadd19724 from './newData Of admissions/Cumulative report of admissions vacant seats in UG Courses 19 07 24.pdf'
import cumulativereportofadmissionsvacentseat200724 from './newData Of admissions/Cumulative report of admissions vacant seats in supernumerary seats as on 20- 07 -2024.pdf'
import admissionsvacantseatsavailableinugcoursesfornondelgen from './newData Of admissions/Admissions vacant seats available in UG Courses for Non Delhi (General).pdf'
import extensionfolastdatforpgandlateralentry from './newData Of admissions/Extension of Last Date for P.G. and Lateral Entry.pdf'
import cumullativeadmissionvacantseatsavainugcounondeva23724 from './newData Of admissions/Cumulative Admissions vacant seats available in UG Courses for Non Delhi various categories as on 23 7 24 .pdf'
import BscSportssciecevacantseatsavainugcounondeva24724 from './newData Of admissions/B.Sc. sports report of admission vacant seats for delhi and non delhi as on 24.07.2024.pdf'
import noticeforextensionofPGcoursesdates from './newData Of admissions/notice for extension of PG course dates.pdf'
import demandforadmissionfeepaymentdoc from './newData Of admissions/DEMAND FOR ADMISSION FEE PAYMENT doc.pdf'



function Admissions() {
  return (
    <div className="dpsru-every-component-css">
      <div className="admission-flex-container-main">
        <div className="admissions-container-each inner-container">
          <h2 className="dpsru-primary-heading-for-every-page">ADMISSIONS</h2>

          <ol>
            
            <li>
              <strong>
                {" "}
                <a href={demandforadmissionfeepaymentdoc} style={{color:'red'}}>
                DEMAND FOR ADMISSION FEE PAYMENT doc
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticeforextensionofPGcoursesdates} style={{color:'red'}}>
                Notice for extension of PG course dates
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={BscSportssciecevacantseatsavainugcounondeva24724} style={{color:'red'}}>
                B.Sc. sports report of admission vacant seats for delhi and non delhi as on 24.07.2024
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={cumullativeadmissionvacantseatsavainugcounondeva23724} style={{color:'red'}}>
                Cumulative Admissions vacant seats available in UG Courses for Non Delhi various categories as on 23-7-24
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={extensionfolastdatforpgandlateralentry} style={{color:'red'}}>
                Extension of Last Date for P.G. and Lateral Entry
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={admissionsvacantseatsavailableinugcoursesfornondelgen} style={{color:'red'}}>
                Admissions vacant seats available in UG Courses for Non Delhi (General)
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            
            <li>
              <strong>
                {" "}
                <a href={cumulativereportofadmissionsvacentseat200724} style={{color:'red'}}>
                Cumulative report of admissions vacant seats in supernumerary seats as on 20-07-2024
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={reportofsadd19724} style={{color:'red'}}>
                Cumulative report of admissions/vacant seats in UG Courses
                as on 19-07-2024
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={cumutivereportofadvacsheet18jul24} style={{color:'red'}}>
                Cumulative report of admissions/vacant seats in UG Courses as on 18-07-2024
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={AdmissionSchedyleforpwdCandidates} style={{color:'red'}}>
                Admission schedule for PWD candidates
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={cumulativereportofasdmission13724} style={{color:'red'}}>
                Cumulative report of admissions for various undergraduate courses till 13-07-2024 up to the merit number  1200 
                (Delhi General)
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={cumulativereportofadmissiotill12juluptono900} style={{color:'red'}}>
                Cumulative report of admissions till  12-07-2024 up to the merit number 900
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={cumulativereportofAdmissionforvariousundergradutecoursetill11jul} style={{color:'red'}}>
                Cumulative report of admissions for various undergraduate courses till 11-07-2024 up to the merit number 600 (Delhi General)
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={vacantpositionofseatsforvariousundergradutecoursesforadmission2425} style={{color:'red'}}>
                Vacant position of seats for various undergraduate courses for admission 2024-25
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={newRevisedAdmissionSchedule} style={{color:'red'}}>
                New revised admission Schedule 2024-25
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={ExtensionofRegistrationdateforugcourses} style={{color:'red'}}>
                Extension of registration date for UG Courses
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={regardingObcNoncreamyadmission2425} style={{color:'red'}}>
                REGARDING OBC NON CREAMY ADMISSIONS 2024-24
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            
            <li>
              <strong>
                {" "}
                <a href={admissioninformationforPGandUGcorses} style={{color:'red'}}>
                ADMISSION INFORMATION 2024-2025 FOR UG AND PG COURSES
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            
            <li>
              <strong>
                {" "}
                <a href={CuetPg2024ParticipationUniversities}>
                CUET PG 2024 - Participating Universities
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticefordocverificationofofnondelhicand}>
                  Notice for document verification of Non-Delhi Candidates
                  applied for M.Pharm courses (2023-24){" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={finalspotroundformbaadmission}>
                  Final spot round for MBA admission{" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticefornotpgpatcandiacondocv}>
                  Notice for Non-GPAT Candidates Counseling and Document
                  Verification <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticeforenrolmentformsubmission}>
                  Notice for Enrolment Form Submission (Session 2023-24){" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticeformscdocverifiation}>
                  Notice for M.Sc. Document Verification{" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticeFormpharmnondelhigpat}>
                  Notice for M.Pharm, Non-Delhi GPAT counseling and document
                  verification 2023-24 <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>

            <li>
              <strong>
                {" "}
                <a href={noticeForMbaCounselingAndDocVerification}>
                  Notice for MBA counseling and document verification 2023-24{" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>
            <li>
              <strong>
                {" "}
                <a href={noticeForMptCounselingAndDocVerification}>
                  Notice for MPT counseling and document verification 2023-24{" "}
                  <img src={newGif} alt="new" />
                </a>
              </strong>
            </li>

            <li>
              <strong>
                <a href={NoticeForAdmissionsNoticeForMPHDocumentVerification}>
                  Notice for MPH Document Verification 2023-24
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={SixthRoundOnlineCounselingScheduleforPG}>
                  Sixth Round Online Counseling Schedule for PG (M. Pharm.)
                  Course (2023-24)
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={fifthRoundCounselingScheduleforpgMpharm}>
                  FIFTH ROUND COUNSELING SCHEDULE FOR PG (M. Pharm.) COURSE
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={fourthRoundOnlineCounselingSehedule}>
                  Fourth Round Online Counseling Schedule for PG (M. Pharm.)
                  Course (2023-24)
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={noticeForMbaNonDelhiCandDocVerification}>
                  Notice for MBA (Non-Delhi) Candidate Document Verification
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={ProvisionalMaritListNonDMBA}>
                  PROVISIONAL MERIT LIST OF MBA NON DELHI
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={noticeForFinalSportRound2324}>
                  Notice for FINAL SPOT ROUND 2023-24
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={mPharmaDovVeri2324}>
                  Notice for M.Pharm Document Verification 2023-24
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>

            <li>
              <strong>
                <a href={thirdRoundOnlineCouneling}>
                  Third Round Online Counseling Schedule for PG (M. Pharm.)
                  Course (2023-24)
                </a>{" "}
                <img src={newGif} alt="new" />
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData01}>
                  Notice for MBA (Non Delhi) Document Verification&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData02}>
                  Notice for B.Pharm L.E Document Verification&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData03}>
                  Notice for M.Sc AI, MPH &amp; M.Sc Sport Science Counseling
                  &amp; Document Verification&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData04}>
                  B. Pharm. (L.E.) THIRD ROUND ONLINE COUNSELLING
                  SCHEDULE-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData05}>
                  Second Round Online Counseling Schedule for PG (M. Pharm.)
                  Course (2023-24)&nbsp;
                </a>
              </strong>{" "}
              &nbsp;
            </li>
            <li>
              <strong>
                <a href={admissionData06}>
                  Notice regarding MPT admission counselling 2023-24&nbsp;
                </a>
              </strong>{" "}
            </li>
            <li>
              <strong>
                <a href={admissionData07}>
                  Revised Provisional Merit List of candidates (Delhi NON DPSRU)
                  MPH&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData08}>
                  Revised Provisional Merit List of candidates (NON
                  Delhi)&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData09}>
                  Revised Provisional Merit List of candidates (Delhi DPSRU)
                  MPH&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData10}>
                  Revised Provisional Merit lists (Non Delhi) for MSc. AIM,
                  Sports Sci. and MPH Courses Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData11}>
                  Revised Provisional Merit lists (Delhi Non DPSRU) for MSc.
                  AIM, Sports Sci. and MPH Courses Courses-2023&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <a href={admissionData12}>
                <strong>
                  Revised Provisional Merit lists (Delhi DPSRU) for MSc. AIM,
                  Sports Sci. and MPH Courses-2023&nbsp;&nbsp;
                </strong>
              </a>
            </li>
            <li>
              <strong>
                <a href={admissionData13}>
                  Notice for MBA Document Verification&nbsp;&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData14}>
                  B. Pharm LE Second Round COUNSELLING SCHEDULE-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData15}>
                  Provisional GD and PI Verified List (DELHI NON DPSRU)
                  2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData16}>
                  Provisional GD and PI Verified List (DELHI DPSRU)
                  2023-24&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData17}>
                  Provisional Merit List of candidates applied for UG SPECIAL
                  SPOT ROUND (Non Delhi)&nbsp;&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData18}>
                  Provisional Merit List of candidates applied for UG SPECIAL
                  SPOT ROUND (Delhi)&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData19}>
                  Date extended Notice for Final Admission Pre-PhD
                  2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData20}>
                  Revised M. Pharm Provisional Merit list of Delhi
                  DPSRU&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData21}>
                  Revised M. Pharm Provisional Merit list of Delhi Non
                  DPSRU&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <a href={admissionData22}>
                <strong>
                  Revised M. Pharm Provisional Merit list of Non
                  Delhi&nbsp;&nbsp;
                </strong>
              </a>
            </li>
            <li>
              <strong>
                <a href={admissionData23}>
                  Notice for Document Verification&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData24}>
                  ADMISSION SCHEDULE FOR PG (M. Pharm.) COURSE&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData25}>
                  Notice for final admission Pre-PhD 2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData26}>
                  List of selected candidates for the Final Admissions to
                  Pre-PhD 2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData27}>
                  B. Pharm LE COUNSELLING SCHEDULE-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData28}>
                  List of Vacant Seats for B. Pharm. L.E Admission 2023-24&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData29}>
                  Result of Ph.D. Entrance Examination 2023-24&nbsp;&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData30}>
                  Notice and List for candidates of Pre-PhD Interview, September
                  2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData31}>
                  List for approved Supervisors September 2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData32}>
                  Provisional Merit lists (Non Delhi) for MSc. AIM, Sports Sci.
                  and MPH Courses Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData33}>
                  Provisional Merit lists (Delhi Non DPSRU) for MSc. AIM, Sports
                  Sci. and MPH Courses Courses-2023&nbsp; &nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData34}>
                  Provisional Merit lists (Delhi DPSRU) for MSc. AIM, Sports
                  Sci. and MPH Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData35}>
                  Provisional Merit lists (Non Delhi) for PG
                  Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData36}>
                  Provisional Merit lists (Delhi Non DPSRU) for PG
                  Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData37}>
                  Provisional Merit lists (Delhi DPSRU) for PG
                  Courses-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData38}>
                  Provisional Merit List of Non-Delhi candidates B. Pharm LE
                  2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData39}>
                  Provisional Merit List of Delhi candidates B. Pharm LE
                  2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData40}>
                  Special Spot Round Schedule for Admissions to UG courses
                  (2023-24)&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData41}>
                  Vacant Seats for Special Spot Round Starting from 28/8/2023
                  [Revised]
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData42}>
                  Provisional merit lists of Non-Delhi Candidates (Spot Round
                  for UG courses) -2023&nbsp; &nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData43}>
                  Provisional merit list of Delhi Candidates (Spot Round for UG
                  courses) -2023&nbsp; &nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData44}>
                  List of Candidates who have not filled B. Pharm. LE Course
                  preference (2023-24)&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData45}>
                  List of Candidates who have not filled PG Course preference
                  (2023-24)&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData46}>
                  Alphabetical list of candidates applied for PG
                  courses-2023&nbsp;&nbsp;{" "}
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData47}>
                  Alphabetical list of candidates applied for B. Pharm. LE
                  course-2023&nbsp; &nbsp;
                </a>
              </strong>
            </li>
            <li>
              <a href={admissionData48}>
                <strong>
                  Notice for Document Verification Dated 11/08/2023&nbsp; &nbsp;
                </strong>
              </a>
            </li>
            <li>
              <strong>
                <a href={admissionData49}>
                  Notice for Document Verification&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <a href={admissionData50}>
                <strong>6th Round Online Counselling Schedule – 2023</strong>{" "}
                &nbsp;
              </a>
            </li>
            <li>
              <a href={admissionData51}>
                <strong>5th Round Online Counselling Schedule – 2023</strong>{" "}
                &nbsp;
              </a>
            </li>
            <li>
              <a href={admissionData52}>
                <strong>5th Round Online Counselling Schedule – 2023</strong>{" "}
                &nbsp;
              </a>
            </li>
            <li>
              <a href={admissionData53}>
                <strong>4th Round Online Counselling Schedule – 2023</strong>{" "}
                &nbsp;
              </a>
            </li>
            <li>
              <strong>
                <a href={admissionData54}>
                  Third round online counselling schedule-2023&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData55}>
                  Second round online counselling schedule-2023&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData56}>
                  First round online counselling schedule-2023&nbsp;
                </a>
                &nbsp;
              </strong>
            </li>
            <li>
              <a href={admissionData57}>
                <b>
                  Provisional Revised Merit Lists of Delhi candidates for UG
                  courses-2023{" "}
                </b>
              </a>
              <b>&nbsp;&nbsp;</b>
            </li>
            <li>
              <strong>
                <a href={admissionData58}>
                  Provisional Revised Merit Lists of Non-Delhi candidates for UG
                  courses-2023
                </a>{" "}
                &nbsp;
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData59}>
                  Alphabetical List of candidates who have not filled course
                  Preference&nbsp; &nbsp;
                </a>
              </strong>
            </li>
            <li>
              <strong>
                <a href={admissionData60}>
                  Notice regarding UG courses preference filling
                  2023-24&nbsp;&nbsp;
                </a>
              </strong>
            </li>
            <li>
              <a href={admissionData61}>
                <b>Announcement&nbsp;of Admissions (2023-24)&nbsp;</b>
              </a>
              &nbsp;
            </li>
            <li>
              <strong>
                <a href={admissionData62}>
                  Notice for PhD admission&nbsp;&nbsp;
                </a>
              </strong>
            </li>
          </ol>
        </div>

        {/* Admissions Link */}
        {/* Admissions Link */}
        {/* Admissions Link */}
        {/* Admissions Link */}
        {/* Admissions Link */}
        <div className="admissions-links-container-each inner-container">
          <h2 className="dpsru-primary-heading-for-every-page">
            Admissions Links
          </h2>

          <p style={{color:'red'}}>
            <a href="https://dpsruonline.in/">
              <strong style={{color:'red'}}>
              Click here for Admissions to UG & PG Courses for the year 2024-25
              </strong>{" "} <img src={newGif} alt="new" />
              &nbsp;
            </a>
          </p>

          <p style={{color:'blue'}}>
          <strong>
                {" "}
                <a href={faqofadminssioa} style={{color:'red', textDecoration:'underline'}}>
                Admission related FAQs
                  <img src={newGif} alt="new" />
                </a>
                </strong>
                </p>

          <p>
            <strong>
              <a href={prospectus202425} style={{color:'red'}}>
              Prospectus for the academic session 2024-25 <img src={newGif} alt="new" />
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink01}>
                Result of MPT entrance exam. 2023&nbsp;&nbsp;
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink02}>
                Scope and Job Prospects of Sports Science&nbsp;
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink03}>
                Scope and Job Prospects of B. Pharm. (Ayurveda) Course&nbsp;
              </a>{" "}
              &nbsp;
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink04}>
                Scope and Job Prospects of BBA- Health Care Course&nbsp; &nbsp;
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a
                href="https://www.youtube.com/watch?v=-gdh9t_4eI0"
                target="_blank"
                rel="noopener"
              >
                {" "}
                [BBA Health Care Course Click Here for more details]&nbsp;{" "}
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink06}>
                Scope and Job Prospects of DMLT, BMLT and BSc. (Hon.) Biomedical
                Science Courses
              </a>{" "}
              &nbsp;
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink07}>
                Vacant seat for online spot Round&nbsp;&nbsp;&nbsp;&nbsp;
              </a>
            </strong>
          </p>
          <p>
            <a href={admissionLink08}>
              <strong>
                Spot Round Schedule for Admissions to UG courses
                (2023-24)&nbsp;&nbsp;
              </strong>
            </a>
          </p>
          <p>
            <a href={admissionLink09}>
              <strong>
                Notice admission withdrawal last date for UG courses
              </strong>
            </a>{" "}
            &nbsp;
          </p>
          <p>
            <strong>
              <a href={admissionLink10}>
                1st Notice regarding document verification at University for UG
                Courses&nbsp; &nbsp;
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink11}>
                Notice for extension of the online application deadline for the
                B. Pharm LE and PG courses
              </a>
              &nbsp;
            </strong>
          </p>
          <p>
            <a href="https://dpsruonline.in/">
              <strong>Candidate login for&nbsp;</strong>
              <b>Counseling&nbsp;</b>
            </a>
          </p>
          
          <p>
            <a href={admissionLink14}>
              <strong>Prospectus 2023</strong>
            </a>
          </p>
         
          <p>
            <a href={prospectus20222023}>
              <strong>Prospectus 2022</strong>
            </a>
          </p>
          <p>
            <a href={prospectus20212022}>
              <strong>Prospectus 2021</strong>
            </a>
          </p>
          <p>
            <strong>
              <a href="https://dpsruonline.in/">
                Online Admission Process Flow Chart (2023-24)
              </a>
            </strong>
          </p>
          <p>
            <strong>
              <a href={admissionLink16}>PhD Notice </a>
            </strong>
          </p>
        </div>
      </div>

      <h2 className="dpsru-secondary-heading-for-every-page">Contact us</h2>

      <div className="admission-contact-us-img-container">
        <img src={contactus} alt="Contact us" />
      </div>
    </div>
  );
}

export default Admissions;
