import React, { useState } from "react";
import "./DpsruHomeTabsUpdated.css";
import { Link } from "react-router-dom";

// at 10/10/23
import meenuGSharma from "./TabFiles/WhatsNew/WhatsNew Data/PhD-Viva-Voce-Notification-of-Ms.-Meenu-Grover-Sharma.pdf";
import proudMomentranked from "./TabFiles/WhatsNew/WhatsNew Data/proud-moment-for-our-university-ranked-first-in-North-zone.pdf";
import GpatScore from "./TabFiles/WhatsNew/WhatsNew Data/DSPRU-GPAT-Score-2023.pdf";
import ExaminationJune from "./TabFiles/WhatsNew/WhatsNew Data/NOTICE-OF-EXAMINATION-JUNE-2023.pdf";
import CycleRally from "./TabFiles/WhatsNew/WhatsNew Data/WhatsApp-Image-2023-05-29-at-8.07.24-AM-e1685359074892.jpeg";
import admissionAnnoucement from "./TabFiles/WhatsNew/WhatsNew Data/Advertisement-2023-24-1.pdf";

// new data at 17-05-2023

// import Phd_Admission_202_24 from "./TabFiles/WhatsNew/WhatsNew Data/Phd_Admission_202_24.pdf";
import G20QuizCompetitionatDPSRU from "./TabFiles/WhatsNew/WhatsNew Data/G20QuizCompetitionatDPSRU.jpeg";
// import iastamOration13new from "./TabFiles/WhatsNew/WhatsNew Data/13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL CONCLAVE.pdf";

// import dkyn02 from "./TabFiles/WhatsNew/WhatsNew Data/dkyn02.pdf";
// import dkyn01 from "./TabFiles/WhatsNew/WhatsNew Data/dkyn01.pdf";
// import r5thannualc from "./TabFiles/WhatsNew/WhatsNew Data/r5thannualc.pdf";
// import dpsruAnnualCon from "./TabFiles/WhatsNew/WhatsNew Data/Convocation-Announcement-2-1.pdf";
// import vigilanceAwarenessWeekImage from "./TabFiles/WhatsNew/WhatsNew Data/vigilanceAwarenessWeekImage.jpeg";
// import NoticeForStudentFacultyAndStaff from "./TabFiles/WhatsNew/WhatsNew Data/NoticeForStudentFacultyAndStaff.pdf";
// import ApplicationsforadmissionsofPGprogramsextendedto29thAugust2022 from "./TabFiles/WhatsNew/WhatsNew Data/Applications-for-admissions-of-PG-programs-extended-to-29th-August-2022.jpg";
// import scanSRF from './TabFiles/WhatsNew/WhatsNew Data/scan-SRF.pdf'
// import DPSRUNIBJointCertificateProgramonQualityAssuranceinBiopharmaceuticals from './TabFiles/WhatsNew/WhatsNew Data/DPSRU-NIB-Joint-Certificate-Program-on-Quality-Assurance-in-Biopharmaceuticals.jpg'
// import ListoFStudentSeclectedForPrePhD from './TabFiles/WhatsNew/WhatsNew Data/ListoFStudentSeclectedForPrePhD.pdf'
// import PrePhdNoticeAnnexure2022 from './TabFiles/WhatsNew/WhatsNew Data/PrePhdNoticeAnnexure2022.pdf'
// import Listofcandidatesofphdentranceexam from './TabFiles/WhatsNew/WhatsNew Data/List-of-candidates-of-phd-entrance-exam.pdf'
// import NoticeForPrePhDEntreanceTest from './TabFiles/WhatsNew/WhatsNew Data/NoticeForPrePhDEntreanceTest.jpeg'
// import PhDAdmissionForm from './TabFiles/WhatsNew/WhatsNew Data/Ph.D-Admission-Form.pdf'

// student file import

import ProbvisionalMeritListONonD from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Provisional-Merit-Lists-of-Non-Delhi-candidates-for-UG-courses-2023.pdf";
import ProbvisionalMeritListDelhiC from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Provisional-Merit-Lists-of-Delhi-candidates-for-UG-courses-2023.pdf";

import vivasewteparoha from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Ph.D-Viva-Voce-Notification-of-Ms.-Shweta-Paroha.pdf";
import evensemexamform from "./TabFiles/News Of Dpsru/News Of Dpsru Data/EVEN-SEMESTER-EXAMINATION-FORM-NOTICE-2023.pdf";
import consSche4thround from "./TabFiles/News Of Dpsru/News Of Dpsru Data/4th-Round-Counselling-Schedule.pdf";
import PhdSupplementaryFee from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Pre-Ph.D-Supplementary-Fee-Notice_1.pdf";
import ThirdRoudCons from "./TabFiles/News Of Dpsru/News Of Dpsru Data/THIRD-ROUND-Counselling-Schedule.pdf";
import firstRoundCons from "./TabFiles/News Of Dpsru/News Of Dpsru Data/First-round-online-counseling-2023.pdf";
import ExamFeeNotice from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Examination-Fee-Notice-2023.pdf";
import AnnouncementOfAdmission from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Advertisement-2023-24-1.pdf";
import HostalMessFee from "./TabFiles/News Of Dpsru/News Of Dpsru Data/WhatsApp-Image-2023-05-23-at-2.17.13-PM.pdf";

// import dpsruHarGharTiranga3 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/dpsruHarGharTiranga3.pdf";
// import ProfRameshGoyalsvisittoUCSIUniversityasDistinguishedVisitingFaculty from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Prof.-Ramesh-Goyals-visit-to-UCSI-University-as-Distinguished-Visiting-Faculty.pdf";
// import DSIRDPSRUGujaratvisit1 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/DSIR-DPSRU-Gujarat-visit-1.pdf";
// import CAFTConstitutiondaycelebrations from "./TabFiles/News Of Dpsru/News Of Dpsru Data/CAFT-Constitution-day-celebrations.pdf";
// import pediatricunit from "./TabFiles/News Of Dpsru/News Of Dpsru Data/pediatric-unit.pdf";
// import coviddrugnews from "./TabFiles/News Of Dpsru/News Of Dpsru Data/covid-drug-news.pdf";
// import BookRelease from "./TabFiles/News Of Dpsru/News Of Dpsru Data/Book-Release.pdf";
// import highimpactresearchpapermodified1 from "./TabFiles/News Of Dpsru/News Of Dpsru Data/high-impact-research-paper-modified-1.pdf";
// import DPSRUStudentsAchievement from "./TabFiles/News Of Dpsru/News Of Dpsru Data/DPSRU-Students-Achievement.pdf";

// import CONGRATULATIONS_GAURAV from "./TabFiles/News Of Dpsru/News Of Dpsru Data/CONGRATULATIONS_GAURAV.pdf";
// import ReAppointmentOfProfessior from "./TabFiles/News Of Dpsru/News Of Dpsru Data/ReAppointmentOfProfessior.jpeg";

// archivment
import vivaMsNeha from "./TabFiles/News Of Dpsru/Ph.D-Viva-Voce-Notification-of-Ms.-Neha.pdf";
import vivaMrPrateek from "./TabFiles/News Of Dpsru/Ph.D-Viva-Voce-Notification-of-Mr.-Prateek-Sharma.pdf";

// import walkinInterview3010 from "../Notifications/Notification Files/Advertisement.pdf";

import circularForTakingIntegrityPledge from "../Notifications/Notification Files/Circular for taking integrity pledge.pdf";
import CircularForTakinganOrganistionalOath from "../Notifications/Notification Files/Circular for taking an organisational Oath on 3 November 2023 in DPSRU.pdf";
import convoForm6th from "../Notifications/Notification Files/6th convocation registration form 2023.pdf";
import walkinInterviewOn17Nov from "../Notifications/Notification Files/Interview on 17.11.23.pdf";

import walkinInterviewResult from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 03-11-2023.pdf";
import walkininterviewresulton17 from "../Recruitments/Recruitment Data/Result of Walk-in-Interview for Guest Faculties held on 17-11-2023.pdf";
import noticeforprofssagarwaltropyforbptstudents from "../Students/StudentsData/Notice for PROF. S.S. AGRAWAL TROPHY for BPT students.pdf";
import recrutmentofjrffellow from "../Recruitments/Recruitment Data/Advertisement for the Post of Junior Research Fellow (JRF).pdf";
import noticeforrehearsaltimingforcovo21stdecandceremony from "../Notifications/Notification Files/Notice for Rehearsal timings for convocation 21st December and  ceremony instructions for convocation 22nd December 2023.pdf";
import convoAwadiName from "../Notifications/Notification Files/List of Awardee’s for 6th Convocation 2023.pdf";
import ReOpeningofoddsemfeeportalnotice from "../Students/StudentsData/Re-Opening of Odd Semester Fee Portal, NOTICE.pdf";
import icmrprojectresearchscientisnosnsme from '../Notifications/Notification Files/Applications are invited for the post of ICMR-Project Research Scientist - I Non-Medical Last date May 27, 2024.pdf'

const DpsruHomeTabsUpdated = () => {
  return (
    <div className="dpsru-bottom-tabs-section-component">
      <div className="dpsru-home-bottom-tabs-container">
        <div className="dpsru-bottom-tabs-each-box first-whatsnew-container">
          <h2 className="dpsru-secondary-heading-for-every-page">WHAT'S NEW</h2>

          <div className="inner-content-container-of-tab-home-dpsru">
            {/* First section */}
            <ul className="first-ul-of-tab-container ">
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={icmrprojectresearchscientisnosnsme}>
                    Applications are invited for the post of ICMR-Project Research Scientist - I (Non-Medical)" (Last date: May 27, 2024)"
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    16-05-2024
                  </li>
                </ul>
              </li>
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={recrutmentofjrffellow}>
                      Advertisement for the Post of Junior Research Fellow (JRF)
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    19-12-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={walkininterviewresulton17}>
                      Result of Walk-in-Interview for Guest Faculties held on
                      17/11/2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    23-11-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={walkinInterviewResult}>
                      Result of Walk-in-Interview for Guest Faculties held on
                      03/11/2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    09-11-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={walkinInterviewOn17Nov}>
                      Walk-in Interview for Guest Faculties of Pharmacy and
                      Physiotherapy on 17/11/2023 (Reporting Time strictly from
                      10:00 AM to 10:30 AM)
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    09-11-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={convoForm6th}>
                      6th convocation registration form 2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    07-11-2023
                  </li>
                </ul>
              </li> */}
              {/* one section end */}

              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={CircularForTakinganOrganistionalOath}>
                      Observance of Vigilance Awareness Week 2023 in DPSRU from
                      30 Oct to 05 Nov 2023 (Circular for Organizational Oath)
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    31-10-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={circularForTakingIntegrityPledge}>
                      Circular for taking integrity pledge Individually
                    </a>{" "}
                    ||{" "}
                    <a
                      href="https://pledge.cvc.nic.in/"
                      style={{ color: "#c10000" }}
                    >
                      Click here..
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    31-10-2023
                  </li>
                </ul>
              </li> */}
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={walkinInterview3010}>
                        
                      "Walk-in- Interview for Guest Faculties on 03/11/2023 (Reporting Time strictly from 10:00 AM to 11:00 AM)
                              </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                   20-10-2023

                    </li>
                  </ul>
                </li> */}
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href='/'>
                        
                      Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on  01.10.2023                    </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                    04-10-2023

                    </li>
                  </ul>
                </li> */}
              {/* one section end */}

              {/* one section end */}
              <div className="read-more-button-container-of-home-page-tab">
                <Link to="/whatsNew" className="read-more-vc-message">
                  Read More
                </Link>
              </div>
            </ul>
          </div>
        </div>

        <div className="dpsru-bottom-tabs-each-box">
          <h2 className="dpsru-secondary-heading-for-every-page">
            STUDENT'S NOTICE BOARD
          </h2>

          <div className="inner-content-container-of-tab-home-dpsru">
            {/* First section */}
            <ul className="first-ul-of-tab-container">
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={ReOpeningofoddsemfeeportalnotice}>
                      Re-Opening of Odd Semester Fee Portal, NOTICE
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    31-01-2024
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={convoAwadiName}>
                      List of Awardee’s for 6th Convocation 2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    20-12-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={noticeforrehearsaltimingforcovo21stdecandceremony}>
                      Notice for Rehearsal timings for convocation 21st December
                      and ceremony instructions for convocation 22nd December
                      2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    19-12-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={noticeforprofssagarwaltropyforbptstudents}>
                      Notice for PROF. S.S. AGRAWAL TROPHY for BPT students
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-12-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={convoForm6th}>
                      6th convocation registration form 2023
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    07-11-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={vivasewteparoha}>
                      Ph.D Viva-Voce Notification of Ms. Shweta Paroha{" "}
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    27-09-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={evensemexamform}>
                      Even Semester Examination Form Notice, 2023{" "}
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    02-08-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={consSche4thround}>
                      4th Round Online Counselling Schedule – 2023{" "}
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    22-07-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={PhdSupplementaryFee}>
                      Pre-Ph.D Supplementary Fee Notice{" "}
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    22-07-2023
                  </li>
                </ul>
              </li> */}
              {/* one section end */}
              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={ThirdRoudCons}>
                        
                      Third round online counselling schedule-2023   </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li> */}
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={firstRoundCons}>
                        
                      Second round online counselling schedule-2023 </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li> */}
              {/* one section end */}
              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={GpatScore}>
                        
                      DPSRU GPAT Score 2023 </a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li> */}
              {/* one section end */}
              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                  <ul className="second-ul-of-tab-container">
                    
                    <li className="second-li-of-tab-container content-li-of-tabs-home">
                      <a href={firstRoundCons}>
                        
                      First round online counselling schedule-2023</a>
                    </li>
                    <li className="second-li-of-tab-container date-li-of-tabs-home">
                 -
                    </li>
                  </ul>
                </li> */}
              {/* one section end */}
              <div className="read-more-button-container-of-home-page-tab">
                <Link
                  to="/achievements_&_news_of_dpsru"
                  className="read-more-vc-message"
                >
                  Read More
                </Link>
              </div>
            </ul>
          </div>
        </div>

        <div className="dpsru-bottom-tabs-each-box">
          <h2 className="dpsru-secondary-heading-for-every-page">
            {/* ACHIEVEMENTS / NEWS OF DPSRU */}
            NEWS OF DPSRU
          </h2>

          <div className="inner-content-container-of-tab-home-dpsru">
            {/* First section */}
            <ul className="first-ul-of-tab-container">
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a>
                    The admission for "All the UG Courses during this Academic Year 2024-25, Will be held on the basis of merit at the qualifying examination and the admissions for PG courses will be held through CUET"
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    07-02-2024
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={vivaMsNeha}>
                      Ph.D Viva-Voce Notification of Ms. Neha
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-10-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={vivaMrPrateek}>
                      Ph.D Viva-Voce Notification of Mr. Prateek Sharma
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    05-10-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}
              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <Link to="/Swachhta-Hi-seva">
                      Swachhta Hi seva – Ek Tareek- Ek Ghante at DPSRU Campus on
                      01.10.2023
                    </Link>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    04-10-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <Link to="/Medical-Laboratory-Technology-week">
                      Medical Laboratory Technology week (17-23 July, 2023)
                      celebrated by DPSRU at AIIMS, New Delhi
                    </Link>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    17-07-2023
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={GpatScore}>DPSRU GPAT Score 2023</a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    -
                  </li>
                </ul>
              </li>
              {/* one section end */}
              <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={CycleRally}>
                      Celebrated G20 conferences through cycle rally
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    -
                  </li>
                </ul>
              </li>
              {/* one section end */}

              {/* one section */}
              {/* <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <a href={G20QuizCompetitionatDPSRU}>
                      G20 Quiz Competition at DPSRU
                    </a>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    26-04-2023
                  </li>
                </ul>
              </li> */}
              {/* one section end */}

              {/* <li className="first-li-of-tab-container">
                <ul className="second-ul-of-tab-container">
                  <li className="second-li-of-tab-container content-li-of-tabs-home">
                    <Link to="/iastamOration13">
                      13 IASTAM ORATION AND AWARDS FUNCTION-2023 & INTERNATIONAL
                      CONCLAVE
                    </Link>
                  </li>
                  <li className="second-li-of-tab-container date-li-of-tabs-home">
                    21-01-2023
                  </li>
                </ul>
              </li> */}
              <div className="read-more-button-container-of-home-page-tab">
                <Link
                  to="/achievements_&_news_of_dpsru"
                  className="read-more-vc-message"
                >
                  Read More
                </Link>
              </div>
            </ul>
          </div>
        </div>
      </div>

      <br />
      <br />
    </div>
  );
};

export default DpsruHomeTabsUpdated;
