import React from "react";
import "./FacultywiseAreasOfResearch.css";
import vcImg from "./Areas of Research Data/vc-sir-Mahesh-verma.png";
import pkSahoo from "./Areas of Research Data/pk-sahoo-sir-image.jpg";
import deeptiPandita from "./Areas of Research Data/Dr. Deepti Pandita.png";
import meenakshiKanwarChauhan from "./Areas of Research Data/Dr.Meenakshi Kanwar Chauhan.png";
import gauravKjain from "./Areas of Research Data/Dr. Gaurav K. Jain , Associate Professor.png";
import bhagchand from "./Areas of Research Data/bhagchand.png";
import DrPoojaMongiaRaj from "./Areas of Research Data/DrPoojaMongiaRaj.jpg";
import drDalpathiGugulothu from "./Areas of Research Data/drDalpathiGugulothu.jpeg";
import DrAnsul from "./Areas of Research Data/DrAnsul.jpg";
import DrLalitK from "./Areas of Research Data/DrLalitK.jpg";
import atulJain from "./Areas of Research Data/atulJain.jpg";
import DrBeautyBehera from "./Areas of Research Data/DrBeautyBehera.jpg";
// sec2
import DrDharamPalPathak from "./Areas of Research Data/DrDharamPalPathak.png";
import DrSharadR from "./Areas of Research Data/DrSharadR.png";
import MrsAmritaParleAssociateProfessor from "./Areas of Research Data/Mrs. Amrita Parle, Associate Professor.png";
import DrRameshBBodla from "./Areas of Research Data/Dr. Ramesh B. Bodla, Associate Professor.png";
import DrArchanaSharma from "./Areas of Research Data/DrArchanaSharma.png";
import DrSaurabhDahiya from "./Areas of Research Data/DrSaurabhDahiya.png";
import DrsahilKumar from "./Areas of Research Data/Dr. Sahil Kumar, Assistant Professor.png";
import DrRuhiAli from "./Areas of Research Data/DrRuhiAli.png";
//sec3
import DrRajniMathur from "./Areas of Research Data/DrRajniMathur.jpeg";
import DrSLatha from "./Areas of Research Data/Dr. S. Latha, Asst Professor.png";
import DrSachinK from "./Areas of Research Data/Dr. Sachin Kumar, Assistant Professor.png";
import DrAnoopKu from "./Areas of Research Data/Dr. Anoop Kumar, Assistant Professor.jpg";
//sec4
import DrSakshiBajaj from "./Areas of Research Data/DrSakshiBajaj.png";
import MrsManjuVyas from "./Areas of Research Data/Mrs. Manju Vyas, Associate Professor.png";
//sec5
import DrHarvinderPopli from "./Areas of Research Data/DrHarvinderPopli.png";
import DrGeetaAggarwal from "./Areas of Research Data/Dr. Geeta Aggarwal, Professor, Dean(Academics).png";
import DrSushamaTalegonkar from "./Areas of Research Data/Dr. Sushama Talegaonkar, Associate Professor.png";
import DrMadhuGupta from "./Areas of Research Data/Dr. Madhu Gupta, Associate Professor.png";
import DrMinakshiGarg from "./Areas of Research Data/Dr. Minakshi Garg, Associate Professor.png";
import DrAmanpreetKaur from "./Areas of Research Data/Dr Amanpreet Kaur, Assistant Professor.jpeg";
import DrSumitSharma from "./Areas of Research Data/DrSumitSharma.jpeg";
// /sec6
import DrRajivKumarTonk from "./Areas of Research Data/DrRajivKumarTonk.png";
import DrPrabodhCHanderSharma from "./Areas of Research Data/Dr. Prabodh Chander Sharma, Associate Professor.png";
import DrPritiJain from "./Areas of Research Data/Dr. Priti Jain, Assistant Professor.png";
import DrSurajPalVerma from "./Areas of Research Data/DrSurajPalVerma.png";
import DrKusumaPraveenKumar from "./Areas of Research Data/Dr. Kusuma Praveen Kumar,Assistant Professor.jpg";
import DrKalicharanSharma from "./Areas of Research Data/Dr. Kalicharan Sharma, Assistant Professor.jpeg";
import DrMukeshNandave from "./Areas of Research Data/DrMukeshNandave.png";
import DrAjitKumarThakur from "./Areas of Research Data/Dr. Ajit Kumar Thakur, Assistant Professor.png";

//sec7
import DrAjaySharma from "./Areas of Research Data/DrAjaySharma.png";
import DrMahaveerDhobi from "./Areas of Research Data/DrMahaveerDhobi.png";
import DrVijenderKumar from "./Areas of Research Data/DrVijenderKumar.png";
import DrDeveshTewari from "./Areas of Research Data/Dr. Devesh Tewari, Asst Professor.jpeg";
import DrAryaLakshmiMarisetti from "./Areas of Research Data/DrAryaLakshmiMarisetti.jpeg";

//sec8
import DrFoziyahZakir from "./Areas of Research Data/Dr. Foziyah Zakir, Assistant Professor.jpg";
import DrRoshniTandey from "./Areas of Research Data/DrRoshniTandey.jpg";
import DrAmritaMishra from "./Areas of Research Data/Dr. Amrita Mishra, Assistant Professor.jpeg";

//sec9
import DrJaseelaMajeed from "./Areas of Research Data/Dr. Jaseela Majeed, Associate Professor.jfif";
import DrPuneetaAjmera from "./Areas of Research Data/Dr. Puneeta Ajmera.png";
import DrAbhishekDadhich from "./Areas of Research Data/Dr. Abhishek Dadhich, Assistant Professor.png";
import DrJSwaminathan from "./Areas of Research Data/DrJSwaminathan.png";
import DrSheetalYadavPT from "./Areas of Research Data/Dr. Sheetal Yadav (P.T.) , Assistant Professor.png";
import DrSimmiVashishtha from "./Areas of Research Data/Dr. Simmi Vashishtha,  Assistant Professor.png";
import DrRashiGupta from "./Areas of Research Data/Dr. Rashi Gupta, Assistant Professor.jpg";
import DrSatishManchanda from "./Areas of Research Data/DrSatishManchanda.png";
import DrAkashMidha from "./Areas of Research Data/Dr. Akash Midha, Assistant Professor.jpg";
import DrREinsteinCharles from "./Areas of Research Data/Dr. R. Einstein Charles, Assistant Professor.jpg";

//sec10
import DrRichaH from "./Areas of Research Data/Dr. Richa H. Rai, Professor.png";
import DrShilpaJainPT from "./Areas of Research Data/Dr. Shilpa Jain (P.T.), Associate Professor.png";
import DrSheetalKalraPT from "./Areas of Research Data/Dr. Sheetal Kalra (P.T.)Associate Professor.jpeg";
import DrSapnaDhimanPT from "./Areas of Research Data/Dr. Sapna Dhiman (P.T.), Assistant Professor.png";
import DrVarshaChorsiya from "./Areas of Research Data/Dr. Varsha Chorsiya, Asst Professor.jpeg";
import DrParulSharma from "./Areas of Research Data/Dr. Parul Sharma (P.T.), Asst Professor.png";
import DrRavinderKumar from "./Areas of Research Data/Dr. Ravinder kumar , Asst Professor.jpeg";
import DrSavitaTamaria from "./Areas of Research Data/Dr. Savita Tamaria, Assistant Professor.jpeg";
import DrNehaKumariAP from "./Areas of Research Data/Dr. Neha Kumari, Assistant Professor.jpeg";
import DrTavassumSahar from "./Areas of Research Data/Dr. Tabassum Saher, Assistant Professor.jpeg";




function FacultywiseAreasOfResearch() {
  return (
    <div className="area-of-research-component">
      <h2 className="area-of-reserch-heading aboutUs-heading">
        Research Interests of Faculty
      </h2>
      <h2 className="area-of-reserch-sub-heading-of-comp">
        Delhi Pharmaceutical Sciences and Research University
      </h2>

      <div className="vc-container-in-area-of-research">
        <div className="vc-conrainer-in-area-of-research-image-box">
          <img src={vcImg} alt="vc" />
        </div>

        <div className="vc-container-in-area-of-research-name-and-detail-box">
          <h4 className="vc-name-in-area-of-reserch-component-inner-heading">
          Prof. (Dr.) Mahesh Verma
Vice Chancellor
          </h4>

          <p className="vc-container-in-area-of-research-name-and-detail-box-paragrauph">
          Padma Shri Awardee <br />
National Science and Technology Awardee <br />
DR. B. C. Roy Awardee <br />
BDS, MDS, MBA, FAMS, FDSRCS (England), FDSRCPSG (Glasgow), FDSRCS
          </p>

          {/* <div className="links-of-vc-container-area-of-research">
            <span>
              Google Scholor Link :-{" "}
              <a href="https://scholar.google.com/citations?user=3sdz8yEAAAAJ&hl=en">
                Click here
              </a>
            </span>
            <span>
              Orcid :-{" "}
              <a href="https://orcid.org/0000-0001-8179-6423">Click here</a>
            </span>
          </div> */}
        </div>
      </div>

      <h2 className="area-of-reserch-sub-heading-of-container">
        Department of Pharmaceutics/ Hospital Pharmacy
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={pkSahoo} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr.Prabhat K. Sahoo, Professor and Director
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Development of formulations (niosome, intranasal bypass DDS,
              niosomalantiacne gel, emulgel, transungual drug delivery). Gold
              nanoparticles based supramolecular architecture for non-Hodgkin
              lymphoma.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=VU3S23AAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={deeptiPandita} alt="" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Deepti Pandita, Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Nanomedicine&Nanotheranostics, Bioconjugates, Stem Cell Research
              for Tissue Engineering, Drug Discovery & Repurposing, 3D Printing
              for Personalized & Precision Medicine, Targeted Phytochemicals
              Delivery
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=RbEPdKsAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 3 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={meenakshiKanwarChauhan} alt="" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr.Meenakshi Kanwar Chauhan, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Vesicular/particulate drug delivery- for improved
              bio-pharmaceutical performance through topical and oral routes.
              Ophthalmic drug delivery system. Formulation approaches to improve
              ocular bioavailability of drugs. Protein/peptide drug delivery
              technology.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=z6WcyOwAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={gauravKjain} alt="" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Gaurav K. Jain , Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Industrial pharmacy and product development; Translational
              research; Oral, dermal, ocular drug delivery using Nanotechnology;
              Targeted drug delivery, Technology transfer, Gamma scintigraphy
              and Clinical studies.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=e616LMcAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0003-1719-238X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 5 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={bhagchand} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Mr. Bhag Chand
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Pharmaceutical dosage forms and delivery systems.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :- <a href="/*">Click here</a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 6  
Dr Pooja Mongia Raj, Assistant Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrPoojaMongiaRaj} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr Pooja Mongia Raj, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Nanotechnology based dual delivery systems for targeting ,
              Molecular and Cellular Imaging of targeted biomolecules,
              Pharmacokinetics and pharmacodynamic studies.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=HsZ9nE4AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0002-0069-7421">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 7 

Dr. Dalapathi Gugulothu , Assistant Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={drDalpathiGugulothu} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Dalapathi Gugulothu , Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Si RNA Delivery,, Targeted drug delivery systems, Nanofibers,
              Exosomes, Nanotoxicology, Intracellular Trafficking of
              nanoparticles, Microneedles, Development of Lyophilzation Cycle,
              Gastroretentive drug delivery systems, Orodispersible drug
              delivery systems and HPLC method development.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=mSWy-XEAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0001-5615-201X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 8  
Dr. Anshul,  Assistant Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAnsul} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Anshul, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Nanomedicine&nanotheranostics, metallic nanoparticles, their
              optimization, characterization and surface conjugation, targeted
              drug delivery, gamma scintigraphy, cosmecuetics.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=TkL9SwEAAAAJ&hl=">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0001-9476-2329">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 9  
Dr. Lalit Kumar, Assistant Professor

*/}
        {/* <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrLalitK} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Lalit Kumar, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Nanoparticulate Drug Delivery Systems, Targeted Drug Delivery
              Systems, Transdermal Drug Delivery Systems, Topical Drug Delivery
              Systems, Oral Drug Delivery Systems, Quality by Design, Design of
              Experiments, Analytical and Bioanalytical method development and
              validations.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=NMvdgtYAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0002-2418-9712">Click here</a>
              </span>
            </div>
          </div>
        </div> */}
        {/* each box */}
        {/* each box 10  

Dr. Atul Jain, Assistant Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={atulJain} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Atul Jain, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Quality by design (Qbd)-driven development of nanostructured drug
              delivery systems, Lipomer, Conjugation, Receptor mediated drug
              delivery systems, Bioavailability enhancement
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?hl=en&user=cei1sVoAAAAJ">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0003-2511-3856">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 11  
Dr.Beauty Behera, Assistant Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrBeautyBehera} alt="pic" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr.Beauty Behera, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Biomaterials and tissue engineering for regenerative medicine,
              Biopolymers for drugs, immunotherapy, and probiotics delivery,
              Peptide-based delivery systems.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=yLyLQYoAAAAJ&hl=en&oi=ao">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0003-3756-0741">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
      </div>

      {/* section 2 */}

      <h2 className="area-of-reserch-sub-heading-of-container">
        Department of Pharmaceutical Chemistry/Quality Assurance
      </h2>
      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1 */}
        {/* <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrDharamPalPathak} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr.Dharam Pal Pathak, Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Drug design, synthesis and pharmacological evaluation of fatty
              acid derivatives, oxadiazole, and benzthiazole derivatives.
              Chemical, biological, radiological and nuclear decontamination and
              mitigation in case of emergencies.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=yf0AU5oAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div> */}
        {/* each box */}
        {/* each box 2 
Dr. Sharad R. Wakode, Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSharadR} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Sharad R. Wakode, Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Synthesis and development of new medicinal heterocyclic scaffold
              and investigation of biological activities such as antiviral,
              anti-inflammatory, anti-diabetic, anti-Alzheimer. Analytical
              method development, stability studies and validation of newer
              finished products and formulations.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=QjlXvNAAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0003-1408-1019">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 3 
Mrs. Amrita Parle, Associate Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={MrsAmritaParleAssociateProfessor} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Mrs. Amrita Parle, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Planning, designing, synthesizing, characterizing and screening of
              novel compounds. Development and validation of analytical methods
              like Reverse HPLC, HPTLC, UV, and LC/MS-MS for Drug molecules.
              Pharmacovigilance.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :- <a href="/*">Click here</a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 
Dr. Ramesh B. Bodla, Associate Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRameshBBodla} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Ramesh B. Bodla, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              HPLC Method development and validation of Drug and their
              combinations in formulations and APIs. Quality assessment and
              Quality control of drugs and Phytochemicals. Plant tissue culture
              studies of static and cell suspension cultures using Elicitors and
              Bio precursors for the Bio Production of secondary metabolites.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=vRHKJzIAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 5 
Dr. Ramesh B. Bodla, Associate Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrArchanaSharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Archana Sharma, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Design, synthesis and biological evaluation of thiazolidi-4-one
              based small heterocyclic agents as anticancer agents.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=QIwX-_sAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 6 
Dr. Saurabh Dahiya, Associate Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSaurabhDahiya} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Saurabh Dahiya, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Analytical assays, validation, synthesis and development of target
              specific dosage forms including radiopharmaceuticals, analytical
              profiling of pharmaceutical formulations, and clinical research on
              life-style diseases.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=5jZfmesAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0001-8291-1796">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 7 
Dr. Sahil Kumar, Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrsahilKumar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Sahil Kumar, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Design, docking, synthesis, characterization and biological
              evaluation of novel medicinal molecules using heterocyclic
              scaffolds and polymeric prodrug design and synthesis for targeted
              drug delivery.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Scopus author id :-{" "}
                <a href="https://www.scopus.com/authid/detail.uri?authorId=56907994700">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=o02NgfkAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Orcid :-{" "}
                <a href=" https://orcid.org/0000-0002-4868-0741">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 8

Dr. Ruhi Ali, Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRuhiAli} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Ruhi Ali, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Design, synthesis and evaluation of small molecule drugs for the
              treatment of neurological disorder.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href=" https://scholar.google.co.in/citations?user=GZbX55kAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0003-4889-3453">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
      </div>

      {/* section -3 */}
      <h2 className="area-of-reserch-sub-heading-of-container">
        Department of Pharmacology
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRajniMathur} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Rajani Mathur , Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Metabolic disorders and its complications. Pharmacological
              Screening of potential drugs for cardio protective effects, ocular
              therapeutics, molecular mechanisms of metabolic disorders.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=M27bumoAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2 DrSLatha */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSLatha} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. S. Latha, Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Pharmacological Screening of Anti-angiogenic herbal drugs and
              their isolated compound. Neuroprotective effect of herbal extract
              and isolated compound.
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=M27bumoAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}
        {/* each box 3 Dr. Sachin Kumar, Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSachinK} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Sachin Kumar, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Cancer metabolism; targeted delivery of phytochemicals to cancer
              with nanocarriers and toxicological studies.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=0c11soP9a90C&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 Dr. Anoop Kumar, Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAnoopKu} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              Dr. Anoop Kumar, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
              Research interests are drug repurposing using computational,
              In-vitro and In-vivo techniques, Meta-analysis, Network
              Pharmacology, Signal analysis in Pharmacovigilance and
              Pharmacoeconomic studies.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Personal Website :-{" "}
                <a href="https://sites.google.com/view/anoopkumardpsru/home">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=qVe7hXwAAAAJ&hl=tl&oi=sra">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Orcid :-{" "}
                <a href=" https://orcid.org/0000-0002-7806-9986">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
      </div>

      <h2 className="area-of-reserch-sub-heading-of-container">
        Department of Pharmacognosy and Phytochemistry
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Sakshi Bajaj, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSakshiBajaj} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">  
Dr. Sakshi Bajaj, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Standardization, Isolation, Characterization, HPTLC analysis and evaluation of antioxidant, anti-inflammatory, anthelmintic, antileishmanial and cytotoxicity of natural products. 
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=0rr8MWgAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-9742-2765">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2  
Mrs. Manju Vyas, Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={MrsManjuVyas} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">  
            Mrs. Manju Vyas, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph" style={{textAlign:'center'}}>
            Evaluation of Different biological activities of medicinal plants.
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=0rr8MWgAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-9742-2765">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}
      </div>
      <h2 className="area-of-reserch-sub-heading-of-comp">
      School of Pharmaceutical Sciences
      </h2>
      <h2 className="area-of-reserch-sub-heading-of-container">
      Department of Pharmaceutics/Industrial Pharmacy/Cosmeceutics/Drug Regulatory Affairs
      </h2>
{/* fifth container */}
      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrHarvinderPopli} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
              
Dr. Harvinder Popli, Professor & Director
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Application of Nanotechnology and Phytopharmaceuticals for development of differentiated drug Delivery Systems for Systemic delivery, Cosmetology. Regulatory policies for impurity profiling, Medical Devices, IVD and Biologicals. Health economics and role / applications of AI in Healthcare.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href=" https://scholar.google.com/citations?user=risDGy0AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2 Dr. Geeta Aggarwal, Professor, Dean(Academics) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrGeetaAggarwal} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Geeta Aggarwal, Professor, Dean(Academics)
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Nanocarriers for drug targeting, Transdermal and Topical drug delivery by using colloidal carriers, Exploitation of natural polymers and lipids for enhancement of bioavailability of drugs, Validation for temperature-controlled packaging materials, Expertise sharing with industry for Formulation and Development.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=i6vU5yQAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="/*">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 3 Dr. Sushama Talegaonkar, Associate Professor ) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSushamaTalegonkar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Sushama Talegaonkar, Associate Professor 
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Development of wide variety of smart and intelligent nano drug delivery systems (Bioinspired nanoparticles, Nanovesicular systems, Self-emulsifying drug delivery systems, etc.) for biomedical applications, targeted drug delivery and   Bioavailability enhancement.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href=" https://scholar.google.co.in/citations?user=e-YpDP0AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-2211-5119">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 Dr. Madhu Gupta, Associate Professor ) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrMadhuGupta} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Madhu Gupta, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Pharmaceutical Nanotechnology and targeted drug delivery for magical molecule delivery, ligands for targeting of bioactives, polymers. Nanoformulations development in the area of cancer, wound healing, fungal infection psoriasis, and many more.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?hl=ja&user=IijjlfIAAAAJ&view_op=list_works&sortby=pubdate">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-3877-9956">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}

        {/* each box 5 Dr. Minakshi Garg, Associate Professor ) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrMinakshiGarg} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Minakshi Garg, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Formulation development along with their global regulatory filing. Development and characterization of stabilized liposomal drug delivery systems and solubility enhancement of poorly aqueous soluble drugs.
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?hl=ja&user=IijjlfIAAAAJ&view_op=list_works&sortby=pubdate">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-3877-9956">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}

        {/* each box 6 Dr Amanpreet Kaur, Assistant Professor) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAmanpreetKaur} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr Amanpreet Kaur, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Novel Drug delivery systems for topical and transdermal systems based on nanocarrier approaches viz., Liposomes, Ethosomes, Transfersomes, Solid lipid nanoparticles and NLCs, oral films based on mucoadhesive drug delivery systems.
            </p>

        <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=4gWTvvkAAAAJ">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0001-6561-7544">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}

        {/* each box 7 
Dr Sumit Sharma, Assistant Professor
) */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSumitSharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">  
Dr Sumit Sharma, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Formulation and development of  nano drug delivery
systems including polymeric nanoparticles and nano emulsions, targeted delivery systems, multi unit particulate systems, stimuli responsive, gelling systems, suspensions and tablets. Experienced in Film coatings techniques,(Spray and Wurster) and Pharmacodynamic and Pharmacokinetic studies.
            </p>

        <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=sHPHwvoAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0001-7898-4677">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}

    </div>

    <h2 className="area-of-reserch-sub-heading-of-container">
    Department of Pharmaceutical Chemistry/Pharmaceutical Analysis
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1 */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRajivKumarTonk} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Rajiv Kumar Tonk, Professor

            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Designing, synthesizing, and characterization of new chemical entities incorporating heterocyclic scaffolds as potential antimicrobial, anti-inflammatory (COX-1, COX-2 and ITK inhibitors), Anti-Alzheimer, Anticancer and Antiviral agents. Development and validation of analytical methods for drugs and drug formulations.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=uf-rC1wAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-0842-5121">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2 Dr. Prabodh Chander Sharma, Associate Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrPrabodhCHanderSharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Prabodh Chander Sharma, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Design and synthesis of novel thiazole, thiazolidin-4-one, fluoroquinolone derivatives for anti-infective and anticancer activities.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=cDGnbyQAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-1920-3855">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 3 Dr. Priti Jain, Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrPritiJain} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Priti Jain, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Use of in silico tools for drug design, synthesis of low molecular weight heterocycles, analytical method development and validation
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="/*">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/ 0000-0003-2801-436X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 
Dr. Suraj Pal Verma , Assistant Professor */}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSurajPalVerma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Suraj Pal Verma , Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Characterization and evaluation of active pharmaceutical ingredients as bulk drug substances and in complex formulations by using HPLC, LC-MS and GC-MS/MS techniques.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
              Scopus author id  :-{" "}
                <a href="https://www.scopus.com/authid/detail.uri?authorId=34772179700">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=-I2kdJ0AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span style={{ fontSize: ".8rem", marginTop: "2%" }}>
                Orcid :-{" "}
                <a href="https://orcid.org/0000-0002-4357-2995">Click here</a>
              </span>
            </div>


          </div>
        </div>
        {/* each box */}
{/* each box 5 Dr. Kusuma Praveen Kumar,Assistant Professor */}
<div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrKusumaPraveenKumar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Kusuma Praveen Kumar,Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Green solvent approaches to the development and validation of new analytical methods for drug molecules using HPLC, UV, LC/MS, and other techniques, Bio-analytical method development and metabolite profiling; Impurity profiling of drugs; Forced degradation (stress testing) and stability kinetics studies, Synthesis of computationally designed molecules of medicinal interest; Extraction, isolation, and chemical studies on bioactive metabolites from plant sources
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=pb_ilz4AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href=" https://orcid.org/0000-0002-0854-3734">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
{/* each box 6 Dr. Kalicharan Sharma, Assistant Professor */}
<div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrKalicharanSharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Kalicharan Sharma, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Synthesis and molecular modelling studies of Antitubercular agents (By targeting Mtb-DHFR and DPRE1), Anticancer Agents (By targeting EGFR and some other targets), Nonalcoholic fatty liver disease (By targeting ASK1), SarsCov2 inhibitor (By targeting ACE2-Spike interface, Polymerase, Helicase, and entry inhibitors), PROTACS for Treatment of Cancer.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="/*">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href=" https://orcid.org/0000-0003-0333-2521">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}

    </div>
{/* new section */}
    <h2 className="area-of-reserch-sub-heading-of-container">
    Department of Pharmacology / Biotechnology
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Mukesh Nandave, Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrMukeshNandave} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Mukesh Nandave, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Investigating the role of nutraceuticals and Phytopharmaceuticals for Myocardial ischemia & reperfusion injury, Diabetes, Obesity, Pain management. Effects of Sirtuin1 (SIRT1) activation in ageing-related cardiovascular diseases, System Xc- antiporter inhibition in epileptogenesis, and Targeting AMPK, SIRT1, and cholesterol pathways for prevention of metabolic abnormalities-associated cancer.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=gX-sJAYAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6535-6505">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2  
Dr. Ajit Kumar Thakur, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAjitKumarThakur} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Ajit Kumar Thakur, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Neuropharmacology & psychopharmacology with specific reference to investigation of neurotransmitters and biomarkers in CNS co-morbidity associated with metabolicdisorders & stress. Ultimate goal of research efforts is to discover therapeutic hits and leads suitable for drug development purpose, or for better standardization of therapeutically used herbal extracts/isolated phytoconstituent(s).
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=IBIRTyMAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/ 0000-0002-1187-602X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}

    </div>

    {/* new section 2 */}
    <h2 className="area-of-reserch-sub-heading-of-container">
    Department of Pharmacognosy and Phytochemistry
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Ajay Sharma*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAjaySharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Ajay Sharma
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Drug discovery and development from natural sources, Ethnopharmacology, Natural product chemistry, Metabolomics, Plant Biotechnology, and Bioactivity guided fractionation and Bioconversions.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="/*">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3623-4445">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 2  
DrMahaveerDhobi*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrMahaveerDhobi} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Mahaveer Dhobi
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Bioactivity guided isolation and structure elucidation of pure compounds from medicinal plants; Method Development, Validation Standardization and quality control for estimation of analytical markers in plants; Screening of plant extracts and their isolates for Central Nervous System related disorders.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=J2uCIlYAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-9148-5714">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 3  

Dr. Vijender Kumar,  Asst Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrVijenderKumar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Vijender Kumar,  Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Polyherbal Formulation Development and Characterization as per API standards. Standardization and monograph developments of Indian Medicinal Plants. Pharmacological evaluation of traditional herbs for CNS and Metabolic disorders. Explore the traditional uses of Indian Medicinal Herbs and Plants in Modern era.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="/*">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href=" www.orcid.org/my-orcid?orcid=0000-0003-0970-6571">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 4 
DrDeveshTewari
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrDeveshTewari} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Devesh Tewari, Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Natural products drug discovery with focus on exploration of medicinal plants of the Himalayan region through ethnopharmacology and reverse pharmacology approach


            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=2hnm_hAAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-5125-3791">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
        {/* each box 5 

Dr .Arya Lakshmi Marisetti, Asst Professor
*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAryaLakshmiMarisetti} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">  
Dr .Arya Lakshmi Marisetti, Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Extraction, Isolation & characterisation of phytochemicals, chromatography, pharmacognostic and phytochemical analysis, biological activities, Natural product pharmacology, Ethanopharmacology, Traditional medicine, Herbal medicine and Herbal drug development.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=Wbj08HwAAAAJ&hl=en:">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3803-3399">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}
    </div>
 {/* new section 7 */}
 <h2 className="area-of-reserch-sub-heading-of-container">
 Department of B. Pharm (Ayurveda)
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Foziyah Zakir, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrFoziyahZakir} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Foziyah Zakir, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Development of nano-appended drug delivery systems for the treatment of osteoporosis. Enhancement of solubility, bioavailability, and therapeutic efficacy of biological active molecules through amalgamation of pharmaceutics with ayurvedic drugs.  
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=YNn1-7gAAAAJ&hl=en ">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-5315-1190">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    
        {/* each box 2  

Dr. Roshni Tandey, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRoshniTandey} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Roshni Tandey, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Environmental botany, oxidative stress, polyaromatic hydrocarbons, nutraceuticals, green extraction methods, isolation and purification of natural products, phenolics, flavonoids, microwave assisted extraction.  
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=sBQ f6QAAAAJ&hl=en&authuser=1">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-1873-9634">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    
        {/* each box 3  
Dr. Amrita Mishra, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAmritaMishra} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Amrita Mishra, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Standardization of ayurvedic and herbal drugs and formulations, Traditional system of medicine, quality control aspects of ayurvedic and herbal drugs and formulations.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=IyEhOdcAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-4729-909X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    
       

    </div>
      <h2 className="area-of-reserch-sub-heading-of-comp">
      School of Allied Health Science and Management
      </h2>
      {/* new section 8 */}
 <h2 className="area-of-reserch-sub-heading-of-container">
 Department of Pharmaceutical Management/Hospital Management/Public Health
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Jaseela Majeed, Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrJaseelaMajeed} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Jaseela Majeed, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Healthcare market, Commercial Drug Development, Quality Systems and Compliance, Health care Management and Public Health Management, Human resource management, Analytical Method Development and Validation of Newer Drugs and formulations, Stability studies including accelerated stability testing of finished products.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=_ZtmDOcAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0001-7585-2724">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    
        {/* each box 2  

Dr. Puneeta Ajmera, Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrPuneetaAjmera} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
Dr. Puneeta Ajmera, Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Maternal and Child health, Globalization of healthcare, Industry 4.0 in healthcare, lean management in healthcare, Decision making in healthcare using MADM & Structural modelling techniques like ISM, TISM.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=L4zbp3kAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6237-2235">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}   
         
        {/* each box 3  
DrAbhishekDadhich*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAbhishekDadhich} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Abhishek Dadhich, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Consumer Behaviour towards Over-the-Counter Medicines, Physician Prescription Behaviour, Rational Use of Medicines, Pharmaceutical Supply Chain Management, Pharmaceutical Services in Hospital.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=l90vg5UAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3810-4281">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box   4
Dr. J. Swaminathan, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrJSwaminathan} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">   
Dr. J. Swaminathan, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Rural Health, Urban Health Development, e-Healthcare Systems. Analytical Method Development and Validation for Newer Drugs, Application of Quality by Design for formulation development of drugs, Hepatoprotective, Anti-ulcer, Analgesics, and anti-inflammatory activity in herbal drugs. 
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.ca/citations?user=F6qpOBQAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              {/* <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3810-4281">Click here</a>
              </span> */}
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  5 
Dr. Sheetal Yadav (P.T.) , Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSheetalYadavPT} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">   
            Dr. Sheetal Yadav (P.T.) , Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Telemedicine/e-Health, Quality in hospitals and healthcare i.e., Accreditation, Six-sigma and lean management, Postnatal depression.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=jIAoE78AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              {/* <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3810-4281">Click here</a>
              </span> */}
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  6 
Dr. Sheetal Yadav (P.T.) , Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSimmiVashishtha} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">   
            Dr. Simmi Vashishtha,  Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Entrepreneurship Ecosystem, Entrepreneurial Intention, Entrepreneurship and economic growth, Entrepreneurs as catalytic agents, sustainable development, Energy sector sustainability, employee productivity, Bibliometric analysis.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=dkHSdkYAAAAJ">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-9259-815">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  7 
Dr. Rashi Gupta, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRashiGupta} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">   
            Dr. Rashi Gupta, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Understand the evolution and rise in antimicrobial resistant pathogens in human and environmental microbiome using advanced bacterial genomics and metagenomics.
            </p>
         
            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?hl=en&user=miK6VOsAAAAJ&view_op=list_works&sortby=pubdate">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-9187-7451">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  8
Dr. Rashi Gupta, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSatishManchanda} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">            
Dr. Satish Manchanda, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Nanoformulation, Ocular drug delivery, Transdermal drug delivery, HPLC method development & validation
            </p>
         
            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=4MjwM84AAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0001-9566-4894">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  9
Dr. Akash Midha, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrAkashMidha} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">            
            Dr. Akash Midha, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Sustainable Development Goals in Respect to Healthcare Sector, Social and Behavior Change Analysis in Relation to Community Health, Artificial Intelligence in Pharmaceutical Industry.
            </p>
         
            <div className="links-of-each-container-area-of-research">
              {/* <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=4MjwM84AAAAJ&hl=en">
                  Click here
                </a>
              </span> */}
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-1463-6080">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    

        {/* each box  10
Dr. Akash Midha, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrREinsteinCharles} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">            
            Dr. R. Einstein Charles, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Proteomics, Genomics, Enzymatic Synthesis, Spectral Characterization of Chemical Structure (FTIR, NMR (1H, C13 & 2DHSQCT), Nano-particle synthesis and characterization, Plant Tissue Culture.
            </p>
         
            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=qdjOpOsAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0001-8696-7105">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}    
    
    </div>

    <h2 className="area-of-reserch-sub-heading-of-container">
School of Physiotherapy
      </h2>

      <div className="content-container-of-department-of-pharmaceutics-area-of-reserch">
        {/* each box 1  
Dr. Richa H. Rai, Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRichaH} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Richa H. Rai, Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Cardiac and Pulmonary Functional Assessment and Rehabilitation, ICU Care, Metabolic Syndrome, Yoga and Fitness, Community Health Care.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=2URe8tAAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-0102-2773">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 2  
Dr. Shilpa Jain (P.T.), Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrShilpaJainPT} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Shilpa Jain (P.T.), Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Biomechanics, pathomechanics, work place ergonomics, Yoga and Physiotherapy integration, worked on Myopia and Hyperopia by using MFR technique. Prevention of injury and enhancing performance of the athletes and handling microcurrent for aesthetic purpose. Inflammation of skeletal muscle at molecular level.
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=2URe8tAAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-0102-2773">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}  

        {/* each box 3  
Dr. Sheetal Kalra (P.T.)Associate Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSheetalKalraPT} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Sheetal Kalra (P.T.)Associate Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Working on fitness of school children, work related musculoskeletal disorders, women health, intervention related to cervical and low back pain, training techniques for enhancing performance in various sports.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.co.in/citations?user=SGOZYYgAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0003-3317-4624">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 4  
Dr. Sapna Dhiman (P.T.), Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSapnaDhimanPT} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Sapna Dhiman (P.T.), Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Motor Control and Motor Learning for Paediatric group (Cerebral Palsy, Autism Spectrum Disorders, Developmental disorders etc.) with specific reference to Posture, Balance, Trunk control and activities of daily living. Evaluate abnormal blocks to development of the children with special needs and emphasize on normal development. Evaluate caregiver burden of children with special needs and design strategies for the same.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=ZBFmW1IAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6581-131X?lang=en">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 5  
Dr. Varsha Chorsiya, Asst Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrVarshaChorsiya} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Varsha Chorsiya, Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Biomechanics, Human Factors and Industrial Ergonomics, Environmental Health, Women Health, Occupational Health & amp; Safety, Physiotherapeutic & Rehabilitation and Geriatrics.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=wMElM4AAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6393-1422">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 6  
Dr. Parul Sharma (P.T.), Asst Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrParulSharma} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Parul Sharma (P.T.), Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph">
            Assessment & Rehabilitation of Neurological Disorders. Comparative evaluation of a new iontophoretic drug for the treatment of Knee Osteoarthritis.
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=wMElM4AAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6393-1422">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}  

        {/* each box 7  
Dr. Ravinder kumar , Asst Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrRavinderKumar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Ravinder kumar , Asst Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph" style={{textAlign:'center'}}>
            Physiotherapy, chiropractic and osteopath
            </p>

            {/* <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=wMElM4AAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6393-1422">Click here</a>
              </span>
            </div> */}
          </div>
        </div>
        {/* each box */}  

        {/* each box 8  
Dr. Savita Tamaria, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrSavitaTamaria} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Savita Tamaria, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph" style={{textAlign:'center'}}>
            Research interest: Effect of patellar taping in patellofemoral OA ,Recent advances in physiotherapy,Coccydynia and tibiofemoral OA
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=VRpK0qEAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-4732-3822">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 9 
Dr. Neha Kumari, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrNehaKumariAP} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Neha Kumari, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph" style={{textAlign:'center'}}>
            Research interest: Therapeutic effects of electrotherapeutic modalities on tightness of muscles and physical performance of individuals
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?user=MVIY5toAAAAJ&hl=en">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-6745-2648">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

        {/* each box 10 
Dr. Neha Kumari, Assistant Professor*/}
        <div className="each-data-container-of-department-of-pharmaceutics-area-of-reserch">
          <div className="each-conrainer-in-area-of-research-image-box">
            <img src={DrTavassumSahar} alt="img" />
          </div>
          <div className="each-container-in-area-of-research-name-and-detail-box">
            <h4 className="each-name-in-area-of-reserch-component-inner-heading">
            Dr. Tabassum Saher, Assistant Professor
            </h4>

            <p className="each-container-in-area-of-research-name-and-detail-box-paragrauph" style={{textAlign:'center'}}>
            Research interest: Effect of pulmonary rehabilitation on respiratory muscles, health related quality of life and respiratory failure.
            </p>

            <div className="links-of-each-container-area-of-research">
              <span>
                Google Scholor Link :-{" "}
                <a href="https://scholar.google.com/citations?hl=en&user=oAtrDkoAAAAJ">
                  Click here
                </a>
              </span>
              <span>
                Orcid :- <a href="https://orcid.org/0000-0002-3020-275X">Click here</a>
              </span>
            </div>
          </div>
        </div>
        {/* each box */}  

    </div>
    </div>
  );
}

export default FacultywiseAreasOfResearch;
