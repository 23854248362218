import React from 'react'
import { Link } from 'react-router-dom'

import AboutOurHonourableChancellor from '../../Home/Vision, Mission, Objectives & Quality Policy/VisionMissionData/AboutOurHonourableChancellor.pdf'
import AboutOurHonourableViceChancellor from '../../Home/Vision, Mission, Objectives & Quality Policy/VisionMissionData/AboutOurHonourableViceChancellor.pdf'
import StrategyPage from '../../Home/Vision, Mission, Objectives & Quality Policy/VisionMissionData/StrategyPage.jpg'
import academicsCalender from "../../Academics/academics-calendar.pdf";


const SideLinkUniversity = () => {
  return (
    <div className="dpsru-every-component-css" style={{margin:'09% auto', width:'100%'}}>
      <h2 className="dpsru-secondary-heading-for-every-page">University</h2>
      <div className="university-side-important-link inner-container">


<ul style={{listStyle:'none'}}>
    <li>
        <Link to="/aboutUs"> About</Link>
    </li>
    <li>
        <Link to="/visionMissionObjectives&QualityPolicy"> Vision, Mission, Objectives and Quality Policy</Link>
    </li>
    <li>
        <Link to="/VisionforViksitBharat@2047">Vision for Viksit Bharat @2047</Link>
    </li>
    <li>
        <Link to="/chancellorDpsru"> Message of the Honourable Chancellor</Link>
    </li>
    <li>
        {/* <Link to="/viceChancellor"> Message of the Vice Chancellor</Link> */}
    </li>
    <li>
        <a href={AboutOurHonourableChancellor}> About Our Honourable Chancellor</a>
    </li>
    <li>
        {/* <a href={AboutOurHonourableViceChancellor}> About Our Honourable Vice Chancellor</a> */}
    </li>
    <li>
        <Link to="/AuthoritiesoftheUniversity"> Authorities of the University</Link>
    </li>
    <li>
        <Link to="/governingActs"> Governing Acts</Link>
    </li>
    <li>
        <a href={StrategyPage}> Strategic Plan of DPSRU</a>
    </li>
    <li>
        <a href={academicsCalender}> List of Holidays</a>
    </li>
    {/* <li>
        <Link to="/"> How to reach DPSRU</Link>
    </li> */}
</ul>

      </div>
      
      
      </div>
  )
}

export default SideLinkUniversity