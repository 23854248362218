import React from 'react'
import './TakeATour.css'
import './ResponsiveTakeATour.css'
import Iframe from 'react-iframe'
import Carousel from 'nuka-carousel';

import pic1 from './Home Gallery/pic1.jpg'
import pic2 from './Home Gallery/pic2.jpg'
import pic3 from './Home Gallery/pic3.jpg'
import pic4 from './Home Gallery/pic4.jpg'
import pic5 from './Home Gallery/pic5.jpg'
import pic6 from './Home Gallery/pic6.jpg'
import pic7 from './Home Gallery/pic7.jpg'
import pic8 from './Home Gallery/pic8.jpeg'
import pic9 from './Home Gallery/pic9.jpeg'
import pic10 from './Home Gallery/pic10.jpeg'
import pic11 from './Home Gallery/pic11.jpeg'
import pic12 from './Home Gallery/pic12.jpg'

function TakeATour() {
    return (
        <div className='takeatour-component'>


            <div className="takeatour-container">

                <div className="video-container">
                    <h2 className="tat-heading-text">
                       DPSRU Kulgeet
                    </h2>
                    <Iframe url="https://www.youtube.com/embed/12hwSQFTk1w"
                        width="100%"
                        height="100%"
                        id=""
                        className=""
                        display="block" />
                </div>

                <div className="photos-highlight-container">
                    <h2 className="tat-heading-text">
                        Photos Highlights
                    </h2>
                    <Carousel autoplay='true' autoplayReverse='true' animation='zoom' pauseOnHover='true' wrapAround={true} withoutControls={true} autoplayInterval={5000} >

                        <img src={pic1} alt="logo" />
                        <img src={pic2} alt="logo" />
                        <img src={pic3} alt="logo" />
                        <img src={pic4} alt="logo" />
                        <img src={pic5} alt="logo" />
                        <img src={pic6} alt="logo" />
                        <img src={pic7} alt="logo" />
                        <img src={pic8} alt="logo" />
                        <img src={pic9} alt="logo" />
                        <img src={pic10} alt="logo" />
                        <img src={pic11} alt="logo" />
                        <img src={pic12} alt="logo" />
                    </Carousel>
                </div>

            </div>

        </div>
    )
}

export default TakeATour