import React, { useState } from "react";
import "./ViceChancellor.css";
import vcSirImg from "./vc-sir-rounded-thumb.png";

const ViceChancellor = () => {
  const [positions, setPositions] = useState(true);

  return (
    <div className="vice-chancellor-component-dpsru">
      <div className="vc-sir-image-and-details-container">
        <a href={vcSirImg}>
          <img
            className="vc-sir-img rounded-corners"
            src={vcSirImg}
            alt="vc-sir"
            style={{marginRight:'0'}}
          />
        </a>
        <div className="vc-sir-info-container">
          <h3>Prof. (Dr.) Mahesh Verma</h3> <br />
          <p className="vc-sir-info-text-vice-chancellor">VICE CHANCELLOR</p>
          <p className="vc-sir-info-text-vice-chancellor">
            DELHI PHARMACEUTICAL SCIENCES AND RESEARCH UNIVERSITY, NEW DELHI
          </p>{" "}
          <br />
          <p className="vc-sir-info-text-vice-chancellor-secendary-text">Padma Shri Awardee</p>
          <p className="vc-sir-info-text-vice-chancellor-secendary-text">National Science and Technology Awardee</p>
          <p className="vc-sir-info-text-vice-chancellor-secendary-text">DR. B. C. Roy Awardee</p>
          <br />
          <p className="vc-sir-info-text-vice-chancellor-third-text">
            BDS, MDS, MBA, FAMS, FDSRCS (England), FDSRCPSG (Glasgow), FDSRCS
            (Edinburgh),
          </p>
          <p className="vc-sir-info-text-vice-chancellor-third-text">Ph.D. (IPU), Ph.D. (HC)</p>
        </div>
      </div>
      <div className="vc-sir-page-buttons">
      <button className="vc-buttons" onClick={() => setPositions(true)}>POSITIONS</button>
      <button className="vc-buttons" onClick={() => setPositions(false)}>AWARDS</button>
      </div>

      <div className="positions-and-awards-container">
        {positions ? (
          <>
            <div
              className="positions-container"
              id="vc_sir_positions"
              style={{ display: "block" }}
            >

              <h3 className="positions-and-award-heading">POSITIONS</h3>
              <table className="position-table">
                <tbody>
                  <tr>
                    <td>Former</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Dean, Maulana Azad Institute of Dental Sciences,{" "}
                          <br />
                          New Delhi-110002, India
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>President</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          International Association for Dental Research (IADR)
                          India Division
                        </li>
                        <li>
                          {" "}
                          International Association for Disability and Oral
                          Health (iADH) India Chapter
                        </li>
                        <li> Indian Academy of Restorative Dentistry (IARD)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Past Vice President</td>
                    <td>
                      <ul>
                        <li>Dental Council of India</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Past President</td>
                    <td>
                      <ul>
                        <li> Indian Dental Association (National)</li>
                        <li> Dental Council of India (Acting)</li>
                        <li> Indian Prosthodontic Society</li>
                        <li> Indian Dental Association (Delhi)</li>
                        <li>
                          {" "}
                          International College of Dentists (India, Sri Lanka
                          &amp; Nepal Section)
                        </li>
                        <li> Pierre Fauchard Academy (India) Section</li>
                        <li> Delhi Dental Council</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Chairperson</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Governing Body of University College of Medical
                          Sciences, University of Delhi
                        </li>
                        <li>
                          {" "}
                          National Accreditation Board for Hospitals &amp;
                          Healthcare Providers
                        </li>
                        <li> The Indian Board of Forensic Odontology (IBFO)</li>
                        <li>
                          {" "}
                          Research Committee (Project Review Committee - PRC)
                          for Oral Health at Indian Council of Medical Research
                          (ICMR)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Honorary Advisor</td>
                    <td>
                      <ul>
                        <li>
                          Armed Forces Dental Services, Ministry of Defence
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Eminent Research Scholar/ Scientist</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Armed Forces Medical Research Committee (AFMRC), Govt.
                          of India
                        </li>
                        <li>
                          {" "}
                          Council of Scientific &amp; Industrial Research (CSIR)
                        </li>
                        <li> Department of Science &amp; Technology (DST)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Fellow</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Fellow - American College of Dentists - FACD (USA)
                        </li>
                        <li>
                          {" "}
                          Fellow - American Academy of Implant Dentistry - FAAID
                          (USA)
                        </li>
                        <li>
                          {" "}
                          Fellow - National Academy of Medical Sciences (FAMS)
                        </li>
                        <li>
                          {" "}
                          Master Fellow - International College of Dentists -
                          FICD (USA)
                        </li>
                        <li>
                          {" "}
                          Fellow - International Medical Sciences Academy
                          (FIMSA)
                        </li>
                        <li>
                          {" "}
                          Honorary Fellow - Royal College of Surgeons of England
                          -FDSRCS (England)
                        </li>
                        <li>
                          {" "}
                          Honorary Fellow - Royal College of Physicians and
                          Surgeons of Glasgow - FDSRCPSG (England)
                        </li>
                        <li>
                          {" "}
                          Fellow - Royal College of Surgeons, Faculty of General
                          Dentistry Practice -FGDPRCS (England)
                        </li>
                        <li>
                          {" "}
                          Fellow - Royal College of Surgeons of Edinburgh -
                          FDSRCSE (Edinburgh)
                        </li>
                        <li>
                          {" "}
                          Fellow - International Congress of Oral
                          Implantologists - ICOI
                        </li>
                        <li>
                          {" "}
                          Fellow - World Congress for Oral Implantology - WCOI
                          (Japan)
                        </li>
                        <li>
                          {" "}
                          Fellow - International Academy for Dental Facial
                          Esthetics - IADFE (USA)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span lang="en-in">Visiting Fellowships</span>
                    </td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          FAIMER Fellow (Foundation for Advancement of
                          International Medical Education &amp; Research) -
                          Philadelphia (USA)
                        </li>
                        <li>
                          {" "}
                          T.C. White Fellowship by Royal College of Surgeons and
                          Physicians of Glasgow (UK)
                        </li>
                        <li>
                          {" "}
                          Visiting Fellow - University of Medicine &amp;
                          Dentistry of New Jersey (USA)
                        </li>
                        <li>
                          {" "}
                          World Health Organisation (WHO) Fellow - State
                          University of New York (SUNY) (Buffalo, U.S.A.)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Clinical Director, AAID Maxicourse India</td>
                    <td>
                      <ul>
                        <li>American Academy of Implant Dentistry (AAID)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Executive Editor
                      <span lang="en-in">(Past)</span>
                    </td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Journal of International College of Dentists (India,
                          Sri Lanka &amp; Nepal Section-VI) USA
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Editor</td>
                    <td>
                      <ul>
                        <li> Dentistry India (Springer)</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Editorial Board</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Journal of American Dental Association (Indian
                          Edition)
                        </li>
                        <li>
                          {" "}
                          Journal of British Dental Association (Indian Edition)
                        </li>
                        <li> Journal of Indian Dental Association</li>
                        <li> Journal of Indian Prosthodontic Society</li>
                        <li>
                          {" "}
                          Journal of Oral Biology and Clinical Dentistry (India
                          Section)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Research</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Principal Investigator, Project by Council of
                          Scientific and Industrial Research, Ministry of
                          Science and Technology, Government of India for
                          Indigenous Dental Implants - Phase I completed, Phase
                          II Started
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Past Secretary</td>
                    <td>
                      <ul>
                        <li> Indian Prosthodontic Society</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Part Time Faculty</td>
                    <td>
                      <ul>
                        <li>
                          Faculty of Management Studies, Delhi University, (MBA
                          Classes)
                        </li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Governing Board Members</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Indraprastha Apollo Hospital, New Delhi, India -
                          Director representing Govt. of Delhi
                        </li>
                        <li>
                          {" "}
                          National Accreditation Board for Hospitals (NABH),
                          Quality Council of India, New Delhi, India
                        </li>
                        <li>
                          {" "}
                          V.P. Patel Chest Institute - Delhi University, New
                          Delhi, India
                        </li>
                        <li>
                          {" "}
                          NITTE University (Deemed to be University), Mangalore,
                          Karnataka, India
                        </li>
                        <li> Indian Council of Medical Research (ICMR)</li>
                        <li> Indian Red Cross Society, Delhi</li>
                        <li> Kalantar Art Trust, Noida</li>
                      </ul>
                      &nbsp;
                    </td>
                  </tr>
                  <tr>
                    <td>Member (International)</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Science Committee, FDI World Dental Federation, Geneva
                        </li>
                        <li>
                          {" "}
                          Working Group on Artificial Intelligence in Dentistry,
                          FDI, World Dental Federation
                        </li>
                        <li>
                          {" "}
                          Global Committee, American Academy of Implant
                          Dentistry (USA)
                        </li>
                        <li>
                          {" "}
                          Antimicrobial Resistance Antibiotic Stewardship
                          (AMR-ABS), FDI World Dental Federation Geneva
                        </li>
                        <li> Global Child Dental Health Task Force (UK)</li>
                        <li>
                          {" "}
                          Global Committee, American Academy of Implant
                          Dentistry
                        </li>
                        <li>
                          {" "}
                          Think Tank Committee Member of Alliance Oral Health
                          Across Borders, USA
                        </li>
                        <li> Alliance for Cavity Free Future, USA</li>
                      </ul>
                    </td>
                  </tr>
                  <tr>
                    <td>Member (National)</td>
                    <td>
                      <ul>
                        <li>
                          {" "}
                          Dental Council of India (Representing Govt. of India)
                        </li>
                        <li>
                          {" "}
                          National Medical Commission (Medical Council of India)
                        </li>
                        <li>
                          {" "}
                          COVID Committee for Hospitals by Govt. of Delhi
                        </li>
                        <li>
                          {" "}
                          Advisory and Ethics Committee of Indian Prosthodontic
                          Society
                        </li>
                        <li>
                          {" "}
                          National Medical and Wellness Tourism Board (NMWTB),
                          Govt. of India
                        </li>
                        <li>
                          {" "}
                          Planning Board of Pandit B.D. Sharma University of
                          Health Sciences, Rohtak, Haryana, India
                        </li>
                        <li>
                          {" "}
                          Executive Member, India International Centre, Lodhi
                          Estate, New Delhi, India
                        </li>
                        <li>
                          {" "}
                          Advisory Board of Pre-incubation Centre, VSPM's Dental
                          College &amp; Research Centre, Nagpur
                        </li>
                        <li>
                          {" "}
                          Disciplinary Committee of National Academy of Medical
                          Sciences, New Delhi
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </>
        ) : <>
        
        
        <div
              className="positions-container"
              id="vc_sir_positions"
              style={{ display: "block" }}
            >

              <h3 className="positions-and-award-heading">AWARDS</h3>
              <table className="position-table">
                <tbody>
                
                 <tr>
                        <td>
                            National Awards
                        </td>
                        <td>
                        <ul>
							<li>	National Award for Outstanding Efforts in Science &amp; Technology by the President of India, February 2020 
							</li><li>	Conferred National Oral Healthcare 'Sushruta Award' by Indian Dental Association, December 2017
							</li><li>	Conferred 'Padmashri' by Hon'ble President of India 2014 
							       (4th Highest Civilian Award)
							</li><li>	'Dr. B.C. Roy National Award' by President of India 2007 
							       (Highest Medical Award presented by President of India)
							</li><li>	'Dr. B.C. Shroff Oration' - Indian Prosthodontic Society Annual Conference 2007
							</li><li>	'Dr. P.N. Behl Foundation Award' By Delhi Medical Association - 2006
							</li><li>	Best Doctor 'State Award' by Govt. of NCT of Delhi - 2001
							</li><li>	'Commonwealth Dental Association Oration 2001'
							</li></ul>

                            
                        </td>
                       

                       
                    </tr>


                 <tr>
                        <td>
                            International Awards &amp; Honours</td>
                        <td>
                        <ul>
						<li>	'Presidential Citation' by American Dental Association, October 2021
						</li><li>	Conferred 'Dentist of the Year 2017 Award' by American Academy of Implant Dentistry during 66th Annual Meeting of American Academy of Implant Dentistry at San Diego, October 2017
						</li><li>	Conferred 'Global Oral Health Progress Award' for Furthering Global Oral Health at FDI - World Dental Congress, Madrid, Spain, August 2017 by International Dental Manufacturers 
						</li><li>	Conferred 'Elmer S Best Memorial Award' for outstanding contributions to the art and science of Dentistry by Pierre Fauchard Academy, USA during FDI - Annual World Dental Congress, Madrid, Spain, August 2017
						</li><li>	'Aryabhata International Award 2015' during 3rd Healthcare Summit &amp; Awards, May 2016 at Bangalore 
						</li><li>	Conferred Honorary Membership of British Dental Association, (Lifetime) 2015
						
						</li><li>	Conferred 'Honorary American Dental Association Membership' by American Dental Association, November 2015, Washington (Lifetime)
						</li></ul></td>
                       

                       
                    </tr>


                 <tr>
                        <td>
                            Other Awards </td>
                        <td>
                       <ul>
							<li>	'Eminent Medical &amp; Health Education Teacher Award' by Delhi Medical Association, New Delhi, 5th September 2022
							</li><li>	'Global Thought Leader Award' by Indian Accounting Association, NCR Chapter, New Delhi, 5th September 2022
							</li><li>	'Trivandrum Medical College Distinguished Medical Award' by Government Medical College, Trivandrum, on 26th August 2022
							</li><li>	'Legend in Dentistry Award' by PMS College of Dental Science &amp; Research, Thiruvananthpuram, 7th March 2022
							</li><li>	'Samaj Ratna Award' by Kishore Kumar Memorial Club during      19th Kishore Kumar Awards at India Islamic Cultural Centre, New Delhi, 27th December 2021 
							</li><li>	'Dr. Hari Parkash Oration' by All Indian Institute of Medical Sciences, New Delhi, September 2021
							</li><li>	'Hall of Fame Award' by Ramanuj College, University of Delhi,                 05th  September 2021
							</li><li>	'Lifetime Achievement Award 2021' in the field of Dental Sciences/ Prosthodontics by Indraprastha Shiksha avam Khel Vikas Sangathan,     1st July 2021
							</li><li>	Certificate of Appreciation in the field of Health Services &amp; Education by Padmavibhushan Dr. A.N Jha Memorial Trust, 1st July 2021
							</li><li>	'Famdent Legend Award' by Famdent, 1st June 2021
							</li><li>	Rashtriya Icon Awards by the 'NNS Media Group' at India International Centre, New Delhi, April 2020
							</li><li>	Conferred 'Aarogya Sevavrati Puraskar' by Vaidyakeeya Madat Nidhi (Trust), June 2018, Mumbai
							</li><li>	Conferred '28th TP Jhunjhunwala National Excellence Award' by TP Jhunjhunwala Foundation, February 2018, New Delhi 
							</li><li>	'Life Time Achievement Award' by Indian Association of Positive Psychology &amp; Manav Rachna  International University, Faridabad during 1st International &amp; 3rd National Conference 'Positive Psychology : The Ripple Effect', February 2016
							</li><li>	ISDR Founder President Prof. M. Rahmatulla Award by Indian Society for Dental Research (ISDR), December 2016
							</li><li>	'Distinguished Teacher cum Administrator Award' by Delhi School of Professional Studies &amp; Research, September 2015
							</li><li>	'M.P.N. Sharma Life Time Achievement Award' by Institute for Environment, YOGA &amp; Social Security, September 2015, New Delhi  
							
							</li><li>	'Bhagirathi Award' by Bhagirathi Samajik Saanskritik Manch, November 2014, New Delhi
							</li><li>	'Life Time Achievement Award 2014' by Heal Talk Publishing House, September 2014, New Delhi 
							</li><li>	'Excellence in Dentistry Award' by Medscape India, September 2014, Mumbai
							</li><li>	'Dr. Radhakrishnan Memorial Award' by All India Freelance Journalists and Writers Association - September 2014, New Delhi 
							</li><li>	'Gomti Devi Swarankar Oration' by Mahatma Gandhi University of Medical Sciences &amp; Technology, Jaipur 2014
							</li><li>	'National Award for Healthcare' by Journalists Federation of India - 2014 
							</li><li>	'Delhi Medical Association Centenary Healthcare Leadership Award', 2014
							</li><li>	'Dental Public Health Award' by National Academy of Medical Sciences - 2013, Bhopal
							</li><li>	'Vashisht Chikitsa Ratan Award' by Delhi Medical Association - 2013
							</li><li>	'Health Care Personality of the Year' by Federation of Indian Chambers of Commerce and Industry (FICCI) 2012
							</li><li>	Commendation by Vice Chief of Army Staff 2009
							</li><li>	 'Rotary Vashishat Sewa Samman' by Rotary Club of India - 2002 
							
							</li></ul></td>
                       

                       
                    </tr>


                 
                 <tr>
                        <td>
                            Patents </td>
                        <td>
                       <ul>
							<li>	US: Dental Implant System, Verma et al.<br/>Patent Number: 9,833,300 B2; May, 2017
							</li><li>	India: Dental Implant System
							       <br/>Patent Number: 324068; October 2007
							</li></ul></td>
                       

                       
                    </tr>


                 
                 <tr>
                        <td>
                            Jury Member</td>
                        <td>
                       <ul>
						<li>	Federation of Indian Chambers of Commerce and Industry (FICCI) Healthcare Awards 
						</li><li>	Federation of Indian Chambers of Commerce and Industry (FICCI) Higher Education Awards 
						</li><li>	Heart Care Foundation of India - Awards 
						</li></ul></td>
						                       

                       
                    </tr>


                 
                </tbody>
            </table>
        </div>
        
        
        </>}
      </div>
    </div>
  );
};

export default ViceChancellor;
