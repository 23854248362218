import React, { useEffect,useState } from "react";
// import { FaBan } from "react-icons/fa";
import "./App.css";
import Home from "./Components/Home/Home";
import Nav from "./Components/Nav/Nav";
import { Route, Routes } from "react-router-dom";
import NewFooter from "./Components/Footer/NewFooter";
import Staff from "./Components/Staff/Staff";
import AnnualReport from "./Components/Annual Report/AnnualReport";
import Conferences from "./Components/Conferences/Conferences";
import Nss from "./Components/NSS/Nss";
import SideNav from "./Components/Nav/SideNav/SideNav";
import Notifications from "./Components/Notifications/Notifications";
import Tenders from "./Components/Tenders/Tenders";
import Recruitments from "./Components/Recruitments/Recruitments";
import ContactUs from "./Components/Contact us/ContactUs";
import NewsLetters from "./Components/NewsLetters/NewsLetters";
import Forms from "./Components/Forms/Forms";
import Approval from "./Components/Approval&Affilliations/Approval";
import Payment from "./Components/Payment/Payment";
import Placement from "./Components/Placement/Placement";
// import ViceChancellor from "./Components/Vice-chancellor/ViceChancellor";
import AboutUs from "./Components/About Us/AboutUs";
import Naac from "./Components/Naac/Naac";
import RD from "./Components/RD/RD";
import Academics from "./Components/Academics/Academics";
import Dipsar from "./Components/Academics/School/DIPSAR/Dipsar";
import SchoolOfPharmaceuticalSciences from "./Components/Academics/School/School Of Pharmaceutical Sciences/SchoolOfPharmaceuticalSciences";
import Sahsm from "./Components/Academics/School/SAHSM/Sahsm";
import SchoolofPhysiotherapy from "./Components/Academics/School/SchoolofPhysiotherapy/SchoolofPhysiotherapy";
import DoctoralPrograms from "./Components/Academics/School/DoctoralProgram/DoctoralPrograms";
import Wcsc from "./Components/Academics/Centres/World Class Skill Centre/Wcsc";
import Assrm from "./Components/Academics/Centres/ASSRM/Assrm";
import CentersOfExcellence from "./Components/Academics/CENTERS OF EXCELLENCE/CentersOfExcellence";
import CertificateCourses from "./Components/Academics/CERTIFICATE COURSES/CertificateCourses";
import Administration from "./Components/Administration/Administration";
import Admissions from "./Components/Admissions/Admissions";
import Students from "./Components/Students/Students";
import GenralCounsil from "./Components/Administration/GoverningBoard/GenralCounsil";
import Organogram from "./Components/Administration/Organogram/Organogram";
import Committees from "./Components/Administration/Committees/Committees";
import PreviousQuestionPapers from "./Components/Students/Previous Question Papers/PreviousQuestionPapers";
import DisciplineRagging from "./Components/Students/Discipline & Ragging/DisciplineRagging";
import Examination from "./Components/Examination/Examination";
import UCPs from "./Components/Under Construction Pages/UCPs";
import Institutions from "./Components/Examination/Institutions/Institutions";
import Rules from "./Components/Examination/Rules/Rules";
import EDownload from "./Components/Examination/Download/EDownload";
import QuestionPaper from "./Components/Examination/Download/Question Papers/QuestionPaper";
import Results from "./Components/Examination/Results/Results";
import DeveloperDetails from "./Components/DeveloperDetails/DeveloperDetails";
import PageNotFound from "./Components/Page Not Found/PageNotFound";
import ChancellorDpsru from "./Components/Vice-chancellor/Chancellor/ChancellorDpsru";
import IAQCIntroduction from "./Components/IAQC/IAQC Introduction/IAQCIntroduction";
import YoutubeLink from "./Components/Footer/YouTube Links/YoutubeLink";
import IaqcMoM from "./Components/IAQC/IaqcMoM/IaqcMoM";
import QualityInitiativeIAQC from "./Components/IAQC/QUALITY INITIATIVES/QualityInitiativeIAQC";
import NationalRankingIAQC from "./Components/IAQC/IAQC National Ranking/NationalRankingIAQC";
import IaqcFeedback from "./Components/IAQC/IAQC Feedback/IaqcFeedback";
import IqacEvent from "./Components/IAQC/IQAC Event/IqacEvent";
import IqacPolicy from "./Components/IAQC/IQAC Policy/IqacPolicy";
import Nirf from "./Components/Accreditations & Approvals/NIRF/Nirf";
import AtalRanking from "./Components/Accreditations & Approvals/Atal Ranking/AtalRanking";
import Approvals from "./Components/Accreditations & Approvals/Approvals/Approvals";
import RtiAct from "./Components/RTI/RTI ACT/RtiAct";
import DomesticVisit from "./Components/RTI/RTI ACT/Domestic Visit/DomesticVisit";
import OrganizationAndFunction from "./Components/RTI/Rti Act Section (4)(1)(b)/Organization And Function/OrganizationAndFunction";
import BudgetAndProgramme from "./Components/RTI/Rti Act Section (4)(1)(b)/BUDGET AND PROGRAMME/BudgetAndProgramme";
import PpiRti from "./Components/RTI/Rti Act Section (4)(1)(b)/PUBLICITY AND PUBLIC INTERFACE/PpiRti";
import StrategicPlanOfDpsru from "./Components/Naac/621 and 622/StrategicPlanOfDpsru";
import Scholorships from "./Components/Academics/Scholorships/Scholorships";
import WhatsNew from "./Components/A tabs creation/TabFiles/WhatsNew/WhatsNew";
import IastamOration13 from "./Components/A tabs creation/TabFiles/IastamOration13/IastamOration13";
import NewsOfDpsru from "./Components/A tabs creation/TabFiles/News Of Dpsru/NewsOfDpsru";
import FacultywiseAreasOfResearch from "./Components/RD/Facultywise Areas of Research/FacultywiseAreasOfResearch";
import ResearchPapersOfRD from "./Components/RD/RESEARCH PAPERS/ResearchPapersOfRD";
import Project from "./Components/RD/Projects/Project";
import AcademicUnits from "./Components/Academics/Academic Units and Centres of Excellences/AcademicUnits";
import DoctoralProgramOfAcademics from "./Components/Academics/Doctoral Programmes of Academics/DoctoralProgramOfAcademics";
import IndustrialTraining from "./Components/Academics/Industrial Training/IndustrialTraining";
import TimeTableAcademics from "./Components/Academics/All Time Tables (Current & Previous)/TimeTableAcademics";
import LibraryOfAcademics from "./Components/Academics/Library Of Academics/LibraryOfAcademics";
import G20MahaFoodFestival from "./Components/A tabs creation/TabFiles/WhatsNew/G20- MAHA FOOD FESTIVAL OF CHINA, FRANCE & GERMANY at DPSRU/G20MahaFoodFestival";
import PushpPradarshanam from "./Components/A tabs creation/TabFiles/WhatsNew/PUSHP PRADARSHNAM 2023/PushpPradarshanam";
import IndoBrazilFusionFest from "./Components/A tabs creation/TabFiles/WhatsNew/G20 Indo Brazil Fusion Fest/IndoBrazilFusionFest";
import G20PanelDiscussionReport from "./Components/A tabs creation/TabFiles/WhatsNew/G20 Report on Panel Discussion/G20PanelDiscussionReport";
import EGovernance from "./Components/RTI/Rti Act Section (4)(1)(b)/E-GOVERNANCE/EGovernance";
import CampusAmenities from "./Components/Campus Amenities/CampusAmenities";
import InformationAsMayBePrescribed from "./Components/RTI/Rti Act Section (4)(1)(b)/INFORMATION AS MAY BE PRESCRIBED/InformationAsMayBePrescribed";
import InformationDisclosedOnOwnInitiative from "./Components/RTI/Rti Act Section (4)(1)(b)/INFORMATION DISCLOSED ON OWN INITIATIVE/InformationDisclosedOnOwnInitiative";
import AnnualReportOfRti from "./Components/RTI/Rti Act Section (4)(1)(b)/Annual Report Of RTI/AnnualReportOfRti";
import CpioAndFaa from "./Components/RTI/CPIO AND FAA/CpioAndFaa";
import GrievanceForm from "./Components/Grievance Form/GrievanceForm";
import Login from "./Components/Admin/Login/Login";
import Admin from "./Components/Admin/AdminApp/Admin";
// import Signup from "./Components/Admin/Signup.js/Signup";
import PrivateComp from "./Components/Admin/PrivateComponent/PrivateComp";
import GravienceFormData from "./Components/Admin/Gravience Form Data/GravienceFormData";
import Events from "./Components/Events/Events";
import Conference from "./Components/Events/Conference/Conference";
import Workshop from "./Components/Events/Workshop/Workshop";
import G20 from "./Components/Events/G20/G20";
import G20QuizCompetition from "./Components/Events/G20/G20 Quiz Competition/G20QuizCompetition";
import NationalScinceDay from "./Components/Events/G20/National Science Day/NationalScinceDay";
import UnnatBharatAbhiyan from "./Components/Events/G20/Unnat Bharat Abhiyan/UnnatBharatAbhiyan";
import DpsruLibrary from "./Components/DPSRU Library/DpsruLibrary";
import LibraryStatistics from "./Components/DPSRU Library/Library Component/Library Statistics/LibraryStatistics";
import SubscribedResources from "./Components/DPSRU Library/Library Component/Subscribed Resources/SubscribedResources";
import OpenAccessResources from "./Components/DPSRU Library/Library Component/Open Access Resources/OpenAccessResources";
import ResearchSupport from "./Components/DPSRU Library/Library Component/Research Support/ResearchSupport";
import InstitutionalMembership from "./Components/DPSRU Library/Library Component/Institutional Membership/InstitutionalMembership";
import LibraryPlanningCommittee from "./Components/DPSRU Library/Library Component/Library Planning Committee/LibraryPlanningCommittee";
import LibraryTeam from "./Components/DPSRU Library/Library Component/Library Team/LibraryTeam";
import LibraryRulesAndRegulations from "./Components/DPSRU Library/Library Component/Library Rules and Regulations/LibraryRulesAndRegulations";
import AskALibrarian from "./Components/DPSRU Library/Library Component/Ask a Librarian/AskALibrarian";
import LibraryEvent from "./Components/DPSRU Library/Library Component/Events/LibraryEvent";
import LibraryFacultyInterview from "./Components/DPSRU Library/Library Component/Faculty Interview/LibraryFacultyInterview";
import PeerTeamVisit from "./Components/Naac/Peer Team Visit/PeerTeamVisit";
import VisionMission from "./Components/Home/Vision, Mission, Objectives & Quality Policy/VisionMission";
import AuthoritiesoftheUniversity from "./Components/Home/Vision, Mission, Objectives & Quality Policy/Authorities of the University/AuthoritiesoftheUniversity";
import GoverningActs from "./Components/Home/Vision, Mission, Objectives & Quality Policy/Governing Acts/GoverningActs";
import Ugc12B from "./Components/Accreditations & Approvals/UGC 12B/Ugc12B";
import TLPReports from "./Components/TLP Report/TLPReports";
import HIVAIDSPreventionsControl from "./Components/HIV AIDS Preventions & Control/HIVAIDSPreventionsControl";
import DateSheet from "./Components/Examination/DateSheet/DateSheet";
import DateSheet2022 from "./Components/Examination/DateSheet/DateSheet Year/Date Sheet - 2022/DateSheet2022";
import DateSheet2021 from "./Components/Examination/DateSheet/DateSheet Year/Date Sheet - 2021/DateSheet2021";
import DateSheet2019 from "./Components/Examination/DateSheet/DateSheet Year/Date Sheet - 2019/DateSheet2019";
import DateSheet2018 from "./Components/Examination/DateSheet/DateSheet Year/Date Sheet - 2018/DateSheet2018";
import DipsarTimeTable from "./Components/Academics/School/DIPSAR/Dipsar Time Table/DipsarTimeTable";
import DipsarSessionalExamination from "./Components/Academics/School/DIPSAR/Dipsar Sessional Examination/DipsarSessionalExamination";
import DipsarNotice from "./Components/Academics/School/DIPSAR/Dipsar Notice/DipsarNotice";
import ProfPKSahoo from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Prof PK Sahoo/ProfPKSahoo";
import DrDeeptiPandita from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Deepti Pandita/DrDeeptiPandita";
import DrMeenakshiKChauhan from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr Meenakshi K Chauhan/DrMeenakshiKChauhan";
import DrGauravKumarJain from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Gaurav Kumar Jain/DrGauravKumarJain";
import DrDalapathiGugulothu from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Dalapathi Gugulothu/DrDalapathiGugulothu";
import DrPoojaMongiaRaj from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Pooja Mongia Raj/DrPoojaMongiaRaj";
import DrAnshul from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Anshul/DrAnshul";
import DrBeautyBehera from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Beauty Behera/DrBeautyBehera";
import DrAtulJain from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Atul Jain/DrAtulJain";
import DrRajaniMathur from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Rajani Mathur/DrRajaniMathur";
import DrSLatha from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. S. Latha/DrSLatha";
import DrSachinAssistantProfessor from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Sachin Assistant Professor/DrSachinAssistantProfessor";
import DrAnoopKumar from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Anoop Kumar/DrAnoopKumar";
import DrGunjanSharma from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Gunjan Sharma/DrGunjanSharma";
import DrDharamPalPathak from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Dharam Pal Pathak/DrDharamPalPathak";
import DrSharadWakode from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Sharad Wakode/DrSharadWakode";
import DrSaurabhDahiya from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Saurabh Dahiya/DrSaurabhDahiya";
import DrAmritaParle from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Amrita Parle/DrAmritaParle";
import DrRameshBodla from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Ramesh Bodla/DrRameshBodla";
import DrSahilKumar from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Sahil Kumar/DrSahilKumar";
import DrArchanaSharma from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Archana Sharma/DrArchanaSharma";
import DrRuhiAli from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Ruhi Ali/DrRuhiAli";
import DrHimangini from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Himangini/DrHimangini";
import DrNeetikaSharma from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Neetika Sharma/DrNeetikaSharma";
import DrManjuVyasSingh from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Manju Vyas Singh/DrManjuVyasSingh";
import DrSakshiBajaj from "./Components/Academics/School/DIPSAR/Dipsar Faculty/Dr. Sakshi Bajaj/DrSakshiBajaj";
import ProfRameshKGoyal from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/01-Prof. Ramesh K. Goyal/ProfRameshKGoyal";
import DrMukeshNandave from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/02-Dr. Mukesh Nandave/DrMukeshNandave";
import DrAjitKumarThakur from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/03-Dr. Ajit Kumar Thakur/DrAjitKumarThakur";
import DrAparnaChaudhary from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/04- Dr. Aparna Chaudhary/DrAparnaChaudhary";
import DrHarvinderPopli from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/05-Dr. Harvinder Popli/DrHarvinderPopli";
import ProfGeetaAggarwal from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/06-Prof. Geeta Aggarwal/ProfGeetaAggarwal";
import DrSushmaTalegaonkar from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/07-Dr. Sushma Talegaonkar/DrSushmaTalegaonkar";
import DrMadhuGupta from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/08-Dr. Madhu Gupta/DrMadhuGupta";
import DrMinakshiGarg from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/09-Dr. Minakshi Garg/DrMinakshiGarg";
import DrAmanpreetKaur from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/10-Dr. Amanpreet Kaur/DrAmanpreetKaur";
import DrSumitSharma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/11-Dr. Sumit Sharma/DrSumitSharma";
import DrBhartiMangla from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/12-Dr. Bharti Mangla/DrBhartiMangla";
import ProfRajivTonk from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/13-Prof. Rajiv Tonk/ProfRajivTonk";
import DrPrabodhChanderSharma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/14-Dr. Prabodh Chander Sharma/DrPrabodhChanderSharma";
import DrPritiJain from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/15-Dr. Priti Jain/DrPritiJain";
import DrSurajPalVerma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/16-Dr. Suraj Pal Verma/DrSurajPalVerma";
import DrKPraveenKumar from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/17-Dr. K. Praveen Kumar/DrKPraveenKumar";
import DrKalicharanSharma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/18-Dr. Kalicharan Sharma/DrKalicharanSharma";
import ProfAjaySharma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/19-Prof. Ajay Sharma/ProfAjaySharma";
import DrMahaveerDhobi from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/20-Dr. Mahaveer Dhobi/DrMahaveerDhobi";
import DrVijenderKumar from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/21-Dr. Vijender Kumar/DrVijenderKumar";
import DrAryaLakshmiMarisetti from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/22-Dr. Arya Lakshmi Marisetti/DrAryaLakshmiMarisetti";
import DrDeveshTewari from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/23-Dr. Devesh Tewari/DrDeveshTewari";
import DrHimanshu from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/24-Dr. Himanshu/DrHimanshu";
import DrVarunSharma from "./Components/Academics/School/School Of Pharmaceutical Sciences/SPS Faculty/25-Dr. Varun Sharma/DrVarunSharma";
import DrJaseelaMajeed from "./Components/Academics/School/SAHSM/Alied Faculty/01-Dr. Jaseela Majeed/DrJaseelaMajeed";
import DrPuneetaAjmera from "./Components/Academics/School/SAHSM/Alied Faculty/02-Dr. Puneeta Ajmera/DrPuneetaAjmera";
import DrJSwaminathan from "./Components/Academics/School/SAHSM/Alied Faculty/03-Dr. J. Swaminathan/DrJSwaminathan";
import DrAbhishekDadhich from "./Components/Academics/School/SAHSM/Alied Faculty/04-Dr. Abhishek Dadhich/DrAbhishekDadhich";
import DrSheetalYadav from "./Components/Academics/School/SAHSM/Alied Faculty/05-Dr. Sheetal Yadav/DrSheetalYadav";
import DrSimmiVashishtha from "./Components/Academics/School/SAHSM/Alied Faculty/06-Dr Simmi Vashishtha/DrSimmiVashishtha";
import DrAkashMidha from "./Components/Academics/School/SAHSM/Alied Faculty/07-Dr. Akash Midha/DrAkashMidha";
import DrSuresh from "./Components/Academics/School/SAHSM/Alied Faculty/08-Dr. Suresh/DrSuresh";
import DrSaurabhChaturvedi from "./Components/Academics/School/SAHSM/Alied Faculty/09-Dr. Saurabh Chaturvedi/DrSaurabhChaturvedi";
import DrSubodhKumar from "./Components/Academics/School/SAHSM/Alied Faculty/10-Dr. Subodh Kumar/DrSubodhKumar";
import DrSatishManchanda from "./Components/Academics/School/SAHSM/Alied Faculty/11-Dr. Satish Manchanda/DrSatishManchanda";
import DrRashiGupta from "./Components/Academics/School/SAHSM/Alied Faculty/12- Dr. Rashi Gupta/DrRashiGupta";
import DrSachinKumar from "./Components/Academics/School/SAHSM/Alied Faculty/13-Dr. Sachin Kumar/DrSachinKumar";
import DrRakhiAhuja from "./Components/Academics/School/SAHSM/Alied Faculty/14-Dr. Rakhi Ahuja/DrRakhiAhuja";
import DrSSivakalai from "./Components/Academics/School/SAHSM/Alied Faculty/15-Dr. S.Sivakalai/DrSSivakalai";
import DrSarikaSingh from "./Components/Academics/School/SAHSM/Alied Faculty/16-Dr. Sarika Singh/DrSarikaSingh";
import DrRamshaIqbal from "./Components/Academics/School/SAHSM/Alied Faculty/17-Dr. Ramsha Iqbal/DrRamshaIqbal";
import AboutUsExamination from "./Components/Examination/Examination About Us/AboutUsExamination";
import ExaminationOrdinance from "./Components/Examination/Download/Examination Ordinance/ExaminationOrdinance";
import ExaminatinSyllabus from "./Components/Examination/Examinatin-syllabus/ExaminatinSyllabus";
import ChintanShivir from "./Components/chintan-shivir/ChintanShivir";
import Result2022 from "./Components/Examination/Results/Result Year/Result - 2022/Result2022";
import Result2021 from "./Components/Examination/Results/Result Year/Result - 2021/Result2021";
import SwachhtaHiseva from "./Components/A tabs creation/Swachta Hi Seva/SwachhtaHiseva";
import MedicalLaboratoryTechnologyweek from "./Components/A tabs creation/Medical Laboratory Technology week/MedicalLaboratoryTechnologyweek";
import NextGenerationSequencinganditsApplications from "./Components/Academics/CERTIFICATE COURSES/Next Generation Sequencing/NextGenerationSequencinganditsApplications";
import InternationalAccolades from "./Components/IAQC/International Accolades/InternationalAccolades";
import DrRichaHirendraRai from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/Dr. Richa Hirendra Rai/DrRichaHirendraRai";
import DrShilpaJain from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/Dr. Shilpa Jain/DrShilpaJain";
import DrSheetalKalra from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/Dr. Sheetal Kalra/DrSheetalKalra";
import DrParulSharma from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/04-Dr. Parul Sharma/DrParulSharma";
import DrSapnaDhiman from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/05-Dr. Sapna Dhiman/DrSapnaDhiman";
import DrNehaKumari from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/06-Dr. Neha Kumari/DrNehaKumari";
import DrTabassumSaher from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/07-Dr. Tabassum Saher/DrTabassumSaher";
import DrVarshaChorsiya from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/08- Dr. Varsha Chorsiya/DrVarshaChorsiya";
import DrJitenderMunjal from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/09-Dr. Jitender Munjal/DrJitenderMunjal";
import DrSavitaTamaria from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/10- Dr. Savita Tamaria/DrSavitaTamaria";
import DrRavinderKumar from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/11- Dr. Ravinder Kumar/DrRavinderKumar";
import MsSuhasiniD from "./Components/Academics/School/SchoolofPhysiotherapy/Physiotherapy faculty/12-Ms. Suhasini D/MsSuhasiniD";
import DrAKUppal from "./Components/Academics/Centres/World Class Skill Centre/wcsc Faculty/DrAKUppal/DrAKUppal";
import DrMeeraSood from "./Components/Academics/Centres/World Class Skill Centre/wcsc Faculty/Dr. Meera Sood/DrMeeraSood";
import MsMeghnaSingh from "./Components/Academics/Centres/World Class Skill Centre/wcsc Faculty/Ms. Meghna Singh/MsMeghnaSingh";
import CenterforMeditationandYogaScience from "./Components/Academics/Centres/Center for Meditation and Yoga Science/CenterforMeditationandYogaScience";
import DrGagandeepKaur from "./Components/Academics/Centres/ASSRM/Assrm faculty/Dr. Gagandeep Kaur/DrGagandeepKaur";
import MsAmandeepKaur from "./Components/Academics/Centres/ASSRM/Assrm faculty/Ms. Amandeep Kaur/MsAmandeepKaur";
import MsSonia from "./Components/Academics/Centres/ASSRM/Assrm faculty/Ms. Sonia/MsSonia";
import MrBaljinderSingh from "./Components/Academics/Centres/ASSRM/Assrm faculty/Mr. Baljinder Singh/MrBaljinderSingh";
import MsSakshiSudha from "./Components/Academics/Centres/ASSRM/Assrm faculty/Ms. Sakshi Sudha/MsSakshiSudha";
import MrNihalSingh from "./Components/Academics/Centres/ASSRM/Assrm faculty/Mr. Nihal Singh/MrNihalSingh";
import MsNikhila from "./Components/Academics/Centres/ASSRM/Assrm faculty/Ms. Nikhila/MsNikhila";
import Result2020 from "./Components/Examination/Results/Result Year/Result - 2020/Result2020";
import Result2019 from "./Components/Examination/Results/Result Year/Result - 2019/Result2019";
import Result2018 from "./Components/Examination/Results/Result Year/Result - 2018/Result2018";
import Ayurveda from "./Components/Academics/School/School Of Pharmaceutical Sciences/Ayurveda/Ayurveda";
import WorldHeartDay from "./Components/Events/World Heart Day (29th September)/WorldHeartDay";
import RegistrarOfDpsru from "./Components/Vice-chancellor/Registrar Home page/RegistrarDpsru";
import StudentHostal from "./Components/Students/HostelData/StudentHostal";
import UpperNavigation from "./Components/Nav/UpperNavigation/UpperNavigation";
import PlacementRecord20222023 from "./Components/Placement/Placement Year/Placement Record 2022-2023/PlacementRecord2022-2023";
import PlacementRecord20212022 from "./Components/Placement/Placement Year/Placement Record 2021- 2022/Placement Record 2021-2022";
import PlacementRecord20202021 from "./Components/Placement/Placement Year/Placement Record 2020-2021/Placement Record 2020-2021";
import PlacementRecord20192020 from "./Components/Placement/Placement Year/Placement Record 2019-2020/Placement Record 2019-2020";
import PlacementRecord20182019 from "./Components/Placement/Placement Year/Placement Record 2018-2019/Placement Record 2018-2019";
import PlacementRecord20172018 from "./Components/Placement/Placement Year/Placement Record 2017-2018/Placement Record 2017-2018";
import VisionforViksitBharat2047 from "./Components/About Us/Vision for Viksit Bharat @2047/VisionforViksitBharat2047";
import VoiceOfYouthViksitBharat2047 from "./Components/About Us/Vision for Viksit Bharat @2047/Voice of youth vilsit bharat 2047/VoiceOfYouthViksitBharat2047";
import NoticeOfViksitBharat from "./Components/About Us/Vision for Viksit Bharat @2047/Notice of Viksit Bharat 2047/NoticeOfViksitBharat";
import AboutViksitBharat from "./Components/About Us/Vision for Viksit Bharat @2047/AboutViksitBharat/AboutViksitBharat";

// clickjacking 
import { Helmet } from "react-helmet";
import ComputerCenter from "./Components/Central resources/Computer Center/ComputerCenter";
import ViceChancellor from "./Components/About Us/Vice Chancellor/ViceChancellor";
import ReDesigningHome from "./Components/Home/ReDesigningHome";
import RedesignUpperNavigation from "./Components/Nav/UpperNavigation/RedesignUpperNavigation";
import NoticePopUp from "./Components/NoticePopUp";
import Archive from "./Components/About Us/Archive/Archive";
import ReDesignNavigation from "./Components/Nav/ReDesignNavigation";

function App() {
  const [isPopupVisible, setIsPopupVisible] = useState(true);

  const handleClosePopup = () => {
    setIsPopupVisible(false);
  };


  useEffect(() => {
    // This effect will run once on component mount
    setIsPopupVisible(true);
  }, []);

  <Helmet>
  <meta http-equiv="X-Frame-Options" content="DENY" />
</Helmet>


  // block f12 key
  // just uncomment these lines and f12 key will be blocked

  // const handleKeyDown = (e) => {
  //   if (e.keyCode === 123) { // F12 key
  //     e.preventDefault();
  //   }
  // };

  // React.useEffect(() => {
  //   document.addEventListener('keydown', handleKeyDown);

  //   return () => {
  //     document.removeEventListener('keydown', handleKeyDown);
  //   };
  // }, []);
  // block f12 key end here

  // block right click code
  // useEffect(() => {
  //   function blockRightClick(event) {
  //     event.preventDefault();
  //     alert("Sorry, Right-click functionality is disabled on this website.");
  //   }

  //   document.addEventListener("contextmenu", blockRightClick);

  //   return () => {
  //     document.removeEventListener("contextmenu", blockRightClick);
  //   };
  // }, []);

  // block right click code end here

  return (
    <div className="app-component">





{/* Notice popup */}

{/* {isPopupVisible && <NoticePopUp onClose={handleClosePopup} />}
      {!isPopupVisible && (
        <div>
        
       
        </div>
      )} */}














      {/* <UpperNavigation /> */}
      <RedesignUpperNavigation/>
      <Nav />
      {/* <ReDesignNavigation/> */}
      <Routes>



        <Route path="/" element={<Home />} />


        <Route path="/server.js" element={<PageNotFound />} />
        <Route path="/package-lock.json" element={<PageNotFound />} />
        <Route path="/manifest.json" element={<PageNotFound />} />
        <Route path="/archive" element={<Archive />} />

{/* ReDesigning Home page container */}

        <Route
          path="/redesignhome"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ReDesigningHome />
            </div>
          }
          />
          {/* ReDesigning Home page container */}





        <Route
          path="/staff"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Staff />
            </div>
          }
        />

        <Route
          path="/visionMissionObjectives&QualityPolicy"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <VisionMission />
            </div>
          }
        />

        {/* Viksit bharat stated here */}
        <Route
          path="/VisionforViksitBharat@2047"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <VisionforViksitBharat2047 />
            </div>
          }
        >
          <Route
            path="aboutViksitBharat"
            element={<AboutViksitBharat />}
          />
          <Route
            path="voiceOfYouthViksitBharat2047"
            element={<VoiceOfYouthViksitBharat2047 />}
          />
          <Route
            path="noticeOfViksitBharat"
            element={<NoticeOfViksitBharat />}
          />
        </Route>

        {/* viksit bharat end here */}

        <Route
          path="/governingActs"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <GoverningActs />
            </div>
          }
        />
        <Route
          path="/AuthoritiesoftheUniversity"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AuthoritiesoftheUniversity />
            </div>
          }
        />

        <Route
          path="/annualReport"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AnnualReport />
            </div>
          }
        />
        <Route
          path="/confercences"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Conferences />
            </div>
          }
        />
        <Route
          path="/nss"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Nss />
            </div>
          }
        />
        <Route
          path="/notifications"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Notifications />
            </div>
          }
        />
        <Route
          path="/tenders"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Tenders />
            </div>
          }
        />
        <Route
          path="/recruitments"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Recruitments />
            </div>
          }
        />
        <Route
          path="/contactUs"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ContactUs />
            </div>
          }
        />

        {/* this is for old url of contact us page */}
        <Route
          path="/contact-us"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ContactUs />
            </div>
          }
        />
        <Route
          path="/newsletters"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <NewsLetters />
            </div>
          }
        />
        <Route
          path="/forms"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Forms />
            </div>
          }
        />
        <Route
          path="/approvalAffiliations"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Approval />
            </div>
          }
        />
        <Route
          path="/payment"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Payment />
            </div>
          }
        />
        <Route
          path="/placement"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Placement />
            </div>
          }
        />

        {/* Placement record start here */}
        <Route
          path="/placement-record-2022-2023"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20222023 />
            </div>
          }
        />
        <Route
          path="/placement-record-2021-2022"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20212022 />
            </div>
          }
        />
        <Route
          path="/placement-record-2020-2021"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20202021 />
            </div>
          }
        />
        <Route
          path="/placement-record-2019-2020"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20192020 />
            </div>
          }
        />
        <Route
          path="/placement-record-2018-2019"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20182019 />
            </div>
          }
        />
        <Route
          path="/placement-record-2017-2018"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PlacementRecord20172018 />
            </div>
          }
        />

        {/* placement record end here */}
        <Route
          path="/tlpReports"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <TLPReports />
            </div>
          }
        />
        <Route
          path="/HIV_AIDS_Preventions_&_Control"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <HIVAIDSPreventionsControl />
            </div>
          }
        />
        {/* <Route
          path="/viceChancellor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ViceChancellor />
            </div>
          }
        /> */}
        <Route
          path="/chancellorDpsru"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ChancellorDpsru />
            </div>
          }
        />
        <Route
          path="/registrar-of-dpsru"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <RegistrarOfDpsru />
            </div>
          }
        />

        {/* Main navigation */}
        <Route
          path="/aboutUs"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AboutUs />
            </div>
          }
        />

        {/* rd */}
        <Route
          path="/rd"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <RD />
            </div>
          }
        />
        <Route
          path="/areaOfResearch"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <FacultywiseAreasOfResearch />
            </div>
          }
        />
        <Route
          path="/researchPaper"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ResearchPapersOfRD />
            </div>
          }
        />

        <Route
          path="/RD_project"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Project />
            </div>
          }
        />
        {/* rd */}

        {/* Academics */}

        <Route
          path="/academics"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Academics />
            </div>
          }
        />
        <Route
          path="/Next-Generation-Sequencing-and-its-Applications"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <NextGenerationSequencinganditsApplications />
            </div>
          }
        />

        <Route
          path="/academicUnits"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AcademicUnits />
            </div>
          }
        />
        <Route
          path="/doctoralProgramOfAcademics"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DoctoralProgramOfAcademics />
            </div>
          }
        />
        <Route
          path="/industrialTraining"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IndustrialTraining />
            </div>
          }
        />
        <Route
          path="/AllTimeTable"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <TimeTableAcademics />
            </div>
          }
        />
        <Route
          path="/scholarShips"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Scholorships />
            </div>
          }
        />

{/* central resources start*/}
        <Route
          path="/library"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <LibraryOfAcademics />
            </div>
          }
        />
        <Route
          path="/computerCenter"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ComputerCenter />
            </div>
          }
        />
{/* central resources ends */}
        {/* Academics */}

        <Route
          path="/dipsar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Dipsar />
            </div>
          }
        />
        <Route
          path="/dipsar-time-table"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DipsarTimeTable />
            </div>
          }
        />
        <Route
          path="/dipsar-sessional-Examination"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DipsarSessionalExamination />
            </div>
          }
        />
        <Route
          path="/dipsar-notice"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DipsarNotice />
            </div>
          }
        />

        {/* Dipsar Faculty */}

        <Route
          path="/Prof-pk-sahoo"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ProfPKSahoo />
            </div>
          }
        />
        <Route
          path="/Dr-Deepti-Pandita"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrDeeptiPandita />
            </div>
          }
        />
        <Route
          path="/Dr-Meenakshi-K-Chauhan"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMeenakshiKChauhan />
            </div>
          }
        />
        <Route
          path="/Dr-Gaurav-Kumar-Jain"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrGauravKumarJain />
            </div>
          }
        />
        <Route
          path="/Dr-Dalapathi-Gugulothu"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrDalapathiGugulothu />
            </div>
          }
        />
        <Route
          path="/Dr-Pooja-Mongia-Raj"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrPoojaMongiaRaj />
            </div>
          }
        />
        <Route
          path="/Dr-Anshul"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAnshul />
            </div>
          }
        />
        <Route
          path="/Dr-Beauty-Behera"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrBeautyBehera />
            </div>
          }
        />
        <Route
          path="/Dr-Atul-jain"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAtulJain />
            </div>
          }
        />
        <Route
          path="/Dr-Rajni-mathur"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRajaniMathur />
            </div>
          }
        />
        <Route
          path="/Dr-s-laha"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSLatha />
            </div>
          }
        />
        <Route
          path="/Dr-Sachin-Assistant-Professor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSachinAssistantProfessor />
            </div>
          }
        />
        <Route
          path="/Dr-Anoop-kumar-Assistant-Professor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAnoopKumar />
            </div>
          }
        />
        <Route
          path="/Dr-Gunjan-sharma-Assistant-Professor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrGunjanSharma />
            </div>
          }
        />
        {/* <Route
          path="/Dr-Dharam-Pal-Pathak"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrDharamPalPathak />
            </div>
          }
        /> */}
        <Route
          path="/Dr-Sharad-Wakode"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSharadWakode />
            </div>
          }
        />
        <Route
          path="/Dr-saurabh-Dahiya"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSaurabhDahiya />
            </div>
          }
        />
        <Route
          path="/Dr-Amrita-parle"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAmritaParle />
            </div>
          }
        />
        <Route
          path="/Dr-Ramesh-bodla"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRameshBodla />
            </div>
          }
        />
        <Route
          path="/Dr-Sahil-Kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSahilKumar />
            </div>
          }
        />
        <Route
          path="/Dr-Archana-sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrArchanaSharma />
            </div>
          }
        />
        <Route
          path="/Dr-Ruhi-ali"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRuhiAli />
            </div>
          }
        />
        <Route
          path="/Dr-Himangini"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrHimangini />
            </div>
          }
        />
        <Route
          path="/Dr-Neetika-sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrNeetikaSharma />
            </div>
          }
        />
        <Route
          path="/Dr-Manju-Vyas-Singh"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrManjuVyasSingh />
            </div>
          }
        />
        <Route
          path="/Dr-Sakshi-Bajaj"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSakshiBajaj />
            </div>
          }
        />

        {/* Dipsar Faculty end here*/}

        {/* School Of Pharmaceutical Sciences faculty start here */}
        <Route
          path="/Prof-Ramesh-kumar-goyal"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ProfRameshKGoyal />
            </div>
          }
        />
        <Route
          path="/Dr-Mukesh-Nandave"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMukeshNandave />
            </div>
          }
        />
        <Route
          path="/Dr-Ajit-kumar-thakur"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAjitKumarThakur />
            </div>
          }
        />
        <Route
          path="/Dr-Aparna-chaudhary"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAparnaChaudhary />
            </div>
          }
        />
        <Route
          path="/Dr-Harvinder-Popli"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrHarvinderPopli />
            </div>
          }
        />
        <Route
          path="/Prof-Geeta-Aggarwal"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ProfGeetaAggarwal />
            </div>
          }
        />
        <Route
          path="/Dr-sushma-telegaonkar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSushmaTalegaonkar />
            </div>
          }
        />
        <Route
          path="/Dr-Madhu-gupta"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMadhuGupta />
            </div>
          }
        />
        <Route
          path="/Dr-Minakshi-garg"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMinakshiGarg />
            </div>
          }
        />
        <Route
          path="/Dr-Amanpreet-kaur"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAmanpreetKaur />
            </div>
          }
        />
        <Route
          path="/Dr-Sumit-Sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSumitSharma />
            </div>
          }
        />
        <Route
          path="/Dr-Bharti-mangla"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrBhartiMangla />
            </div>
          }
        />
        <Route
          path="/Dr-Rajiv-tonk"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ProfRajivTonk />
            </div>
          }
        />
        <Route
          path="/Dr-Prabodh-Chander-Sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrPrabodhChanderSharma />
            </div>
          }
        />
        <Route
          path="/Dr-Priti-jain"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrPritiJain />
            </div>
          }
        />
        <Route
          path="/Dr-Suraj-pal-verma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSurajPalVerma />
            </div>
          }
        />
        <Route
          path="/Dr-k-Praveen-Kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrKPraveenKumar />
            </div>
          }
        />
        <Route
          path="/Dr-kali-charan-sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrKalicharanSharma />
            </div>
          }
        />
        <Route
          path="/Prof-Ajay-Sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ProfAjaySharma />
            </div>
          }
        />
        <Route
          path="/Dr-Mahaveer-dhobi"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMahaveerDhobi />
            </div>
          }
        />
        <Route
          path="/Dr-Vijender-kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrVijenderKumar />
            </div>
          }
        />
        <Route
          path="/Dr-Arya-Lakshmi-Marisetti"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAryaLakshmiMarisetti />
            </div>
          }
        />
        <Route
          path="/Dr-Devesh-tewari"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrDeveshTewari />
            </div>
          }
        />
        <Route
          path="/Dr-Himanshu-Assistant-Professor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrHimanshu />
            </div>
          }
        />
        <Route
          path="/Dr-Varun-sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrVarunSharma />
            </div>
          }
        />

        {/* School Of Pharmaceutical Sciences faculty END here */}

        {/* School of Allied Health Sciences And Management faculty START here */}

        <Route
          path="/Dr-jaseela-majeed"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrJaseelaMajeed />
            </div>
          }
        />

        <Route
          path="/Dr-punita-ajmera"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrPuneetaAjmera />
            </div>
          }
        />
        <Route
          path="/Dr-j-swaminathan"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrJSwaminathan />
            </div>
          }
        />
        <Route
          path="/Dr-abhishek-dadhich"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAbhishekDadhich />
            </div>
          }
        />
        <Route
          path="/Dr-sheetal-yadav"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSheetalYadav />
            </div>
          }
        />
        <Route
          path="/Dr-simmi-vashishtha"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSimmiVashishtha />
            </div>
          }
        />
        <Route
          path="/Dr-akash-midha"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAkashMidha />
            </div>
          }
        />
        <Route
          path="/Dr-suresh-Assistant-Professor"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSuresh />
            </div>
          }
        />
        <Route
          path="/Dr-saurabh-chaturvedi"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSaurabhChaturvedi />
            </div>
          }
        />
        <Route
          path="/Dr-subodh-kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSubodhKumar />
            </div>
          }
        />
        <Route
          path="/Dr-satish-namchanda"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSatishManchanda />
            </div>
          }
        />
        <Route
          path="/Dr-rashi-gupta"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRashiGupta />
            </div>
          }
        />
        <Route
          path="/Dr-sachin-kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSachinKumar />
            </div>
          }
        />
        <Route
          path="/Dr-rakhi-ahuja"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRakhiAhuja />
            </div>
          }
        />
        <Route
          path="/Dr-s-sivakalai"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSSivakalai />
            </div>
          }
        />
        <Route
          path="/Dr-sarika-singh"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSarikaSingh />
            </div>
          }
        />
        <Route
          path="/Dr-ramsha-iqbal"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRamshaIqbal />
            </div>
          }
        />
        {/* School of Allied Health Sciences And Management faculty STOP here */}

        <Route
          path="/soPharmaceticalSciences"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <SchoolOfPharmaceuticalSciences />
            </div>
          }
        />
        <Route
          path="/ayurveda"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Ayurveda />
            </div>
          }
        />
        <Route
          path="/sahsm"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Sahsm />
            </div>
          }
        />

        {/* /school-of-physiotherapy */}
        <Route
          path="/school-of-physiotherapy"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <SchoolofPhysiotherapy />
            </div>
          }
        />

        {/* /school-of-physiotherapy faculty */}
        <Route
          path="/Prof-Dr-Richa-Hirendra-Rai"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRichaHirendraRai />
            </div>
          }
        />
        <Route
          path="/dr-shilpa-jain"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrShilpaJain />
            </div>
          }
        />
        <Route
          path="/dr-sheetal-kalra"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSheetalKalra />
            </div>
          }
        />
        <Route
          path="/dr-parul-sharma"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrParulSharma />
            </div>
          }
        />
        <Route
          path="/dr-sapna-dhiman"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSapnaDhiman />
            </div>
          }
        />
        <Route
          path="/dr-neha-kumari"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrNehaKumari />
            </div>
          }
        />
        <Route
          path="/dr-tabassum-sahar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrTabassumSaher />
            </div>
          }
        />
        <Route
          path="/dr-varsha-chorsiya"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrVarshaChorsiya />
            </div>
          }
        />

        <Route
          path="/dr-jitender-munjal"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrJitenderMunjal />
            </div>
          }
        />

        <Route
          path="/dr-savita-tanaria"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrSavitaTamaria />
            </div>
          }
        />

        <Route
          path="/dr-ravinder-kumar"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrRavinderKumar />
            </div>
          }
        />
        <Route
          path="/ms-suhasini-D"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsSuhasiniD />
            </div>
          }
        />

        {/* /school-of-physiotherapy end here */}
        <Route
          path="/doctoralPrograms"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DoctoralPrograms />
            </div>
          }
        />

        <Route
          path="/wcsc"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Wcsc />
            </div>
          }
        />
        <Route
          path="/Center-for-Meditation-and-Yoga-Science"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <CenterforMeditationandYogaScience />
            </div>
          }
        />
        <Route
          path="/dr-ak-uppal"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrAKUppal />
            </div>
          }
        />
        <Route
          path="/dr-meera-sood"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrMeeraSood />
            </div>
          }
        />

        <Route
          path="/ms-meghna-singh"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsMeghnaSingh />
            </div>
          }
        />

        <Route
          path="/assrm"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Assrm />
            </div>
          }
        />
        <Route
          path="/dr-gagandeep-kaur"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DrGagandeepKaur />
            </div>
          }
        />
        <Route
          path="/ms-amandeep-kaur"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsAmandeepKaur />
            </div>
          }
        />
        <Route
          path="/ms-sonia"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsSonia />
            </div>
          }
        />
        <Route
          path="/mr-baljinder-singh"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MrBaljinderSingh />
            </div>
          }
        />
        <Route
          path="/ms-sakshi-sudha"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsSakshiSudha />
            </div>
          }
        />
        <Route
          path="/mr-nihal-singh"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MrNihalSingh />
            </div>
          }
        />
        <Route
          path="/ms-Nikhila"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MsNikhila />
            </div>
          }
        />

        <Route
          path="/centresOfExcellence"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <CentersOfExcellence />
            </div>
          }
        />
        <Route
          path="/certificateCourses"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <CertificateCourses />
            </div>
          }
        />

        <Route
          path="/administration"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Administration />
            </div>
          }
        />
        <Route
          path="/genralCounsil"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <GenralCounsil />
            </div>
          }
        />
        <Route
          path="/organogram"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Organogram />
            </div>
          }
        />
        <Route
          path="/committees"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Committees />
            </div>
          }
        />

        <Route
          path="/admissions"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Admissions />
            </div>
          }
        />

        <Route
          path="/students"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Students />
            </div>
          }
        />
        <Route
          path="/hostel"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <StudentHostal />
            </div>
          }
        />
        <Route
          path="/previousQuestionPapers"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PreviousQuestionPapers />
            </div>
          }
        />
        <Route
          path="/DisciplineRagging"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DisciplineRagging />
            </div>
          }
        />

        {/* IAQC */}
        <Route
          path="/iaqcIntroduction"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IAQCIntroduction />
            </div>
          }
        />
        <Route
          path="/International-Accolades"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <InternationalAccolades />
            </div>
          }
        />
        <Route
          path="/iqacMoM"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IaqcMoM />
            </div>
          }
        />
        <Route
          path="/QualityInitiativeIAQC"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <QualityInitiativeIAQC />
            </div>
          }
        />
        <Route
          path="/NationalRankingIAQC"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <NationalRankingIAQC />
            </div>
          }
        />
        <Route
          path="/iaqcFeedback"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IaqcFeedback />
            </div>
          }
        />
        <Route
          path="/iqacEvent"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IqacEvent />
            </div>
          }
        />
        <Route
          path="/iqacPolicy"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IqacPolicy />
            </div>
          }
        />
        {/* IAQC */}

        {/* Examination */}
        <Route
          path="/examination"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Examination />
            </div>
          }
        >
          <Route path="examinatin-syllabus" element={<ExaminatinSyllabus />} />

          <Route path="examination-about-us" element={<AboutUsExamination />} />
          <Route
            path="examinations-ordinance"
            element={<ExaminationOrdinance />}
          />
          <Route path="institutions" element={<Institutions />} />
          <Route path="rules" element={<Rules />} />
          <Route path="eDownload" element={<EDownload />} />
          <Route path="questionPapers" element={<QuestionPaper />} />
          <Route path="examination-result" element={<Results />} />
          <Route path="result-2022" element={<Result2022 />} />
          <Route path="result-2021" element={<Result2021 />} />
          <Route path="result-2020" element={<Result2020 />} />
          <Route path="result-2019" element={<Result2019 />} />
          <Route path="result-2018" element={<Result2018 />} />
          <Route path="examination-datesheet" element={<DateSheet />} />
          {/* date sheet inner container */}

          <Route path="date-sheet-2022" element={<DateSheet2022 />} />
          <Route path="date-sheet-2021" element={<DateSheet2021 />} />
          <Route path="date-sheet-2019" element={<DateSheet2019 />} />
          <Route path="date-sheet-2018" element={<DateSheet2018 />} />
        </Route>
        {/* Examination */}

        {/* Accreditations & Approvals */}

        <Route
          path="/vice-chancellor-dpsru"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ViceChancellor />
            </div>
          }
        />
        <Route
          path="/naac"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Naac />
            </div>
          }
        />
        <Route
          path="/university/strategic-plan-of-dpsru/"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <StrategicPlanOfDpsru />
            </div>
          }
        />
        <Route
          path="/peer-team-visit/"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PeerTeamVisit />
            </div>
          }
        />

        <Route
          path="/nirf"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Nirf />
            </div>
          }
        />
        <Route
          path="/atalRanking"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AtalRanking />
            </div>
          }
        />
        <Route
          path="/approvals"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Approvals />
            </div>
          }
        />
        <Route
          path="/ugc_12b"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Ugc12B />
            </div>
          }
        />

        {/* Accreditations & Approvals */}

        {/* RTI*/}
        <Route
          path="/rtiAct"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <RtiAct />
            </div>
          }
        />

        <Route
          path="/informationAsMayBePrescribed"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <InformationAsMayBePrescribed />
            </div>
          }
        />
        <Route
          path="/informationDisclosedOnOwnInitiative"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <InformationDisclosedOnOwnInitiative />
            </div>
          }
        />
        <Route
          path="/annualReportOfRti"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <AnnualReportOfRti />
            </div>
          }
        />
        <Route
          path="/cpioAndFaa"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <CpioAndFaa />
            </div>
          }
        />
        <Route
          path="/domesticVisit"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DomesticVisit />
            </div>
          }
        />
        <Route
          path="/organizationAndFunction"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <OrganizationAndFunction />
            </div>
          }
        />
        <Route
          path="/budgetAndProgramme"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <BudgetAndProgramme />
            </div>
          }
        />
        <Route
          path="/ppiRti"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PpiRti />
            </div>
          }
        />
        {/* Event */}
        <Route
          path="/events"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Events />
            </div>
          }
        />
        <Route
          path="/conference"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Conference />
            </div>
          }
        />
        <Route
          path="/workshop"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <Workshop />
            </div>
          }
        />
        <Route
          path="/g20"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <G20 />
            </div>
          }
        />

        <Route
          path="/indoBrazilFusionFest"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IndoBrazilFusionFest />
            </div>
          }
        />
        <Route
          path="/g20QuizCompetition"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <G20QuizCompetition />
            </div>
          }
        />
        <Route
          path="/nationalScinceDay"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <NationalScinceDay />
            </div>
          }
        />
        <Route
          path="/unnatBharatAbhiyan"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <UnnatBharatAbhiyan />
            </div>
          }
        />
        <Route
          path="/eGovernance"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <EGovernance />
            </div>
          }
        />
        <Route
          path="/grievanceForm"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <GrievanceForm />
            </div>
          }
        />
        <Route
          path="/campusAmenities"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <CampusAmenities />
            </div>
          }
        />

        {/* whats new tab in home tab creation */}
        <Route
          path="/G20MahaFoodFestival"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <G20MahaFoodFestival />
            </div>
          }
        />
        <Route
          path="/Swachhta-Hi-seva"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <SwachhtaHiseva />
            </div>
          }
        />
        <Route
          path="/world-heart-day-29-sept"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <WorldHeartDay />
            </div>
          }
        />
        <Route
          path="/Medical-Laboratory-Technology-week"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <MedicalLaboratoryTechnologyweek />
            </div>
          }
        />
        <Route
          path="/pushpPradarshnam"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PushpPradarshanam />
            </div>
          }
        />

        <Route
          path="/indoBrazilFusionFest"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IndoBrazilFusionFest />
            </div>
          }
        />
        <Route
          path="/g20PanelDiscussionReport"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <G20PanelDiscussionReport />
            </div>
          }
        />
        {/* whats new tab in home tab creation */}
        {/* RTI */}

        {/* Academics */}

        {/* PageNotFound */}
        <Route
          path="/*"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <PageNotFound />
            </div>
          }
        />
        {/* PageNotFound */}

        {/* comming soon */}
        <Route
          path="/ucps"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <UCPs />
            </div>
          }
        />
        {/* comming soon */}

        {/* Developer details */}
        <Route
          path="/developerDetails"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DeveloperDetails />
            </div>
          }
        />
        {/* Developer details */}

        {/* YoutubeLinksPage */}
        <Route
          path="/youtubeLinks"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <YoutubeLink />
            </div>
          }
        />
        {/* YoutubeLinksPage */}

        {/* HomeTabBottom */}
        <Route
          path="/whatsNew"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <WhatsNew />
            </div>
          }
        />
        <Route
          path="/iastamOration13"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <IastamOration13 />
            </div>
          }
        />
        <Route
          path="/achievements_&_news_of_dpsru"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <NewsOfDpsru />
            </div>
          }
        />
        <Route
          path="/chintan-shivir"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <ChintanShivir />
            </div>
          }
        />
        {/* library component */}

        <Route
          path="/dpsruLibrary"
          element={
            <div style={{ display: "flex" }}>
              <SideNav />
              <DpsruLibrary />
            </div>
          }
        >
          <Route path="libraryStatistics" element={<LibraryStatistics />} />
          <Route path="subscribedResources" element={<SubscribedResources />} />
          <Route path="openAccessResources" element={<OpenAccessResources />} />
          <Route path="researchSupport" element={<ResearchSupport />} />
          <Route
            path="institutionalMembership"
            element={<InstitutionalMembership />}
          />
          <Route
            path="libraryPlanningCommittee"
            element={<LibraryPlanningCommittee />}
          />
          <Route path="libraryTeam" element={<LibraryTeam />} />
          <Route
            path="libraryRulesAndRegulations"
            element={<LibraryRulesAndRegulations />}
          />
          <Route path="askALibrarian" element={<AskALibrarian />} />
          <Route path="libraryEvent" element={<LibraryEvent />} />

          <Route
            path="LibraryFacultyInterview"
            element={<LibraryFacultyInterview />}
          />

          <Route path="libraryEvent" element={<LibraryEvent />} />
        </Route>
        {/* library component end */}

        {/* admin pannel routes */}
        <Route path="/admin" element={<Admin />}>
          <Route path="admin-login" element={<Login />} />
          {/* <Route path="admin-signup" element={<Signup />} /> */}

          <Route element={<PrivateComp />}>
            <Route path="gravience-form-data" element={<GravienceFormData />} />
          </Route>
        </Route>
        {/* admin pannel routes */}

        {/* HomeTabBottom */}
      </Routes>

      <NewFooter />
      {/* <div className="developer-details-container">
      <Link to='/developerDetails' className='devDetails-link'>Developer Details</Link>
      </div> */}
    </div>
  );
}

export default App;
