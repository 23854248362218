import React from "react";
import "./ComputerCenter.css";
import lab302 from "./Images/lab302.jpeg";
import server309 from './Images/server room.jpeg'
import ITincharge from './Images/it incharge prabodh sir.jpeg'
import vikas from './Images/vikash.jpeg'
import brajesh from './Images/brajesh.jpg'
import rajuvjha from './Images/rajivjha.jpeg'
import sachin from './Images/sachin.jpeg'
import manish from './Images/manish.jpeg'
import noimg from './Images/no img.webp'
import complaintform from './Images/Complaint Form.pdf'
import internetupseacccoutncrea from './Images/Internet Users Account Creation Form - Google Forms.pdf'
import lab02 from './Images/lab02.jpeg'
import lab112 from './Images/lab112.jpeg'
import ReDesignNavigation from "../../Nav/ReDesignNavigation";

const ComputerCenter = () => {
  return (
    <div className="dpsru-every-component-css">
      <h2 className="dpsru-primary-heading-for-every-page">
        DPSRU Computer Center
      </h2>

      <div className="libraru-about-us-container">
        <h2
          className="dpsru-secondary-heading-for-every-page"
          style={{ color: "orange", borderTop: "2px solid" }}
        >
          About Computer Center
        </h2>
        <p className="library-paraqgrauph-about-us">
          Welcome to the dynamic IT sector of DPSR University, where innovation
          meets efficiency to power our digital infrastructure. As the backbone
          of technological advancement within the university, we specialize in
          maintaining and optimizing internet connectivity, networking systems,
          and the university website to ensure seamless operations and
          accessibility for all stakeholders. With two state-of-the-art computer
          labs under our purview, we provide a conducive environment for
          students and faculty to engage in hands-on learning and research
          activities. From troubleshooting technical issues to spearheading
          cutting-edge initiatives, our dedicated team of IT professionals is
          committed to fostering a digitally empowered ecosystem that propels
          DPSR University into the future.
        </p>
        <h2 className="dpsru-secondary-heading-for-every-page">Our mission</h2>
        <p className="library-paraqgrauph-about-us">
          {" "}
          At DPSR University's IT sector, our mission revolves around
          maintaining a robust digital ecosystem that seamlessly integrates
          technology into every facet of campus life. Our primary objective is
          to ensure uninterrupted internet connectivity, optimize networking
          infrastructure, and provide timely updates and maintenance to the
          university website. Through diligent monitoring and proactive
          maintenance practices, we guarantee a smooth online experience for
          students, faculty, and staff, facilitating efficient communication,
          collaboration, and access to vital resources. Our commitment extends
          to the management of two cutting-edge computer labs, where we empower
          the DPSR community with the latest tools and technologies for academic
          and research pursuits. With a focus on reliability, security, and
          innovation, we are dedicated to supporting the university's mission of
          academic excellence and preparing students for success in the digital
          age.
        </p>
        <h2 className="dpsru-secondary-heading-for-every-page">
        Computer Lab Timing
        </h2>
        <p className="library-paraqgrauph-about-us">
          {" "}
          <span className="library-timing-heading">
           Computer Lab Opening Hours:- {"   "}
          </span>
          9:00 A.M. to 5:30 P.M. (Monday to Friday). <br />
          </p>


        <h2 className="dpsru-secondary-heading-for-every-page" style={{ color: "orange", borderTop: "2px solid" }}>Facilities</h2>

       

        <h2 className="dpsru-secondary-heading-for-every-page">Server Room</h2>
<div className="server-room-computer-center-container">
    <img src={server309} alt="server room it" />
    <p>Welcome to Room 309, the nerve center of DPSR University's digital infrastructure. Here, housed within our secure server room, lies the beating heart of our technological ecosystem. Equipped with robust firewall security measures, our server room ensures the utmost protection for sensitive data and critical systems, safeguarding against cyber threats and unauthorized access. With a blazing-fast 1Gbps leased line internet connection, we provide lightning-speed connectivity to support the diverse needs of our campus community, facilitating seamless access to online resources and applications. Our commitment to uninterrupted service is further reinforced by UPS support, guaranteeing continuous operation and data integrity even in the event of power fluctuations or outages. To maintain optimal performance, the server room is outfitted with state-of-the-art air conditioning, ensuring a climate-controlled environment that preserves the reliability and efficiency of our equipment. With Room 309 at the helm, DPSR University remains at the forefront of technological innovation, powering forward into the digital age with resilience and reliability.</p>
</div>
<h2 className="dpsru-secondary-heading-for-every-page">UPS</h2>
<p className="library-paraqgrauph-about-us">
Across the expansive campus of DPSR University, reliability is paramount in ensuring uninterrupted operations of our critical systems. To uphold this standard, we've strategically deployed UPS units throughout our facilities, serving as a backbone of power backup solutions. With meticulous planning, we've installed eight UPS units of 17kV each, offering robust power backup across various administrative and academic buildings. Specifically tailored for our server infrastructure, an additional UPS unit of 5kV ensures continuous operation of our data center, safeguarding against potential disruptions. Furthermore, in Lab 112, where innovation and experimentation thrive, a dedicated UPS unit of 10kV stands ready to support the technological endeavors of our students and faculty. This comprehensive deployment of UPS units underscores our commitment to resilience and continuity, ensuring that DPSR University remains a steadfast beacon of learning and progress, even in the face of power challenges.
</p>

        <h2 className="dpsru-secondary-heading-for-every-page" style={{ color: "orange", borderTop: "2px solid" }}>
          Our Computer Labs
        </h2>
        <p className="library-paraqgrauph-about-us">
          {" "}
          At DPSR University, our IT sector oversees the management and
          optimization of two advanced computer labs, each meticulously equipped
          to support the diverse technological needs of our academic community.
          These state-of-the-art facilities serve as dynamic hubs for
          innovation, learning, and collaboration, providing students and
          faculty with access to cutting-edge hardware and software resources.
          Under the purview of our IT cell, these computer labs are meticulously
          maintained to ensure peak performance and reliability, enabling
          seamless execution of coursework, research endeavors, and hands-on
          practical sessions.
        </p>

        <div className="labs-details-in-computer-center-section-container">
          <div className="lab-details-of-each-lab-box-first">
            <h2 className="dpsru-secondary-heading-for-every-page">
              312 IT Lab (New Building)
            </h2>
            <div className="image-and-text-container-of-lab-details-computer-center">
              <img src={lab302} alt="Lab302" />
              <p className="paragrauph-of-lab-details-computer-center">
              Welcome to Lab 312, a cutting-edge facility within DPSR University's IT sector tailored to meet the specialized needs of the Medical department. Equipped with 40 high-performance computers, this lab offers a dedicated Python environment supported for AI-related applications in the medical field. From machine learning algorithms to data analysis tools, students and faculty have access to the latest resources to advance research and innovation in healthcare. The lab boasts four air conditioning units ensuring a comfortable environment for extended study sessions, along with an interactive panel and two LED TVs for enhanced collaboration and presentation capabilities. Additionally, Lab 312 is fortified with UPS support, ensuring uninterrupted operation and data protection, even during power outages. With its focus on merging technology with medicine, Lab 312 serves as a cornerstone for interdisciplinary exploration and discovery, driving forward the frontier of medical science at DPSR University.
              </p>
            </div>
          </div>


         
          <div className="lab-details-of-each-lab-box-first">
            <h2 className="dpsru-secondary-heading-for-every-page">
              02 IT Lab (Old Building)
            </h2>
            <div className="image-and-text-container-of-lab-details-computer-center image-and-text-container-of-lab-details-computer-center-second">
              <img src={lab02} alt="Lab302" />
              <p className="paragrauph-of-lab-details-computer-center">
              Welcome to Lab 02, a dynamic space within DPSR University tailored to meet the specialized needs of the Medical department. With 50 high-performance computers at its disposal, this lab serves as a hub for cutting-edge research and innovation at the intersection of technology and healthcare. Supported by a dedicated Python environment, students and faculty have access to powerful tools for AI-related applications, empowering them to explore advanced algorithms and data analysis techniques relevant to the medical field. Whether delving into medical imaging, predictive analytics, or drug discovery, Lab 02 provides the essential resources and infrastructure for driving forward groundbreaking advancements in healthcare. With a commitment to excellence and innovation, this lab stands as a testament to DPSR University's dedication to fostering interdisciplinary collaboration and pushing the boundaries of medical science.
              </p>
            </div>
          </div>
          <div className="lab-details-of-each-lab-box-first">
            <h2 className="dpsru-secondary-heading-for-every-page">
              112 IT Lab (New Building)
            </h2>
            <div className="image-and-text-container-of-lab-details-computer-center">
              <img src={lab112} alt="Lab302" />
              <p className="paragrauph-of-lab-details-computer-center">
              Welcome to Lab 112, a vibrant and innovative space within DPSR University designed to facilitate hands-on learning and exploration. Equipped with 21 computers, this lab provides students with the tools they need to engage in immersive and collaborative academic experiences. Supported by a comfortable environment with air conditioning, students can focus on their studies and projects without distraction. Additionally, the lab features an interactive panel, enhancing teaching and learning through dynamic presentations and interactive activities. Ensuring uninterrupted operation, Lab 112 is backed by a robust 10kV UPS support system, safeguarding against power disruptions and allowing students to seamlessly continue their work without interruption. With its blend of technology, comfort, and reliability, Lab 112 serves as a cornerstone for academic excellence and innovation within DPSR University.
              </p>
            </div>
          </div>
        </div>
      </div>

      <h2 className="dpsru-primary-heading-for-every-page">IT Team</h2>

      <div className="p-c-each-img-container pc-img-container-main">
        <img src={ITincharge} alt="IT" />
        <div className="planning-committee-text-container p-c-text-container-for-head">
          <p className="planning-committee-img-name">Dr. Prabodh Chander Sharma</p>
          <p className="planning-committee-img-designation">
           IT Incharge
          </p>
        </div>
      </div>

      {/* ohters */}
      <div className="planning-committee-img-container">
        
        <div className="p-c-each-img-container">
          <img src={vikas} alt="IT" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">
              Mr. Vikas Yadav
            </p>
            <p className="planning-committee-img-designation">IT Assistent</p>
          </div>
        </div>
        <div className="p-c-each-img-container">
          <img src={sachin} alt="IT" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">Mr. Sachin Satyarthi</p>
            <p className="planning-committee-img-designation">Office  Assistant</p>
          </div>
        </div>

        <div className="p-c-each-img-container">
          <img src={manish} alt="IT" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">
              Mr. Manish
            </p>
            <p className="planning-committee-img-designation">IT Support</p>
          </div>
        </div>
        <div className="p-c-each-img-container">
          <img src={rajuvjha} alt="IT" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">
            Mr. Rajeev Kumar Jha 
            </p>
            <p className="planning-committee-img-designation">IT Support</p>
          </div>
        </div>
        <div className="p-c-each-img-container">
          <img src={brajesh} alt="IT" />
          <div className="planning-committee-text-container">
            <p className="planning-committee-img-name">
              Mr. Brajesh
            </p>
            <p className="planning-committee-img-designation">Web Developer</p>
          </div>
        </div>
        </div>
        <h2 className="dpsru-secondary-heading-for-every-page" style={{ color: "orange", borderTop: "2px solid" }}>Forms</h2>
        <ul className="for-container-of-computer-center">
            <li>
                <a href={complaintform}>Complaint Form</a>
            </li>
            <li>
                <a href='https://docs.google.com/forms/d/e/1FAIpQLSeE2QdMBuHEdxr36hge4YCaNYZ7fTe7Dy85T4mEsiqdpyaUVg/viewform'>Internet Users Account Creation Form - Google Forms</a> 
            </li>
        </ul>
        {/* <ReDesignNavigation/> */}
    </div>
  );
};

export default ComputerCenter;
